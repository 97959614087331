import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'

import pdf from '../pdf/reducers'
import audio from '../audio/reducers'

const rootReducer = combineReducers({
  form,
  pdf,
  audio
})

export default rootReducer
