import React, { Component } from 'react'
import { Route, Switch, Redirect, withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import * as _ from 'lodash'
import './index.scss'
import 'antd/dist/antd.css'
// import Header from './shared/Header'
import { Menu, Layout } from 'antd'
import {
    DashboardOutlined, ProfileOutlined, SettingOutlined
} from '@ant-design/icons'
const { Sider } = Layout
moment.updateLocale(moment.locale(), { invalidDate: 'N/A' })

const isLocalStorageEmpty = () => {
    let strResult
    if (localStorage.getItem('CLIENT_SESSION_TOKEN') !== null) {
        strResult = true
    } else {
        strResult = false
    }
    return strResult
}

const SiderMenu = (props) => {
    return (
        <Sider className='sider-container' trigger={null} collapsible collapsed={props.siderCollapse}>
            <div className="logo" onClick={props.toggle} style={{cursor:'pointer'}}/>
            <Menu theme="dark" mode="inline" defaultSelectedKeys={[props.activeTab.toString()]} style={{ backgroundColor: 'transparent' }}>
                {
                    props.paths.map((data, index) => {
                        return (
                            <Menu.Item key={index} icon={data.icon}>
                                <Link to={data.route} >{data.slug}</Link>
                            </Menu.Item>
                        )
                    })
                }
            </Menu>
        </Sider>
    )
}

export default SiderMenu;