
import React, { useState, useEffect } from 'react';
import PDFJSAnnotate from '../../../../../PDFJSAnnotate'
import LeaderLine from 'react-leader-line';
import AnnotationContent from './annotationContent'
import moment from 'moment'
import { Icon } from "office-ui-fabric-react";
import { v4 as uuidv4 } from 'uuid';
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { updateAnnotationsList } from '../../actions'
import { ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Select, Divider, Collapse, Dropdown, Menu, Modal, Row, Col, Avatar, Tabs } from 'antd'
import '../index.scss'
const { UI } = PDFJSAnnotate;
const { Option } = Select;
const { Panel } = Collapse;

const AnnotationView = (props, ref) => {
    const [annotationsList, setAnnotationsList] = useState([])
    const [activeEditCommentId, setActiveEditCommentId] = useState('')
    const [activeEditCommentContent, setActiveEditCommentContent] = useState('')
    const [activeEditCommentReplyId, setActiveEditCommentReplyId] = useState('')
    const [activeEditCommentReplyMessage, setActiveEditCommentReplyMessage] = useState('')
    const [activeAnnotationId, setActiveAnnotationId] = useState()
    const [activeLeaderLine, setActiveLeaderLine] = useState()
    const [filterType, setFilterType] = useState('all')
    const [sort, setSort] = useState('')
    // attributes
    const [color, setColor] = useState('')
    const [fontSize, setFontSize] = useState('')
    const url = props.pdfFile
    const documentId = url;
    const viewerId = `${props.id}-viewer`
    function handleSubmitReply(data) {
        const index = props.annotationsData.findIndex(item => item.uuid === data.uuid)
        if (props.annotationsData[index].reply) {
            props.annotationsData[index].reply.push({ uuid: uuidv4(), message: data.message, createdAt: new Date() })
        } else {
            props.annotationsData[index].reply = []
            props.annotationsData[index].reply.push({ uuid: uuidv4(), message: data.message, createdAt: new Date() })
        }
        // update local storage
        localStorage.setItem(`${props.pdfFile}/annotations`, JSON.stringify(props.annotationsData));
        // update state
        setAnnotationsList([...props.annotationsData.filter(data => data.type !== 'point')])
        props.onUpdateAnnotationsList(props.annotationsData)
    }

    function handleSort(sortType) {
        setSort(sortType)
        // let annotList
        // if (sortType === 'newest') {
        //     annotList = annotationsList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        // } else {
        //     annotList = annotationsList.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
        // }
        // setAnnotationsList([...annotList])
    }

    function handleFilter(filter) {
        setFilterType(filter)
        if (filter === 'all') {
            setAnnotationsList([...props.annotationsData])
        } else if (filter === 'note') {
            setAnnotationsList([...props.annotationsData.filter(data => data.class === 'Comment')])
        } else {
            setAnnotationsList([...props.annotationsData.filter(data => data.type === filter)])
        }
    }


    useEffect(() => {
        let filteredData = props.annotationsData
        if (props.compareEnabled) {
            const annotData = localStorage.getItem(`${props.pdfFile}/annotations`)
            filteredData = JSON.parse(annotData)
            filteredData && setAnnotationsList(filteredData || [])
        } else {
            if (props.annotationsData) {
                if (filterType !== 'all') {
                    filteredData = filteredData.filter(data => data.type === filterType)
                }
            }
            // do not include 'point' type of annotations
            filteredData && setAnnotationsList([...filteredData])
        }
        //remove leader line if data change
        removeLeaderLine()
    }, [props.annotationsData])

    function handleDeleteAnnotation(annotData, point) {
        let uuid = annotData.uuid
        let page = annotData.page && annotData.page.toString()
        let pdfPageContainer = document.getElementById(`pageContainer${page}`)
        let filteredData = annotationsList.filter(data => data.uuid !== annotData.uuid)

        if (!page) {
            uuid = point.uuid
            page = point.page && point.page.toString()
            pdfPageContainer = document.getElementById(`pageContainer${page}`)
            filteredData = filteredData.filter(data => data.uuid !== uuid)
        }

        Modal.confirm({
            title: 'Are you sure delete this annotation?',
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                try {
                    localStorage.setItem(`${documentId}/annotations`, JSON.stringify(filteredData))
                    pdfPageContainer && pdfPageContainer.querySelector(`[data-pdf-annotate-id='${uuid}']`).remove()
                    setAnnotationsList(filteredData)
                    props.onUpdateAnnotationsList(filteredData)
                    removeLeaderLine()
                } catch (error) {
                    console.log('err', error)
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    function handleDeleteReply(annotId, replyId) {
        const index = props.annotationsData.findIndex(data => data.uuid === annotId)
        if (index !== -1) {
            props.annotationsData[index].reply = props.annotationsData[index].reply.filter(reply => reply.uuid !== replyId)
        }

        localStorage.setItem(`${props.pdfFile}/annotations`, JSON.stringify(props.annotationsData));
        setAnnotationsList([...props.annotationsData.filter(data => data.type !== 'point')])
        props.onUpdateAnnotationsList(props.annotationsData)

        setActiveEditCommentReplyId('')
        setActiveEditCommentReplyMessage('')
    }

    function handleSearch(searchTerm, searchKeys) {
        const convertStringDotNotation = (desc, obj) => {
            var arr = desc.split('.')
            while (arr.length && (obj = obj[arr.shift()]));
            return obj
        }

        const newArrayObj = props.annotationsData
        // if SearchTerm is Empty return original data.
        if (!searchTerm) {
            return setAnnotationsList([...newArrayObj])
        }
        const newArray = []
        newArrayObj.forEach((rowItem, i) => {
            searchKeys.forEach(keys => {
                const rowItemKeys = convertStringDotNotation(keys, { ...rowItem })
                const isFound = (rowItemKeys) && rowItemKeys.toString().toLowerCase().includes(searchTerm.toLowerCase())
                if (isFound) newArray.push(rowItem)
                if (rowItem.reply) {
                    rowItem.reply.map(data => {
                        const foundReply = (rowItemKeys) && data.message.toLowerCase().includes(searchTerm.toLowerCase())
                        if (foundReply) newArray.push(rowItem)
                    })
                }
            })
        })
        // Removed Duplicate Entry in Array.
        const newArrayElement = newArray.filter(function (elem, index, self) {
            return index === self.indexOf(elem)
        })
        setAnnotationsList([...newArrayElement])
        removeLeaderLine()
    }

    function annotationIconRenderer(type) {
        switch (type) {
            case 'area':
                return (
                    <div className='annot-icon'>
                        <Icon iconName="RectangleShape" styles={IconStyles} />
                        <span>Area</span>
                    </div>)

            case 'textbox':
                return (
                    <div className='annot-icon'>
                        <Icon iconName="InsertTextBox" styles={IconStyles} />
                        <span>Text</span>
                    </div>)

            case 'highlight':
                return (
                    <div className='annot-icon'>
                        <Icon iconName="Highlight" styles={IconStyles} />
                        <span>Highlight</span>
                    </div>)

            case 'strikeout':
                return (
                    <div className='annot-icon'>
                        <Icon iconName="Strikethrough" styles={IconStyles} />
                        <span>Strikeout</span>
                    </div>)

            case 'Comment':
                return (
                    <div className='annot-icon'>
                        <Icon iconName="Comment" styles={IconStyles} />
                        <span>Comment</span>
                    </div>)
            case 'drawing':
                return (
                    <div className='annot-icon'>
                        <Icon iconName="PenWorkspace" styles={IconStyles} />
                        <span>Drawing</span>
                    </div>)

            default:
                break;
        }
    }

    function handleUpdateComment(annotId, page) {
        console.log("handleUpdateComment", annotId, page)
        // update svg in viewer
        const viewer = window.document.getElementById(viewerId).querySelector(`[data-page-number='${page}']`);
        if (viewer) {
            const svgElement = viewer.querySelector(`[data-pdf-annotate-id='${annotId}']`)
            svgElement && (svgElement.textContent = activeEditCommentContent)

            const found = props.annotationsData.find(data => data.uuid === activeEditCommentId)
            found.content = activeEditCommentContent
            localStorage.setItem(`${props.pdfFile}/annotations`, JSON.stringify(props.annotationsData));
            props.onUpdateAnnotationsList(props.annotationsData)

            setActiveEditCommentId('')
            setActiveEditCommentContent('')
        }
    }

    function handleUpdateReply(annotId) {
        const found = props.annotationsData.find(data => data.uuid === annotId).reply.find(reply => reply.uuid === activeEditCommentReplyId)
        found.message = activeEditCommentReplyMessage
        found.dateEdited = new Date()
        localStorage.setItem(`${props.pdfFile}/annotations`, JSON.stringify(props.annotationsData));
        setActiveEditCommentReplyId('')
        setActiveEditCommentReplyMessage('')
    }

    function handleOnChangeColorText(color, annotId, page) {
        // update svg in viewer
        const viewer = window.document.getElementById(viewerId).querySelector(`[data-page-number='${page}']`);
        const svgElement = viewer.querySelector(`[data-pdf-annotate-id='${annotId}']`)
        svgElement && svgElement.setAttribute('fill', color)
        const found = props.annotationsData.find(data => data.uuid === annotId)
        found.color = color
        // update local storage
        localStorage.setItem(`${props.pdfFile}/annotations`, JSON.stringify(props.annotationsData));
        setColor(color)
        props.onUpdateAnnotationsList(props.annotationsData)
    }

    function handleOnChangeFontSizeText(size, annotId, page) {
        // update svg in viewer
        const viewer = window.document.getElementById(viewerId).querySelector(`[data-page-number='${page}']`);
        const svgElement = viewer.querySelector(`[data-pdf-annotate-id='${annotId}']`)
        svgElement && svgElement.setAttribute('font-size', size)
        const found = props.annotationsData.find(data => data.uuid === annotId)
        found.size = size
        // update local storage
        localStorage.setItem(`${props.pdfFile}/annotations`, JSON.stringify(props.annotationsData));
        setColor(color)
        props.onUpdateAnnotationsList(props.annotationsData)
    }

    function handleOnChangeColorDrawing(color, annotId, page) {
        // update svg in viewer
        const viewer = window.document.getElementById(viewerId).querySelector(`[data-page-number='${page}']`);
        const svgElement = viewer.querySelector(`[data-pdf-annotate-id='${annotId}']`)
        svgElement.setAttribute('stroke', color)
        const found = props.annotationsData.find(data => data.uuid === annotId)
        found.color = color
        // update local storage
        localStorage.setItem(`${props.pdfFile}/annotations`, JSON.stringify(props.annotationsData));
        setColor(color)
        props.onUpdateAnnotationsList(props.annotationsData)
    }

    function handleOnChangeAttribute(color, annotId, page, attribute) {
        console.log("xxxxxxxxxxx", color, annotId, page, attribute)
        // update svg in viewer
        const viewer = window.document.getElementById(viewerId).querySelector(`[data-page-number='${page}']`);
        const svgElement = viewer.querySelector(`[data-pdf-annotate-id='${annotId}']`)
        svgElement && svgElement.setAttribute(attribute, color)
        const found = props.annotationsData.find(data => data.uuid === annotId)
        found.color = color
        // update local storage
        localStorage.setItem(`${props.pdfFile}/annotations`, JSON.stringify(props.annotationsData));
        setColor(color)
        props.onUpdateAnnotationsList(props.annotationsData)

    }

    function handleOnChangeFontSizeDrawing(width, annotId, page) {
        // update svg in viewer
        const viewer = window.document.getElementById(viewerId).querySelector(`[data-page-number='${page}']`);
        const svgElement = viewer.querySelector(`[data-pdf-annotate-id='${annotId}']`)
        svgElement && svgElement.setAttribute('stroke-width', width)
        const found = props.annotationsData.find(data => data.uuid === annotId)
        found.width = width
        // update local storage
        localStorage.setItem(`${props.pdfFile}/annotations`, JSON.stringify(props.annotationsData));
        setColor(color)
        props.onUpdateAnnotationsList(props.annotationsData)

    }

    function handleOnChangeHeight(height, annotId, page) {
        // update svg in viewer
        const viewer = window.document.getElementById(viewerId).querySelector(`[data-page-number='${page}']`);
        const svgElement = viewer.querySelector(`[data-pdf-annotate-id='${annotId}']`)
        svgElement && svgElement.setAttribute('height', height)
        const found = props.annotationsData.find(data => data.uuid === annotId)
        found.height = height
        // update local storage
        localStorage.setItem(`${props.pdfFile}/annotations`, JSON.stringify(props.annotationsData));
        props.onUpdateAnnotationsList(props.annotationsData)

    }

    function handleOnChangeWidth(width, annotId, page) {
        // update svg in viewer
        const viewer = window.document.getElementById(viewerId).querySelector(`[data-page-number='${page}']`);
        const svgElement = viewer.querySelector(`[data-pdf-annotate-id='${annotId}']`)
        svgElement && svgElement.setAttribute('width', width)
        const found = props.annotationsData.find(data => data.uuid === annotId)
        found.width = width
        // update local storage
        localStorage.setItem(`${props.pdfFile}/annotations`, JSON.stringify(props.annotationsData));
        props.onUpdateAnnotationsList(props.annotationsData)

    }

    function scrollToView(page, id) {
        var elmnt = document.getElementById(`pageContainer${page}`);
        // elmnt.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        // elmnt.scrollIntoView(true);
        // window.scrollBy(0, -140);
        document.getElementById('pdf-container').scrollTop = elmnt.offsetTop - document.getElementById('pdf-container').offsetTop

        handleTwoElementConnector(id, page)
    }

    function handleTwoElementConnector(id, page) {
        console.log("PROPSSS", id, viewerId, page)
        const viewer = document.getElementById(viewerId).querySelector(`[data-page-number='${page}']`);
        removeLeaderLine()
        const origin = document.getElementById(`annotation-card-${id}`)
        const destination = viewer.querySelector(`[data-pdf-annotate-id='${id}']`)

        if (origin && destination) {
            try {
                var line = new LeaderLine(
                    origin,
                    destination
                );

                line.color = '#87B5F0'
                line.path = 'grid'
                line.size = 2;
            } catch (error) { }

            scrollListener(line)
            setActiveLeaderLine(line)
        }
        setActiveAnnotationId(id)
    }

    function removeLeaderLine() {
        const removeElements = (elms) => elms.forEach(el => el.remove());
        removeElements(document.querySelectorAll(".leader-line"));
        removeScrollListener(activeLeaderLine)
    }

    function watchPosition(line, removeLine = false) {
        try {
            if (removeLine) return line && line.remove()
            line && line.position && line.position();
        } catch (error) { }
    }

    function scrollListener(line) {
        document.getElementById('pdf-container').addEventListener('scroll', () => watchPosition(line));
        document.getElementById('content-list').addEventListener('scroll', () => watchPosition(line));
    }

    function removeScrollListener(line) {
        document.getElementById('pdf-container').addEventListener('scroll', () => watchPosition(line, true));
        document.getElementById('content-list').addEventListener('scroll', () => watchPosition(line, true));
    }

    // use id check, check first the class if it is a comment else return id
    function idCheck(uuid) {
        const found = props.annotationsData.find(x => x.uuid === uuid)
        let newUuid = ''

        if (found && found.class === 'Comment') {
            const comment = props.annotationsData.find(y => y.uuid === found.annotation)
            newUuid = comment && comment.uuid
        }
        else newUuid = uuid

        return newUuid
    }

    function disableAllAnnotation() {
        // UI.disableEdit();
        UI.disablePen();
        UI.disableText();
        UI.disablePoint();
        UI.disableRect();
    }

    return (
        <div className='annotation-container' onClick={() => props.clearSelection()} >
            <div style={{ display: props.rightSidebarCollapse ? 'flex' : 'none', position: 'absolute', left: 30, top: 10, justifyContent: 'space-between', flexDirection: 'column', alignItems: 'center' }}>
                <Icon
                    iconName='Comment'
                    styles={IconStyles}
                />
                <strong><b>{annotationsList.length}</b></strong>
            </div>
            <div style={{ display: !props.rightSidebarCollapse ? 'block' : 'none' }}>
                <div className='input-action'>
                    <div className='search'>
                        <Input
                            prefix={<SearchOutlined className="site-form-item-icon" />}
                            placeholder="Search author, type, content, etc."
                            size='small'
                            className='search'
                            onChange={(e) => handleSearch(e.target.value, ['authorName', 'content', 'class', 'type', 'color', 'page', 'size', 'type', 'status'])}
                        />
                    </div>
                    <Divider style={{ margin: 10 }} />
                    <div className='action-filter'>
                        <Row style={{ width: '100%' }}>
                            <Col sm={10} xs={10} md={8} lg={8}>
                                <Select defaultValue={filterType} style={{ width: 95, fontSize: 11 }} onChange={(value) => handleFilter(value)} >
                                    <Option value="all">All</Option>
                                    <Option value="note">Note</Option>
                                    <Option value="area">Area</Option>
                                    <Option value="highlight">Highlight</Option>
                                    <Option value="strikeout">Strikeout</Option>
                                    <Option value="textbox">Textbox</Option>
                                    <Option value="drawing">Drawing</Option>
                                    {/* <Option value="circle">Circle</Option>
                                    <Option value="rectangle">Rectangle</Option>
                                    <Option value="line">Line</Option>
                                    <Option value="image">Image</Option>
                                    <Option value="signature">Signature</Option> */}
                                </Select>
                            </Col>
                            <Col sm={14} xs={14} md={16} lg={16} align='right'>
                                <div>
                                    <span style={{ fontSize: 11 }}>Sort By:</span>&nbsp;
                                    <Select defaultValue="oldest" style={{ width: 95, fontSize: 11 }} onChange={(value) => handleSort(value)} >
                                        <Option value="oldest">Oldest</Option>
                                        <Option value="newest">Newest</Option>
                                    </Select>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className='content-list' id='content-list' style={{ height: props.viewControl === "view" ? props.compareEnabled ? 'calc(100vh - 261px)' : 'calc(100vh - 175px)' : 'calc(100vh - 246px)' }}>
                    {
                        (annotationsList && sort === 'newest' ? annotationsList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) :
                            annotationsList.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))).map((data, index) => {
                                if (data.type === 'point') return;
                                const found = props.annotationsData.find(x => x.uuid === data.annotation)
                                return (
                                    <div className={`card ${(activeAnnotationId === idCheck(data.uuid)) ? 'annotation-active' : ''}`} id={`annotation-card-${idCheck(data.uuid)}`} key={index} onClick={() => {
                                        // disabled all annotation function when clicking 'card annotation in the right side panel'
                                        // this is to focus and give way to leader line
                                        disableAllAnnotation()

                                        if (data.page) {
                                            // all type
                                            scrollToView(data.page, data.uuid)
                                        } else {
                                            // comment type
                                            // comment type does not have contain pages so that we need to get it in sister object in 'point'
                                            if (found) scrollToView(found.page, found.uuid)
                                        }
                                    }} >
                                        <div className='card-content'>
                                            <div className='user-info'>
                                                <div className='flex'>
                                                    <div className='image' >
                                                        <Avatar
                                                            size={52}
                                                            src={data.authorAvatarURL}
                                                        />
                                                    </div>
                                                    <div className='user-name'>
                                                        <p >{data.authorName}</p>
                                                        <div className='date'>
                                                            <span>{moment(data.createdAt).format('MM/DD/YYYY hh:mm A')}</span>
                                                            <div className='settings' >
                                                                <Dropdown overlay={<Menu>
                                                                    <Menu.Item onClick={() => {
                                                                        UI.disableEdit();
                                                                        setActiveEditCommentId(data.uuid)
                                                                        setActiveEditCommentContent(data.content)
                                                                        UI.enableEditToSpecificAnnotation(data.class === 'Comment' ? found.uuid : data.uuid); // to check if data type is 'comment' then look up to 'found variable'. else direct data.uuid
                                                                    }}>Edit</Menu.Item>
                                                                    <Menu.Item onClick={() => {
                                                                        handleDeleteAnnotation(data, found)
                                                                    }
                                                                    }> Delete </Menu.Item>
                                                                </Menu>} placement="bottomCenter">
                                                                    <FontAwesomeIcon icon={faCog} />
                                                                </Dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>
                                                    {annotationIconRenderer(data.type || data.class)}
                                                </div>
                                            </div>

                                            <AnnotationContent
                                                activeEditCommentId={activeEditCommentId}
                                                activeEditCommentContent={activeEditCommentContent}
                                                handleUpdateComment={handleUpdateComment}
                                                setActiveEditCommentContent={setActiveEditCommentContent}
                                                handleOnChangeAttribute={handleOnChangeAttribute}
                                                handleOnChangeFontSizeDrawing={handleOnChangeFontSizeDrawing}
                                                handleOnChangeColorText={handleOnChangeColorText}
                                                handleOnChangeHeight={handleOnChangeHeight}
                                                handleOnChangeWidth={handleOnChangeWidth}
                                                handleOnChangeFontSizeText={handleOnChangeFontSizeText}
                                                viewControl={props.viewControl}
                                                annotationsData={props.annotationsData}
                                                data={data}
                                            />
                                        </div>
                                        <Divider style={{ margin: 0 }} />
                                        {
                                            data.reply &&
                                            <Collapse bordered={false} defaultActiveKey={[index]} style={{ backgroundColor: 'transparent' }} >
                                                <Panel header={data.reply ? `${data.reply.length} Replies` : 'No Reply'} key={index}>
                                                    {
                                                        data.reply && data.reply.map((item, index) => {
                                                            return (
                                                                <div className='reply-list-content' key={index}>
                                                                    <div className='user-info'>
                                                                        <div className='flex'>
                                                                            <div className='image' >
                                                                                <Avatar
                                                                                    size={52}
                                                                                    src={data.authorAvatarURL}
                                                                                />
                                                                            </div>

                                                                            <div className='user-name'>
                                                                                <p >{data.authorName}</p>
                                                                                <div className='date'>
                                                                                    <span>{moment(item.createdAt).format('LLL')}</span>
                                                                                    <div className='settings' >
                                                                                        <Dropdown overlay={<Menu>
                                                                                            <Menu.Item onClick={() => {
                                                                                                const found = data.reply.find(reply => reply.uuid === item.uuid)
                                                                                                setActiveEditCommentReplyId(found.uuid)
                                                                                                setActiveEditCommentReplyMessage(found.message)
                                                                                            }}>Edit</Menu.Item>
                                                                                            <Menu.Item onClick={() => {
                                                                                                const found = data.reply.find(reply => reply.uuid === item.uuid)
                                                                                                handleDeleteReply(data.uuid, found.uuid)
                                                                                            }}> Delete </Menu.Item>
                                                                                        </Menu>} placement="bottomCenter">
                                                                                            <FontAwesomeIcon icon={faCog} />
                                                                                        </Dropdown>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        activeEditCommentReplyId === item.uuid ?
                                                                            <div>
                                                                                <Input.TextArea rows={5} value={activeEditCommentReplyMessage} onChange={(e) => {
                                                                                    setActiveEditCommentReplyMessage(e.target.value)
                                                                                }} />
                                                                                <Button type="primary" size='small' onClick={() => handleUpdateReply(data.uuid)}>Save</Button>
                                                                            </div>
                                                                            :
                                                                            <>
                                                                                <p className='content'>{item.message}</p>
                                                                                {(item.dateEdited && item.dateEdited !== item.createdAt) &&
                                                                                    <div class='edited'>(edited) {moment(item.dateEdited).format('MM/DD/YYYY hh:mm A')}</div>
                                                                                }
                                                                            </>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </Panel>
                                            </Collapse>
                                        }
                                        <ReplyContainer onSubmit={value => handleSubmitReply({
                                            uuid: data.uuid,
                                            message: value
                                        })} />
                                    </div>
                                )
                            })
                    }
                </div>
            </div>
        </div>
    );
}

const ReplyContainer = (props) => {

    const [replyMessage, setReplyMessage] = useState('')

    function handleSubmit() {
        if (replyMessage) {
            props.onSubmit(replyMessage)
            setReplyMessage('')
        }
    }
    return (
        <div className='reply-content'>
            <Input size='small' placeholder='Reply here...' value={replyMessage} onChange={(e) => setReplyMessage(e.target.value)} onPressEnter={() => handleSubmit()} />
            <Button type="primary" size='small' onClick={() => handleSubmit()}>Reply</Button>
        </div>
    )
}

const IconStyles = {
    root: {
        color: '#868e96',
        fontSize: '18px',
        fontWeight: 300,
        display: 'block',
        margin: -8
    }
}

function mapStateToProps(state) {
    console.log("state.client.pdf.annotationsList", state.client.pdf.annotationsList)
    return {
        annotationsData: state.client.pdf.annotationsList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onUpdateAnnotationsList: (annotations) => dispatch(updateAnnotationsList(annotations))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnnotationView)
