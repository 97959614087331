import React from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash.omit'
import { fabric } from "fabric";


class Image extends React.Component {
    static propTypes = {
        canvas: PropTypes.object,
        url: PropTypes.string.isRequired,
        scale: PropTypes.number.isRequired,
        top: PropTypes.number.isRequired,
    }

    static defaultProps = {
        scale: 1.0,
        opacity: 0,
    }

    componentDidMount() {
        const options = omit({ ...this.props, cornerSize: 6 }, ['scale'])
        const { canvas } = this.props
        fabric.Image.fromURL(this.props.url, img => {
            // img.scale(this.pro   ps.scale)
            // this.props.canvas.add(img)
            img.animate('opacity', 1, {
                duration: 500,
                onChange: canvas.renderAll.bind(canvas),
                easing: fabric.util.ease['easeInQuad']
            });
            canvas.add(img);
        }, options)
    }

    render() {
        return null
    }
}

export default Image
