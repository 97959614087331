import { useState, useEffect, useLayoutEffect } from 'react'
import { Tabs, Input, Button } from 'antd'
import TextAttribute from '../attributes/text'
import DrawingAttribute from '../attributes/drawing'
import StrikeoutAttribute from '../attributes/strikeout'
import HighlightAttribute from '../attributes/highlight'
import AreaAttribute from '../attributes/area'
import moment from 'moment'
const { TabPane } = Tabs;

const AnnotationContent = (props) => {
    const [activeKey, setActiveKey] = useState('1')

    useEffect(() => {
        setActiveKey('1')
    }, [props.viewControl])

    useLayoutEffect(() => {
        if (props.activeEditCommentId && props.activeEditCommentId === props.data.uuid) {
            if (props.data.type === 'textbox' || props.data.class === 'Comment') setActiveKey('1')
            else setActiveKey('2')
        }
    }, [props.activeEditCommentId])

    return (
        <Tabs activeKey={activeKey} onChange={key => setActiveKey(key)} size="small" >
            <TabPane tab="Content" key="1">
                <div className='details'>
                    {
                        // Comment
                        (props.activeEditCommentId === props.data.uuid && props.data.class === 'Comment') ?
                            <div>
                                <Input.TextArea rows={5} value={props.activeEditCommentContent} onChange={(e) => {
                                    props.setActiveEditCommentContent(e.target.value)
                                }} />
                                <Button type="primary" size='small' onClick={() => {
                                    if (props.data.page) {
                                        // all type
                                        props.handleUpdateComment(props.data.uuid, props.data.page)
                                    } else {
                                        // comment type does not have contain pages so that we need to get it in sister object in 'point'
                                        const found = props.annotationsData.find(x => x.uuid === props.data.annotation)
                                        props.handleUpdateComment(props.data.uuid, found.page)
                                    }

                                }}>Save</Button>
                            </div> :
                            <>
                                <p className='content'>{props.data.content}</p>
                                {(props.data && props.data.dateEdited && props.data.dateEdited !== props.data.createdAt) &&
                                    <div class='edited'>(edited) {moment(props.data.dateEdited).format('MM/DD/YYYY hh:mm A')}</div>
                                }
                            </>
                    }
                    {
                        (props.activeEditCommentId === props.data.uuid && props.data.type === 'textbox') &&
                        <div>
                            <Input.TextArea rows={5} value={props.activeEditCommentContent} onChange={(e) => {
                                props.setActiveEditCommentContent(e.target.value)
                            }} />
                            <Button type="primary" size='small' onClick={() => props.handleUpdateComment(props.data.uuid, props.data.page)}>Save</Button>
                        </div>
                    }
                </div>
            </TabPane>
            <TabPane tab="Attributes" key="2">
                <div className='attributes'>
                    {
                        (() => {
                            switch (props.data.type) {
                                case 'textbox':
                                    return <TextAttribute onChangeColor={(e) => props.handleOnChangeColorText(e, props.data.uuid, props.data.page)} color={props.data.color} onChangeFontSize={(e) => props.handleOnChangeFontSizeText(e, props.data.uuid, props.data.page)} fontSizeValue={props.data.size} />
                                case 'drawing':
                                    return <DrawingAttribute onChangeColor={(e) => props.handleOnChangeAttribute(e, props.data.uuid, props.data.page, 'stroke')} color={props.data.color} onChangeFontSize={(e) => props.handleOnChangeFontSizeDrawing(e, props.data.uuid, props.data.page)} fontSizeValue={props.data.size} />
                                case 'strikeout':
                                    return <StrikeoutAttribute onChangeColor={(e) => props.handleOnChangeAttribute(e, props.data.uuid, props.data.page, 'stroke')} color={props.data.color} />
                                case 'highlight':
                                    return <HighlightAttribute onChangeColor={(e) => props.handleOnChangeAttribute(e, props.data.uuid, props.data.page, 'fill')} color={props.data.color} />
                                case 'area':
                                    return <AreaAttribute onChangeColor={(e) => props.handleOnChangeAttribute(e, props.data.uuid, props.data.page, 'stroke')} onChangeHeight={(e) => props.handleOnChangeHeight(e, props.data.uuid, props.data.page)} onChangeWidth={(e) => props.handleOnChangeWidth(e, props.data.uuid, props.data.page)} color={props.data.color} width={props.data.width} height={props.data.height} />
                                default:
                                    return (<div className='no-attributes'><span>No Attributes Available</span></div>)
                                    break;
                            }
                        })()
                    }
                </div>
            </TabPane>
        </Tabs>
    )
}

export default AnnotationContent