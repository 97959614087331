import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'

import clientReducers from '../components/client/reducers'

// Global Reducers

const rootReducer = combineReducers({
  form,
  client: clientReducers,
  admin: {} // reducers for the admin
})

export default rootReducer
