
import React, { useState, useEffect } from 'react';
import { Button, Input, Radio } from 'antd'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faFileImage, faFont } from '@fortawesome/free-solid-svg-icons'
import PdfView from './viewer'
const PanelView = (props) => {
    const [radioActive, setRadioActive] = useState('thumbnail')
    const [textStringRecognition, setTextStringRecognition] = useState('')
    const [activePage, setActivePage] = useState(undefined)
    const scale = 0.4
    PDFJS.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/1.4.162/pdf.worker.min.js'
    const url = props.pdfFile
    async function loadPdfFile(currPage) {
        const container = document.querySelector('#pdf-thumbnail');
        const createElementDiv = document.createElement("canvas");
        createElementDiv.className = 'pdf-page-panel';
        container.appendChild(createElementDiv);
        PDFJS.getDocument(url).then(function (pdf) {
            pdf.getPage(currPage).then(function (page) {
                var viewport = page.getViewport(scale);
                var canvas = createElementDiv
                var context = canvas.getContext('2d');
                canvas.onclick = function () { scrollToView(currPage) }
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                canvas.id = `thumbnail-canvas-${currPage}`
                var renderContext = {
                    canvasContext: context,
                    viewport: viewport
                };
                page.render(renderContext);
            });
        });
    }

    function scrollToView(page) {
        setActivePage(page)
        var elmnt = document.getElementById(`pageContainer${page}`);

        // thumbnail
        const pdfPagePanel = document.getElementsByClassName('pdf-page-panel')
        for (let index = 0; index < pdfPagePanel.length; index++) {
            pdfPagePanel[index] && (pdfPagePanel[index].style = '')
        }

        document.getElementById(`thumbnail-canvas-${page}`).style = 'border: 2px solid lightgray'

        // outline
        const panelViewOutline = document.getElementsByClassName('panel-view-outline')
        for (let index = 0; index < panelViewOutline.length; index++) {
            panelViewOutline[index].style = ''
        }
        document.getElementById(`outline-canvas-${page}`) &&
            (document.getElementById(`outline-canvas-${page}`).style = 'text-decoration: underline')

        // control view scroll
        // elmnt.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        elmnt.scrollIntoView(true);
        window.scrollBy(0, -140);
    }

    function loadPdfOutline(currPage) {
        const container = document.querySelector('#pdf-outline');
        const createElementDiv = document.createElement("h1");
        createElementDiv.textContent = 'Outline ' + currPage;
        createElementDiv.className = 'panel-view-outline';
        createElementDiv.id = `outline-canvas-${currPage}`;
        createElementDiv.onclick = function () { scrollToView(currPage) }
        container.appendChild(createElementDiv);
    }

    useEffect(() => {
        props.textRecognition && props.textRecognition.then(res => {
            const uniq = [...new Set(res)];
            setTextStringRecognition(uniq.toString().replaceAll(',', ', '))
        })
    }, [props.textRecognition])

    useEffect(() => {
        document.querySelector('#pdf-thumbnail').innerHTML = ''
        PDFJS.getDocument(url).then(function (doc) {
            var numPages = doc.numPages;
            for (let index = 0; index < numPages; index++) {
                loadPdfOutline(index + 1)
                loadPdfFile(index + 1)
            }
        })
    }, [props.pdfFile])


    function getPdfLength() {
        if (document.querySelector('#pdf-thumbnail') && document.querySelector('#pdf-thumbnail').querySelectorAll('canvas')) {
            return document.querySelector('#pdf-thumbnail').querySelectorAll('canvas').length
        }
        return 0
    }
    return (
        <div className='menu-container'>
            <div className='view-actions'>
                <Radio.Group value='thumbnail' value={radioActive} onChange={(e) => setRadioActive(e.target.value)}>
                    <Radio.Button className='radio-btn' value="thumbnail"> <FontAwesomeIcon icon={faFileImage} /></Radio.Button>
                    <Radio.Button className='radio-btn' value="outline"> <FontAwesomeIcon icon={faList} /></Radio.Button>
                    {/* <Radio.Button className='radio-btn' value="bookmark"> <FontAwesomeIcon icon={faBookmark} /></Radio.Button> */}
                    <Radio.Button className='radio-btn' value="text-recognition"> <FontAwesomeIcon icon={faFont} /></Radio.Button>
                </Radio.Group>

            </div>
            <div className='content'>
                <div id='thumbnail' style={{ display: radioActive === 'thumbnail' ? 'block' : 'none' }}>
                    <div id="pdf-thumbnail" style={{ height: '85vh', textAlign: 'center' }}></div>
                    {/* <PdfView
                        id={'pdf-left-panel'}
                        scale={40}
                        rotation={0}
                        pdfFile={props.pdfFile}
                    /> */}
                </div>
                <div id='outline' style={{ display: radioActive === 'outline' ? 'block' : 'none', width: '100%' }}>
                    <div id="pdf-outline"></div>
                </div>
                <div id='bookmark' style={{ display: radioActive === 'bookmark' ? 'block' : 'none' }}>
                    <div className='bookmark-container' >
                        <Button style={{ backgroundColor: '#5E40D0', color: 'white', borderRadius: 5, margin: 10 }}>Add new Bookmark</Button>
                        <div className='bookmark-actions'>
                            <div className='bookmark-form' >
                                <Input placeholder='Input Bookmark name' />
                                <div className='submit-btn'>
                                    <Button style={{ backgroundColor: '#5E40D0', color: 'white' }}>Cancel</Button>
                                    <Button style={{ backgroundColor: '#5E40D0', color: 'white' }}>Save</Button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id='text-recognition' style={{ display: radioActive === 'text-recognition' ? 'block' : 'none', width: '100%' }}>
                    <h1 style={{ fontSize: 20 }}>Text Recognition</h1>
                    <p style={{ wordBreak: 'break-word', fontSize: 12, textAlign: 'justify' }}>{textStringRecognition}</p>
                </div>
            </div>
        </div >
    );
}

export default PanelView;