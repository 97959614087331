import React, { useState, useEffect } from 'react'
import { Row, Col, Select, Collapse, Input } from 'antd'
import ColorPicker from '../../../../../utilities/colorPicker'
const { Option } = Select;
const { Panel } = Collapse;
const Line = (props) => {
    const [strokeColor, setStrokeColor] = useState('')

    useEffect(() => {
        setStrokeColor(props.color)
    }, [props.color])

    function handleChangeColor(value) {
        props.onChangeColor(value)
        setStrokeColor(value)
    }

    return (
        <div>
            <Row>
                <Col md={12} lg={12} xs={24} sm={24} className='name'>
                    <span>Color</span>
                </Col>
                <Col md={12} lg={12} xs={24} sm={24} align="right" className='action'>
                    <ColorPicker onChange={handleChangeColor} value={strokeColor} />
                </Col>
            </Row>

            <Row style={{ marginTop: 3 }}>
                <Col md={12} lg={12} xs={24} sm={24} className='name'>
                    <span>Height</span>
                </Col>
                <Col md={12} lg={12} xs={24} sm={24} align="right" className='action'>
                    <Input defaultValue={props.height} onChange={(e) => props.onChangeHeight(e.target.value)} size='small' type='number' style={{ fontSize: 11 }} />
                </Col>
            </Row>

            <Row style={{ marginTop: 3 }}>
                <Col md={12} lg={12} xs={24} sm={24} className='name'>
                    <span>Width</span>
                </Col>
                <Col md={12} lg={12} xs={24} sm={24} align="right" className='action'>
                    <Input defaultValue={props.width} size='small' onChange={(e) => props.onChangeWidth(e.target.value)} type='number' style={{ fontSize: 11 }} />
                </Col>
            </Row>
        </div>
    )
}

export default Line