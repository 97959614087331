import { UPDATE_LOCALSTORAGE, UPDATE_ANNOTATIONS } from '../actions'
export default function reducer(state = {
  annotationsData: [],
  annotationsList: []
}, action) {
  switch (action.type) {
    case UPDATE_LOCALSTORAGE:
      return {
        ...state,
        annotationsData: action.payload
      }

    case UPDATE_ANNOTATIONS:
      return {
        ...state,
        annotationsList: action.payload
      }
    default:
      return {
        ...state
      }
  }
}
