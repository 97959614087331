import React, { useLayoutEffect, useEffect, useState, useRef, Fragment } from 'react';
import { Row, Col, Button, Radio, Select } from 'antd'
import Circle from '../../../../assets/images/shapes/circle.png'
import Line from '../../../../assets/images/shapes/line.png'
import Square from '../../../../assets/images/shapes/square.png'
import Triangle from '../../../../assets/images/shapes/triangle.png'
import { AnnotateToolbarv2, ShapesToolbar, InsertToolbar } from "../../pdf/components/toolbar.js";
import { initializeIcons, IconButton } from "office-ui-fabric-react";
import ColorPicker from '../../../../utilities/colorPicker'
import { downloadVideoPdf } from '../actions'
import { connect } from 'react-redux'

const RadioStyle = {
    width: '25%', border: '0px', textAlign: 'center', backgroundColor: '#e7ebee',
}
const UserActions = (props) => {
    const [actionTypeBtn, setActionTypeBtn] = useState('annotate')
    const [selectedShape, setSelectedShape] = useState('')
    const [strokeWidth, setStrokeWidth] = useState('8')
    const [fontSize, setFontSize] = useState('16')
    const [stroke, setStroke] = useState('')
    const [fill, setFill] = useState('')
    const [width, setWidth] = useState(8)
    const [penColor, setPenColor] = useState('')

    const shapesSettings = {
        stroke,
        fill,
        strokeWidth,
        fontSize,
        width,
        penColor
    }

    useEffect(() => {
        initializeIcons();
    }, [])

    useEffect(() => {
        props.onChange(actionTypeBtn);
    }, [actionTypeBtn])

    useEffect(() => {
        if (selectedShape !== '') {
            props.getSelectedShapeToAdd(selectedShape, shapesSettings)
        }
    }, [selectedShape])

    const IconStyles = {
        root: {
            color: '#868e96',
            fontSize: '21px',
            fontWeight: 300,
            display: 'block'
        }
    }

    function handleChangeSelectedShape(shape) {
        if (selectedShape === shape) {
            props.getSelectedShapeToAdd(selectedShape, shapesSettings)
        }
        setSelectedShape(shape)
    }

    return (
        <Fragment>
            <Row>
                <div className='annotate-actions'>
                    <Radio.Group
                        value={props.viewControl}
                        onChange={(e) => setActionTypeBtn(e.target.value)}
                        style={{ width: '50%' }}>
                        <Radio.Button className={actionTypeBtn === 'view' && 'active'} value="view" style={RadioStyle}> View</Radio.Button>
                        <Radio.Button className={actionTypeBtn === 'annotate' && 'active'} value="annotate" style={RadioStyle}> Annotate</Radio.Button>
                        <Radio.Button className={actionTypeBtn === 'shapes' && 'active'} value="shapes" style={RadioStyle}> Shapes</Radio.Button>
                        <Radio.Button className={actionTypeBtn === 'insert' && 'active'} value="insert" style={RadioStyle}> Insert</Radio.Button>
                    </Radio.Group>
                </div>
            </Row>
            {
                actionTypeBtn === 'annotate' &&
                <Row>
                    <div style={{ width: '100%' }}>
                        {/* <AnnotateToolbarv2 visible={actionTypeBtn === 'annotate'} /> */}
                        <div style={{ background: 'white', width: '100%', height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {/* <div onClick={() => handleChangeSelectedShape('drawing')} style={{ border: selectedShape === 'drawing' && '1px solid gray', marginLeft: 5, marginRight: 5 }} >
                                <IconButton
                                    iconProps={{ iconName: 'PenWorkspace' }}
                                    styles={IconStyles}
                                />
                            </div>
                            <Select onChange={(e) => setWidth(e)} defaultValue={width} style={{ width: 58, marginRight: 10 }}>
                                <Select.Option value={'1'}>1</Select.Option>
                                <Select.Option value={'2'}>2</Select.Option>
                                <Select.Option value={'4'}>4</Select.Option>
                                <Select.Option value={'6'}>6</Select.Option>
                                <Select.Option value={'8'}>8</Select.Option>
                                <Select.Option value={'10'}>10</Select.Option>
                                <Select.Option value={'12'}>12</Select.Option>
                                <Select.Option value={'14'}>14</Select.Option>
                            </Select>
                            <span style={{ fontSize: 10 }}></span><ColorPicker onChange={(value) => setPenColor(value)} /> */}

                            <div className="spacer"></div>
                            <div onClick={() => handleChangeSelectedShape('textNormal')} style={{ border: selectedShape === 'textNormal' && '1px solid gray', marginLeft: 5, marginRight: 5 }} >
                                <IconButton
                                    iconProps={{ iconName: 'InsertTextBox' }}
                                    title='Text Normal'
                                    styles={IconStyles}
                                />
                            </div>
                            <div onClick={() => handleChangeSelectedShape('textUnderline')} style={{ border: selectedShape === 'textUnderline' && '1px solid gray', marginLeft: 5, marginRight: 5 }} >
                                <IconButton
                                    iconProps={{ iconName: 'Underline' }}
                                    title='Text Underline'
                                    styles={IconStyles}
                                />
                            </div>
                            <div onClick={() => handleChangeSelectedShape('textBold')} style={{ border: selectedShape === 'textBold' && '1px solid gray', marginLeft: 5, marginRight: 5 }} >
                                <IconButton
                                    iconProps={{ iconName: 'Bold' }}
                                    title='Text Bold'
                                    styles={IconStyles}
                                />
                            </div>
                            <Select onChange={(e) => setFontSize(e)} defaultValue={fontSize} style={{ width: 58, marginRight: 10 }}>
                                <Select.Option value={'8'}>8</Select.Option>
                                <Select.Option value={'10'}>10</Select.Option>
                                <Select.Option value={'12'}>12</Select.Option>
                                <Select.Option value={'16'}>16</Select.Option>
                                <Select.Option value={'20'}>20</Select.Option>
                                <Select.Option value={'24'}>24</Select.Option>
                                <Select.Option value={'32'}>32</Select.Option>
                                <Select.Option value={'64'}>64</Select.Option>
                            </Select>

                            <span style={{ fontSize: 10 }}></span><ColorPicker onChange={(value) => setFill(value)} />
                            <div className="spacer"></div>
                            <div onClick={() => handleChangeSelectedShape('comment')} style={{ border: selectedShape === 'comment' && '1px solid gray', marginLeft: 5, marginRight: 5 }} >
                                <IconButton
                                    iconProps={{ iconName: 'Comment' }}
                                    styles={IconStyles}
                                />
                            </div>
                            <div className="spacer"></div>
                            <Button danger onClick={() => props.onDeleteCanvas()} style={{ margin: '0 2px' }}>Remove</Button>
                            <Button danger onClick={() => props.handleDownload()} style={{ margin: '0 2px' }}>Download</Button>
                        </div>
                    </div>
                </Row>
            }
            {
                // to be refactor after presentation;
                actionTypeBtn === 'shapes' &&
                <Row>
                    {/* <ShapesToolbar visible={actionTypeBtn === 'shapes'} /> */}
                    <div style={{ background: 'white', width: '100%', height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div onClick={() => handleChangeSelectedShape('line')} style={{ border: selectedShape === 'line' && '1px solid gray', marginLeft: 5, marginRight: 5 }} >
                            <IconButton
                                iconProps={{ iconName: 'Line' }}
                                title='Line'
                                styles={IconStyles}
                            />
                        </div>

                        <img onClick={() => handleChangeSelectedShape('circle')} src={Circle} height={18} width={18} style={{ border: selectedShape === 'circle' && '1px solid gray', marginLeft: 5, marginRight: 5 }} title='Circle' />
                        <img onClick={() => handleChangeSelectedShape('rect')} src={Square} height={18} width={18} style={{ border: selectedShape === 'rect' && '1px solid gray', marginLeft: 5, marginRight: 5 }} title='Rectangle/Square' />
                        {/* <div className="spacer"></div> */}
                        {/* <span style={{ fontSize: 12 }}>Shapes Settings: &nbsp;&nbsp; </span> */}
                        {/* <span style={{ fontSize: 10 }}>Stroke </span><ColorPicker onChange={(value) => setStroke(value)} /> */}
                        {/* <span style={{ fontSize: 10 }}>Stroke Width: &nbsp;&nbsp; </span>
                        <Select onChange={(e) => setStrokeWidth(e)} defaultValue={strokeWidth} style={{ width: 58, marginRight: 10 }}>
                            <Select.Option value={'1'}>1</Select.Option>
                            <Select.Option value={'2'}>2</Select.Option>
                            <Select.Option value={'4'}>4</Select.Option>
                            <Select.Option value={'8'}>8</Select.Option>
                            <Select.Option value={'16'}>16</Select.Option>
                            <Select.Option value={'32'}>32</Select.Option>
                            <Select.Option value={'64'}>64</Select.Option>
                        </Select> */}
                        {/* <span style={{ fontSize: 10 }}>Fill </span><ColorPicker onChange={(value) => setFill(value)} /> */}
                        {/* <div className="spacer"></div> */}
                        <Button danger onClick={() => props.onDeleteCanvas()} style={{ margin: '0 20px' }}>Remove</Button>
                    </div>
                </Row>
            }
            {
                actionTypeBtn === 'insert' &&
                <Row>
                    {/* <InsertToolbar visible={actionTypeBtn === 'insert'} /> */}
                    <div style={{ background: 'white', width: '100%', height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button style={{ margin: 4 }} onClick={() => props.handleAddImageModal()} title='Add Image'>Add Image</Button>
                        <Button style={{ margin: 4 }} onClick={() => props.handleAddSignatureModal()} title='Add Signature' >Add Signature</Button>
                    </div>

                </Row>
            }
        </Fragment>
    )
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        onDownloadVideoPdf: (id) => dispatch(downloadVideoPdf(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserActions)