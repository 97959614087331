
import initColorPicker from '../../../../../utilities/initColorPicker'
import PDFJSAnnotate from '../../../../../PDFJSAnnotate'
const { UI } = PDFJSAnnotate;

export function initialText(docId) {
    (function () {
        var textSize = void 0;
        var textColor = void 0;

        function initText() {
            var size = document.querySelector('.text-size');
            [8, 9, 10, 11, 12, 14, 18, 24, 30, 36, 48, 60, 72, 96].forEach(function (s) {
                size && size.appendChild(new Option(s, s));
            });

            setText(localStorage.getItem(docId + '/text/size') || 10, localStorage.getItem(docId + '/text/color') || '#000000');

            // adding if statement here meaning that dont add init color when useEffectLayout is rerendering
            if (document.querySelector('.text-color') && document.querySelector('.text-color').childNodes.length < 1) {
                initColorPicker(document.querySelector('.text-color'), textColor, function (value) {
                    setText(textSize, value);
                });
            }

        }

        function setText(size, color) {
            var modified = false;

            if (textSize !== size) {
                modified = true;
                textSize = size;
                localStorage.setItem(docId + '/text/size', textSize);
                document &&
                    document.querySelector('.toolbar .text-size') &&
                    (document.querySelector('.toolbar .text-size').value = textSize);
            }

            if (textColor !== color) {
                modified = true;
                textColor = color;
                localStorage.setItem(docId + '/text/color', textColor);

                var selected = document.querySelector('.toolbar .text-color.color-selected');

                if (selected) {
                    selected.classList.remove('color-selected');
                    selected.removeAttribute('aria-selected');
                }

                selected = document.querySelector('.toolbar .text-color[data-color="' + color + '"]');
                if (selected) {
                    selected.classList.add('color-selected');
                    selected.setAttribute('aria-selected', true);
                }
            }

            if (modified) {
                UI.setText(textSize, textColor)
            }
        }

        function handleTextSizeChange(e) {
            setText(e.target.value, textColor);
        }

        document &&
            document.querySelector('.toolbar .text-size') &&
            document.querySelector('.toolbar .text-size').addEventListener('change', handleTextSizeChange);

        initText();
    })();
}


export function initialPen(docId) {
    (function () {
        var penSize = void 0;
        var penColor = void 0;;

        function initPen() {
            let size = document.querySelector('.pen-size');
            for (let i = 0; i < 12; i++) {
                size && size.appendChild(new Option(i + 1, i + 1));
            }

            setPen(
                localStorage.getItem(`${docId}/pen/size`) || 1,
                localStorage.getItem(`${docId}/pen/color`) || '#000000'
            );

            // adding if statement here meaning that dont add init color when useEffectLayout is rerendering
            if (document.querySelector('.pen-color') && document.querySelector('.pen-color').childNodes.length < 1) {
                initColorPicker(document.querySelector('.pen-color'), penColor, function (value) {
                    setPen(penSize, value);
                });
            }

        }

        function setPen(size, color) {
            let modified = false;

            if (penSize !== size) {
                modified = true;
                penSize = size;
                localStorage.setItem(`${docId}/pen/size`, penSize);
                document && document.querySelector('.pen-size') &&
                    document.querySelector('.pen-size').value &&
                    (document.querySelector('.pen-size').value = penSize);
            }

            if (penColor !== color) {
                modified = true;
                penColor = color;
                localStorage.setItem(`${docId}/pen/color`, penColor);

                let selected = document.querySelector('.pen-color.color-selected');
                if (selected) {
                    selected.classList.remove('color-selected');
                    selected.removeAttribute('aria-selected');
                }

                selected = document && document.querySelector(`.pen-color[data-color="${color}"]`);
                if (selected) {
                    selected.classList.add('color-selected');
                    selected.setAttribute('aria-selected', true);
                }
            }

            if (modified) {
                UI.setPen(penSize, penColor);
            }
        }

        function handlePenSizeChange(e) {
            setPen(e.target.value, penColor);
        }

        document.querySelector('.pen-size') &&
            document.querySelector('.pen-size').addEventListener('change', handlePenSizeChange);

        initPen();
    })();
}