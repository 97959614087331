
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Button, Input, Select, Divider, Collapse, Dropdown, Menu, Modal, Avatar, Row, Col } from 'antd'
import '../index.scss'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { v4 as uuidv4 } from 'uuid';
import { ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { initializeIcons } from "office-ui-fabric-react";
import { Icon } from "office-ui-fabric-react";
import AnnotationContent from './annotationContent'
import Guest from '../../../../../assets/images/guestIcon.png'

const { Panel } = Collapse;
const { Option } = Select;
const IconStyles = {
    root: {
        // color: '#868e96',
        fontSize: '21px',
        fontWeight: 300,
        display: 'block'
    }
}

const AnnotationView = (props) => {
    const [annotationsList, setAnnotationsList] = useState([])
    const [filter, setFilter] = useState('')
    const [search, setSearch] = useState('')
    const [sort, setSort] = useState('')
    const [activeEditCommentReplyId, setActiveEditCommentReplyId] = useState('')
    const [activeEditCommentReplyMessage, setActiveEditCommentReplyMessage] = useState('')
    const [activeAnnotationId, setActiveAnnotationId] = useState()
    const [activeEditCommentId, setActiveEditCommentId] = useState('')
    const [activeEditCommentContent, setActiveEditCommentContent] = useState('')

    function handleSubmitReply(data) {
        const authorAvatarUrl = localStorage.getItem('/author/url')
        const authorId = localStorage.getItem('/author/id')
        const authorName = localStorage.getItem('/author/name')

        console.log("data", data)
        const index = props.data.findIndex(item => item.uuid === data.uuid)
        if (props.data[index].reply) {
            props.data[index].reply.push({ uuid: uuidv4(), message: data.message, createdAt: new Date(), authorAvatarUrl, authorId, authorName })
        } else {
            props.data[index].reply = []
            props.data[index].reply.push({ uuid: uuidv4(), message: data.message, createdAt: new Date(), authorAvatarUrl, authorId, authorName })
        }
        props.setData(props.data)
        localStorage.setItem(`${props.videoUrl}/video/annotations`, JSON.stringify(props.data))

    }


    function handleDeleteAnnotation(annotData) {
        console.log("annotData", annotData)
        const id = annotData.uuid

        const filteredData = props.data.filter(data => data.uuid !== annotData.uuid)
        Modal.confirm({
            title: 'Are you sure delete this annotation?',
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                try {
                    props.setData(filteredData)
                    localStorage.setItem(`${props.videoUrl}/video/annotations`, JSON.stringify(filteredData))
                } catch (error) {
                    console.log('err', error)
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    function handleDeleteReply(annotId, replyId) {
        const index = props.data.findIndex(data => data.uuid === annotId)
        if (index !== -1) {
            props.data[index].reply = props.data[index].reply.filter(reply => reply.uuid !== replyId)
        }

        props.setData([...props.data])
        localStorage.setItem(`${props.videoUrl}/video/annotations`, JSON.stringify(props.data))

        setActiveEditCommentReplyId('')
        setActiveEditCommentReplyMessage('')
    }

    function handleSearch(searchTerm, searchKeys) {
        const convertStringDotNotation = (desc, obj) => {
            var arr = desc.split('.')
            while (arr.length && (obj = obj[arr.shift()]));
            return obj
        }

        const newArrayObj = props.annotationsData
        // if SearchTerm is Empty return original data.
        if (!searchTerm) {
            return setAnnotationsList([...newArrayObj])
        }
        const newArray = []
        newArrayObj.forEach((rowItem, i) => {
            searchKeys.forEach(keys => {
                const rowItemKeys = convertStringDotNotation(keys, { ...rowItem })
                const isFound = (rowItemKeys) && rowItemKeys.toString().toLowerCase().includes(searchTerm.toLowerCase())
                if (isFound) newArray.push(rowItem)
            })
        })
        // Removed Duplicate Entry in Array.
        const newArrayElement = newArray.filter(function (elem, index, self) {
            return index === self.indexOf(elem)
        })
        setAnnotationsList([...newArrayElement])
    }

    function handleSort(sortType) {
        setSort(sortType)
        // let annotList
        // if (sortType === 'newest') {
        //     annotList = props.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        // } else {
        //     annotList = props.data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
        // }
        // props.setData(annotList)
    }

    function handleFilter(filterType) {
        setFilter(filterType)
    }

    function handleSortBy(value) {
        const sortedData = annotationsList.sort(function (a, b) {
            return a[value] > b[value]
        });
        setAnnotationsList([...sortedData])
    }

    function handleOnChangeFontSizeText(size, annotId) {
        const canvas = props.refDesignCanvas.state.canvas
        canvas.statefullCache = true;

        canvas.getObjects().forEach(function (o) {
            if (o.uuid === annotId) {
                o.set("fontSize", size);
                canvas.renderAll();
            }
        })

        // update local storage
        const annotations = JSON.parse(localStorage.getItem(`${props.videoUrl}/video/annotations`))
        const index = annotations.findIndex(data => data.uuid === annotId)
        if (index != -1) {
            annotations[index].fontSize = size
            localStorage.setItem(`${props.videoUrl}/video/annotations`, JSON.stringify(annotations));
        }
    }

    function handleOnChangeColorText(color, annotId) {
        const canvas = props.refDesignCanvas.state.canvas
        canvas.statefullCache = true;

        canvas.getObjects().forEach(function (o) {
            if (o.uuid === annotId) {
                o.set("fill", color);
                canvas.renderAll();
            }
        })

        // update local storage
        const annotations = JSON.parse(localStorage.getItem(`${props.videoUrl}/video/annotations`))
        const index = annotations.findIndex(data => data.uuid === annotId)
        if (index != -1) {
            annotations[index].fill = color
            localStorage.setItem(`${props.videoUrl}/video/annotations`, JSON.stringify(annotations));
            props.setData(annotations)
        }
    }

    function handleOnChangeAttribute(annotId, attribute, value) {
        // update svg in viewer
        const canvas = props.refDesignCanvas.state.canvas
        canvas.statefullCache = true;

        canvas.getObjects().forEach(function (o) {
            if (o.uuid === annotId) {
                console.log("O Value", o)
                o.set(attribute, value);
                canvas.renderAll();
            }
        })

        // update local storage
        const annotations = JSON.parse(localStorage.getItem(`${props.videoUrl}/video/annotations`))
        const index = annotations.findIndex(data => data.uuid === annotId)
        if (index != -1) {
            annotations[index][attribute] = value
            localStorage.setItem(`${props.videoUrl}/video/annotations`, JSON.stringify(annotations));
            props.setData(annotations)
        }

    }

    function handleUpdateComment(annotId) {
        console.log("handleUpdateComment", annotId)
        const canvas = props.refDesignCanvas.state.canvas
        canvas.statefullCache = true;

        canvas.getObjects().forEach(function (o) {
            if (o.uuid === annotId) {
                o.set('comment', activeEditCommentContent);
                canvas.renderAll();

                const index = props.data.findIndex(data => data.uuid === annotId)
                if (index !== -1) {
                    props.data[index].comment = activeEditCommentContent
                }
            }
        })

        // update svg in viewer

        // update local storage
        const annotations = JSON.parse(localStorage.getItem(`${props.videoUrl}/video/annotations`))
        const index = annotations.findIndex(data => data.uuid === annotId)
        if (index != -1) {
            annotations[index]['comment'] = activeEditCommentContent
            localStorage.setItem(`${props.videoUrl}/video/annotations`, JSON.stringify(annotations));
        }

        setActiveEditCommentId('')
        setActiveEditCommentContent('')
    }


    function handleActiveObject(uuid) {
        setActiveAnnotationId(uuid)
        // const canvas = props.refDesignCanvas.state.canvas
        // canvas.getObjects().forEach(function (o) {
        //     if (o.uuid === uuid) {
        //         canvas.setActiveObject(o);
        //     }
        // })
    }

    function handleUpdateReply(annotId) {
        const found = props.data.find(data => data.uuid === annotId).reply.find(reply => reply.uuid === activeEditCommentReplyId)
        found.message = activeEditCommentReplyMessage
        // localStorage.setItem(`${props.pdfFile}/annotations`, JSON.stringify(props.annotationsData));
        setActiveEditCommentReplyId('')
        setActiveEditCommentReplyMessage('')
    }

    function removeAllAnnotations() {
        // Modal.confirm({
        //     title: 'Do you Want to delete these items?',
        //     icon: <ExclamationCircleOutlined />,
        //     content: 'You can not undo this',
        //     onOk() {

        //         localStorage.setItem(`${props.videoUrl}/video/annotations`, JSON.stringify([]));
        //         props.setData([])
        //     },
        //     onCancel() {
        //         console.log('Cancel');
        //     },
        // });


        const authorId = localStorage.getItem(`/author/id`)
        Modal.confirm({
            title: 'Delete',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you Want to delete all annotations?',
            onOk() {
                // authorId string 0 meaning this is a guest user
                // will delete all annotation for that user
                if (authorId === '0') {
                    localStorage.setItem(`${props.videoUrl}/video/annotations`, JSON.stringify([]));
                    props.setData([])
                } else {
                    // author is not 0 meaning that this is a logged user
                    // will delete annotations related to that user
                    const annotStorage = JSON.parse(localStorage.getItem(`${props.videoUrl}/video/annotations`))
                    const filterAnnotStorage = annotStorage.filter(data => data.authorId !== authorId)
                    localStorage.setItem(`${props.videoUrl}/video/annotations`, JSON.stringify(filterAnnotStorage));
                    props.setData(filterAnnotStorage)
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });

    }


    useEffect(() => {
        if (props.data) {
            setAnnotationsList([...props.data])
        }
    }, [props.data])

    function annotationIconRenderer(type) {
        switch (type) {
            case 'textNormal':
                return (
                    <div className='annot-icon'>
                        <Icon iconName="InsertTextBox" styles={IconStyles} />
                        <span>Normal</span>
                    </div>)

            case 'textUnderline':
                return (
                    <div className='annot-icon'>
                        <Icon iconName="Underline" styles={IconStyles} />
                        <span>Underline</span>
                    </div>)

            case 'textBold':
                return (
                    <div className='annot-icon'>
                        <Icon iconName="Bold" styles={IconStyles} />
                        <span>Bold</span>
                    </div>)

            case 'line':
                return (
                    <div className='annot-icon'>
                        <Icon iconName="Line" styles={IconStyles} />
                        <span>Line</span>
                    </div>)

            case 'circle':
                return (
                    <div className='annot-icon'>
                        <Icon iconName="CircleRing" styles={IconStyles} />
                        <span>Circle</span>
                    </div>)
            case 'rect':
                return (
                    <div className='annot-icon'>
                        <Icon iconName="RectangleShape" styles={IconStyles} />
                        <span>Rectangle</span>
                    </div>)
            case 'comment':
                return (
                    <div className='annot-icon'>
                        <Icon iconName="Comment" styles={IconStyles} />
                        <span>Comment</span>
                    </div>)
            case 'image':
                return (
                    <div className='annot-icon'>
                        <Icon iconName="FileImage" styles={IconStyles} />
                        <span>Image</span>
                    </div>)

            case 'signature':
                return (
                    <div className='annot-icon'>
                        <Icon iconName="InsertSignatureLine" styles={IconStyles} />
                        <span>Signature</span>
                    </div>)

            default:
                break;
        }
    }

    useEffect(() => {
        initializeIcons();
    }, [])

    return (
        <div className='video-annotation-container' style={{ overflow: 'auto' }}>
            <div className='input-action'>
                <div className='search'>
                    <Input
                        prefix={<SearchOutlined className="site-form-item-icon" />}
                        placeholder="Search"
                        size='small'
                        className='search'
                        // onChange={(e) => handleSearch(e.target.value, ['content', 'class', 'type', 'color', 'page', 'size', 'type', 'status'])}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                <Divider style={{ margin: 10 }} />
                <div className='action-filter'>
                    <Row style={{ width: '100%' }}>
                        <Col sm={24} xs={24} md={8} lg={8}>
                            <Select defaultValue="" style={{ width: 115, fontSize: 11 }} onChange={(value) => handleFilter(value)} >
                                <Option value="">All</Option>
                                <Option value="textNormal">Text Normal</Option>
                                <Option value="textUnderline">Text Underline</Option>
                                <Option value="textBold">Text Bold</Option>
                                <Option value="line">Line</Option>
                                <Option value="circle">Circle</Option>
                                <Option value="rect">Rectangle</Option>
                                <Option value="comment">Comment</Option>
                                <Option value="image">Image</Option>
                                <Option value="signature">Signature</Option>
                            </Select>
                        </Col>
                        <Col sm={24} xs={24} md={16} lg={16} align='right'>
                            <div>
                                <span style={{ fontSize: 11 }}>Sort By:</span>&nbsp;
                                <Select defaultValue="oldest" style={{ width: 95, fontSize: 11 }} onChange={(value) => handleSort(value)} >
                                    <Option value="oldest">Oldest</Option>
                                    <Option value="newest">Newest</Option>
                                </Select>
                            </div>
                        </Col>
                        <Row>
                            <Col>
                                <div className='remove-all-btn-container'>
                                    <Button className='remove-all-btn' danger onClick={() => removeAllAnnotations()}><span>Remove All</span></Button>
                                </div>
                            </Col>
                        </Row>
                    </Row>
                </div>
            </div>

            <div className='content'>
                {
                    // props.data && props.data.filter(annot => filter === '' ? annot : annot.type === filter).map((data, index) => {

                    (props.data && sort === 'newest' ? props.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) :
                        props.data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))).map((data, index) => {

                            // filter
                            if ((filter !== '') && data.type !== filter) return
                            // search 
                            // if (search !== '' &&
                            //     (data.type && !data.type.toLowerCase().includes(search.toLowerCase())) &&
                            //     (data.authorName && !data.authorName.toLowerCase().includes(search.toLowerCase())) &&
                            //     (data.comment && !data.comment.toLowerCase().includes(search.toLowerCase())) &&
                            //     (data.text && !data.text.toLowerCase().includes(search.toLowerCase()))
                            // ) return
                            if (search !== '') {
                                console.log("search", search)
                                var found = false;
                                if (data.type && data.type.toLowerCase().includes(search.toLowerCase())) found = true;
                                if (data.authorName && data.authorName.toLowerCase().includes(search.toLowerCase())) found = true;
                                if (data.comment && data.comment.toLowerCase().includes(search.toLowerCase())) found = true;
                                if (data.text && data.text.toLowerCase().includes(search.toLowerCase())) found = true;

                                if (!found) return;
                            }

                            return (
                                <div key={index} onClick={() => handleActiveObject(data.uuid)}>
                                    <h1 className='time-range'> {moment.utc(data.startTime * 1000).format('HH:mm:ss')} - {moment.utc(data.endTime * 1000).format('HH:mm:ss')}</h1>
                                    <div className={`card ${(activeAnnotationId === data.uuid) ? 'annotation-active' : ''}`} key={index} onClick={() => props.handleUpdateSeekChange(data.startTime)} style={{ cursor: 'pointer' }}>
                                        <div className='card-content'>
                                            <div className='user-info'>
                                                <div className='flex'>
                                                    <div className='image' >
                                                        <Avatar
                                                            size={52}
                                                            src={Guest}
                                                        />
                                                    </div>
                                                    <div className='user-name'>
                                                        <p >{data.authorName || 'Guest'}</p>
                                                        <div className='date'>
                                                            <span>{moment(data.createdAt).format('MM/DD/YYYY hh:mm A')}</span>
                                                            <div className='settings' >
                                                                <Dropdown overlay={<Menu>
                                                                    <Menu.Item onClick={() => {
                                                                        setActiveEditCommentId(data.uuid)
                                                                        setActiveEditCommentContent(data.comment)
                                                                    }}>Edit</Menu.Item>
                                                                    <Menu.Item onClick={() => handleDeleteAnnotation(data)}> Delete </Menu.Item>
                                                                </Menu>} placement="bottomCenter">
                                                                    <FontAwesomeIcon icon={faCog} />
                                                                </Dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>
                                                    {annotationIconRenderer(data.type || data.class)}
                                                </div>
                                            </div>

                                            <AnnotationContent
                                                activeEditCommentId={activeEditCommentId}
                                                activeEditCommentContent={activeEditCommentContent}
                                                handleUpdateComment={handleUpdateComment}
                                                setActiveEditCommentContent={setActiveEditCommentContent}
                                                handleOnChangeAttribute={handleOnChangeAttribute}
                                                handleOnChangeColorText={handleOnChangeColorText}
                                                handleOnChangeFontSizeText={handleOnChangeFontSizeText}
                                                annotationsData={props.data}
                                                // viewControl={props.viewControl}
                                                data={data}
                                            />
                                        </div>
                                        <Divider />
                                        {
                                            data.reply &&
                                            <Collapse bordered={false} defaultActiveKey={[index]} style={{ backgroundColor: 'transparent' }}>
                                                <Panel header={data.reply ? `${data.reply.length} Replies` : 'No Reply'} key={index}>
                                                    {
                                                        data.reply && data.reply.map((item, index) => {
                                                            return (
                                                                <div className='reply-list-content' key={index}>
                                                                    <div className='user-info'>
                                                                        <div className='flex'>
                                                                            <div className='image' >
                                                                                <Avatar
                                                                                    size={52}
                                                                                    src={Guest}
                                                                                />
                                                                            </div>

                                                                            <div className='user-name'>
                                                                                <p >{data.authorName}</p>
                                                                                <div className='date'>
                                                                                    <span>{moment(item.createdAt).format('LLL')}</span>
                                                                                    <div className='settings' >
                                                                                        <Dropdown overlay={<Menu>
                                                                                            <Menu.Item onClick={() => {
                                                                                                const found = data.reply.find(reply => reply.uuid === item.uuid)
                                                                                                setActiveEditCommentReplyId(found.uuid)
                                                                                                setActiveEditCommentReplyMessage(found.message)
                                                                                            }}>Edit</Menu.Item>
                                                                                            <Menu.Item onClick={() => {
                                                                                                const found = data.reply.find(reply => reply.uuid === item.uuid)
                                                                                                handleDeleteReply(data.uuid, found.uuid)
                                                                                            }}> Delete </Menu.Item>
                                                                                        </Menu>} placement="bottomCenter">
                                                                                            <FontAwesomeIcon icon={faCog} />
                                                                                        </Dropdown>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        activeEditCommentReplyId === item.uuid ?
                                                                            <div>
                                                                                <Input.TextArea rows={5} value={activeEditCommentReplyMessage} onChange={(e) => {
                                                                                    setActiveEditCommentReplyMessage(e.target.value)
                                                                                }} />
                                                                                <Button type="primary" size='small' onClick={() => handleUpdateReply(data.uuid)}>Save</Button>
                                                                            </div>
                                                                            :
                                                                            <p className='content'>{item.message}</p>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </Panel>
                                            </Collapse>
                                        }


                                        <ReplyContainer onSubmit={value => handleSubmitReply({
                                            uuid: data.uuid,
                                            message: value
                                        })} />
                                    </div>
                                </div>

                            )
                        })
                }
            </div>
        </div>
    );
}


const ReplyContainer = (props) => {

    const [replyMessage, setReplyMessage] = useState('')

    function handleSubmit() {
        if (replyMessage) {
            props.onSubmit(replyMessage)
            setReplyMessage('')
        }
    }
    return (
        <div className='reply-content'>
            <Input size='small' placeholder='Reply here...' value={replyMessage} onChange={(e) => setReplyMessage(e.target.value)} onPressEnter={() => handleSubmit()} />
            <Button type="primary" size='small' onClick={() => handleSubmit()}>Reply</Button>
        </div>
    )
}


function mapStateToProps(state) {
    return {
        annotationsData: state.client.pdf.annotationsData,
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AnnotationView)
