import React from 'react'
import { Row, Col, Select, Collapse } from 'antd'
import ColorPicker from '../../../../../utilities/colorPicker'
const { Option } = Select;
const { Panel } = Collapse;
const Text = (props) => {
    return (
        <div>
            <Row>
                <Col md={12} lg={12} xs={24} sm={24} className='name'>
                    <span>Color</span>
                </Col>
                <Col md={12} lg={12} xs={24} sm={24} align="right" className='action'>
                    <ColorPicker onChange={props.onChangeColor} value={props.color} />
                </Col>
            </Row>
            <Row>
                <Col md={12} lg={12} xs={24} sm={24} className='name'>
                    <span>Font Size</span>
                </Col>
                <Col md={12} lg={12} xs={24} sm={24} align="right" className='action'>
                    <Select
                        size="small"
                        labelInValue
                        onChange={(e) => props.onChangeFontSize(e.value)}
                        defaultValue={{ value: props.fontSizeValue.toString() }}
                        style={{ width: 50, fontSize: 11 }}
                    >
                        <Option value="8">8</Option>
                        <Option value="9">9</Option>
                        <Option value="10">10</Option>
                        <Option value="11">11</Option>
                        <Option value="12">12</Option>
                        <Option value="14">14</Option>
                        <Option value="18">18</Option>
                        <Option value="24">24</Option>
                        <Option value="30">30</Option>
                        <Option value="36">36</Option>
                        <Option value="48">48</Option>
                        <Option value="60">60</Option>
                        <Option value="72">72</Option>
                        <Option value="96">96</Option>
                    </Select>
                </Col>
            </Row>
        </div>
    )
}

export default Text