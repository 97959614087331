import React from 'react'
import { Row, Col, Select, Collapse } from 'antd'
import ColorPicker from '../../../../../utilities/colorPicker'
const { Option } = Select;
const { Panel } = Collapse;
const Strikeout = (props) => {
    return (
        <div>
            <Row>
                <Col md={12} lg={12} xs={24} sm={24} className='name'>
                    <span>Line Color</span>
                </Col>
                <Col md={12} lg={12} xs={24} sm={24} align="right" className='action'>
                    <ColorPicker onChange={props.onChangeColor} value={props.color} />
                </Col>
            </Row>
        </div>
    )
}

export default Strikeout