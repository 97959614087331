import React from 'react'
import { Button, Col, Layout, Modal, Row } from 'antd'
import Upload from './upload'
class AddImage extends React.Component {

    constructor() {
        super()
        this.state = {
            imageFile: ''
        }
    }
    handleSubmit = () => {
        this.props.handleAddImageModal(this.state.imageFile)
    }

    render() {
        return (
            <Modal title="Add Signature" onCancel={() => this.props.onCancel()} visible={this.props.visible} width={820} footer={null}>
                <Layout>
                    <Layout.Content>
                        <Upload
                            getFile={e => this.setState({ imageFile: e })}
                        />
                    </Layout.Content>
                </Layout>
                <Row style={{ width: '100%' }}>
                    <Col span={24} align='right'>
                        <Button style={{ margin: 4 }} onClick={() => this.handleSubmit()}>Add Image</Button>
                    </Col>
                </Row>
            </Modal>
        )
    }
}

export default AddImage
