
import React, { useState, useEffect } from 'react';
import { Button, Input } from 'antd'
import '../index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { IconButton, Icon } from "office-ui-fabric-react";


const Scale = (props) => {
    const [value, setValue] = useState(props.value)
    const maxValue = 150
    const minValue = 0

    function increase() {
        if (value >= maxValue) return
        const currValue = +value + 5
        setValue(currValue)
        props.onChange(currValue)
    }

    function decrease() {
        if (value <= minValue) return
        const currValue = +value - 5
        setValue(currValue)
        props.onChange(currValue)
    }

    function handleChangeValue(value) {
        const reg = /^-?\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
            if (+value > maxValue) {
                props.onChange(maxValue)
                setValue(maxValue)
            } else if (+value < minValue) {
                props.onChange(minValue)
                setValue(minValue)
            } else {
                props.onChange(value || 100)
                setValue(value)
            }
        }
    }

    useEffect(() => {
        // remove leader line when creating an actions with these buttons
        const removeElements = (elms) => elms.forEach(el => el.remove());
        removeElements(document.querySelectorAll(".leader-line"));
    }, [value])

    return (
        <Input className="scale" style={{ width: 180, margin: 10 }}
            maxLength={3}
            addonAfter={
                <Button size='small' className='no-border' onClick={() => decrease()} >
                    <FontAwesomeIcon icon={faMinus} color="#5F41d2" />
                </Button>

            }
            addonBefore={
                <Button size='small' className='no-border' onClick={() => increase()}>
                    <FontAwesomeIcon icon={faPlus} color="#5F41d2" />
                </Button>
            }
            value={value}
            onChange={(e) => setValue(e.target.value)}

            onPressEnter={(e) => handleChangeValue(e.target.value)}
        />

    );
}

export default Scale;