import React from 'react'
import PropTypes from 'prop-types'
import { fabric } from 'fabric'

class Circle extends React.Component {
    static propTypes = {
        canvas: PropTypes.object,
        top: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
        radius: PropTypes.number.isRequired,
        fill: PropTypes.string.isRequired,
    }

    static defaultProps = {
        top: 0,
        left: 0,
        radius: 5,
        fill: 'black',
        opacity: 0
    }

    componentDidMount() {
        const circle = new fabric.Circle(this.props)
        const { canvas } = this.props
        circle.animate('opacity', 1, {
            duration: 500,
            onChange: canvas.renderAll.bind(canvas),
            easing: fabric.util.ease['easeInQuad']
        });
        canvas.add(circle);
    }

    render() {
        return null
    }
}

export default Circle
