import React from 'react'
import PropTypes from 'prop-types'
import { fabric } from "fabric";

class Text extends React.Component {
    static propTypes = {
        // canvas: PropTypes.object,
        // top: PropTypes.number.isRequired,
        // left: PropTypes.number.isRequired,
        // fill: PropTypes.string.isRequired,
    }

    static defaultProps = {
        // top: 0,
        // left: 0,
        // strokeWidth: 7,
        opacity: 1
    }

    componentDidMount() {
        // this.props.canvas.renderAll();
        const text = new fabric.IText('Edit Here', { ...this.props, underline: true, cornerSize: 6 });
        const { canvas } = this.props
        text.animate('opacity', 1, {
            duration: 500,
            onChange: canvas.renderAll.bind(canvas),
            easing: fabric.util.ease['easeInQuad']
        });
        canvas.add(text);
    }

    render() {
        return null
    }
}

export default Text
