import { useState, useEffect } from 'react'
import { Tabs, Input, Button } from 'antd'
import TextAttribute from '../attributes/text'
import Circle from '../attributes/circle'
import Rectangle from '../attributes/rectangle'
import Line from '../attributes/line'
import Image from '../attributes/image'
import AreaAttribute from '../attributes/area'
const { TabPane } = Tabs;

const AnnotationContent = (props) => {
    const [activeKey, setActiveKey] = useState('1')

    useEffect(() => {
        setActiveKey('1')
    }, [props.viewControl])

    return (
        <Tabs activeKey={activeKey} onChange={key => setActiveKey(key)} size="small" >
            <TabPane tab="Content" key="1">
                <div className='details'>
                    {
                        // Comment
                        (props.activeEditCommentId === props.data.uuid && props.data.type === 'comment') ?
                            <div>
                                <Input.TextArea rows={5} value={props.activeEditCommentContent} onChange={(e) => {
                                    props.setActiveEditCommentContent(e.target.value)
                                }} />
                                <Button type="primary" size='small' onClick={() => {
                                    props.handleUpdateComment(props.data.uuid)
                                }}>Save</Button>
                            </div> : <p className='content'>{props.data.comment || props.data.text}</p>
                    }
                    {
                        (props.activeEditCommentId === props.data.uuid && props.data.type === 'textNormal') &&
                        <div>
                            <Input.TextArea rows={5} value={props.activeEditCommentContent} onChange={(e) => {
                                props.setActiveEditCommentContent(e.target.value)
                            }} />
                            <Button type="primary" size='small' onClick={() => props.handleUpdateComment(props.data.uuid)}>Save</Button>
                        </div>
                    }
                </div>
            </TabPane>
            <TabPane tab="Attributes" key="2">
                <div className='attributes'>
                    {
                        (() => {
                            switch (props.data.type) {
                                case 'textNormal':
                                    return <TextAttribute onChangeColor={(e) => props.handleOnChangeColorText(e, props.data.uuid)} color={props.data.fill} onChangeFontSize={(e) => props.handleOnChangeFontSizeText(e, props.data.uuid)} fontSizeValue={props.data.fontSize} />
                                case 'textUnderline':
                                    return <TextAttribute onChangeColor={(e) => props.handleOnChangeColorText(e, props.data.uuid)} color={props.data.fill} onChangeFontSize={(e) => props.handleOnChangeFontSizeText(e, props.data.uuid)} fontSizeValue={props.data.fontSize} />
                                case 'textBold':
                                    return <TextAttribute onChangeColor={(e) => props.handleOnChangeColorText(e, props.data.uuid)} color={props.data.fill} onChangeFontSize={(e) => props.handleOnChangeFontSizeText(e, props.data.uuid)} fontSizeValue={props.data.fontSize} />
                                case 'line':
                                    return <Line onChangeColor={(e) => props.handleOnChangeAttribute(props.data.uuid, 'stroke', e)} color={props.data.stroke} onChangeHeight={(e) => props.handleOnChangeAttribute(props.data.uuid, 'scaleY', e)} onChangeWidth={(e) => props.handleOnChangeAttribute(props.data.uuid, 'scaleX', e)} height={props.data.scaleY} width={props.data.scaleX} />
                                case 'rect':
                                    return <Rectangle onChangeColor={(e) => props.handleOnChangeAttribute(props.data.uuid, 'stroke', e)} color={props.data.stroke} onChangeHeight={(e) => props.handleOnChangeAttribute(props.data.uuid, 'scaleY', e)} onChangeWidth={(e) => props.handleOnChangeAttribute(props.data.uuid, 'scaleX', e)} height={props.data.scaleY} width={props.data.scaleX} />
                                case 'circle':
                                    return <Circle onChangeColor={(e) => props.handleOnChangeAttribute(props.data.uuid, 'stroke', e)} color={props.data.stroke} onChangeHeight={(e) => props.handleOnChangeAttribute(props.data.uuid, 'scaleY', e)} onChangeWidth={(e) => props.handleOnChangeAttribute(props.data.uuid, 'scaleX', e)} height={props.data.scaleY} width={props.data.scaleX} />
                                case 'image':
                                    return <Image onChangeHeight={(e) => props.handleOnChangeAttribute(props.data.uuid, 'scaleY', e)} onChangeWidth={(e) => props.handleOnChangeAttribute(props.data.uuid, 'scaleX', e)} height={props.data.scaleY} width={props.data.scaleX} />
                                case 'signature':
                                    return <Image onChangeHeight={(e) => props.handleOnChangeAttribute(props.data.uuid, 'scaleY', e)} onChangeWidth={(e) => props.handleOnChangeAttribute(props.data.uuid, 'scaleX', e)} height={props.data.scaleY} width={props.data.scaleX} />
                                default:
                                    return (<div className='no-attributes'><span>No Attributes Available</span></div>)
                                    break;
                            }
                        })()
                    }
                </div>
            </TabPane>
        </Tabs>
    )
}

export default AnnotationContent