import React, { useState } from 'react'
import './index.scss'
import { connect } from 'react-redux'
import { Button, Input } from 'antd'
import { v4 as uuidv4 } from 'uuid';
import { updateAnnotationsList } from '../../actions'

const ReplyComponent = (props) => {
    const [message, setMessage] = useState('')

    function handleSubmit() {
        if (message.length <= 0) return
        const index = props.annotationsList.findIndex(annot => annot.id === props.annotId)
        if (index !== - 1) {
            props.annotationsList[index].reply.push({
                createAt: new Date(),
                message: message,
                uuid: uuidv4()
            })
            props.onUpdateAnnotationsList([...props.annotationsList])
            localStorage.setItem(`${props.audioUrl}/annotations`, JSON.stringify(props.annotationsList));
        }
        setMessage('')
    }

    return (
        <div className='reply-content'>
            <Input size='small' placeholder='Reply here...' value={message} onChange={(e) => setMessage(e.target.value)} onPressEnter={() => handleSubmit()} />
            <Button type="primary" size='small' onClick={() => handleSubmit()}>Reply</Button>
        </div>
    )
}


function mapStateToProps(state) {
    return {
        annotationsList: state.client.audio.annotationsList,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onUpdateAnnotationsList: (annotations) => dispatch(updateAnnotationsList(annotations))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReplyComponent)
