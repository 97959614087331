import React, { useState, useReducer, useEffect } from 'react'
import { Button, Input } from 'antd'
import '../index.scss'

let globalCount = 0
let lastValue = ''
let searchPosition = 0
const SearchComponent = (props) => {
    const [searchText, setSearchText] = useState('')
    const [foundCount, setFoundCount] = useState(0)
    const [showSearch, setShowSearch] = useState(props.showSearch || true)
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    String.prototype.replaceBetween = function (start, end, what) {
        return this.substring(0, start) + what + this.substring(end);
    };

    function removeDuplicates(array) {
        const seenIDs = {};
        for (const item of array) {
            if (seenIDs[item.style.cssText]) item.parentNode.removeChild(item);
            else seenIDs[item.style.cssText] = true;
        }
    }

    function clearFound(div) {
        div.innerHTML = div.innerHTML.replaceAll(`<found>`, '')
        div.innerHTML = div.innerHTML.replaceAll(`</found>`, '')
        div.innerHTML = div.innerHTML.replaceAll(`<foundactive>`, '')
        div.innerHTML = div.innerHTML.replaceAll(`</foundactive>`, '')
    }

    function onSearch(value, btnType = 'increment') {
        if (value.length < 3) return
        value = value.toLowerCase()
        globalCount = 0
        if (btnType === 'increment' || btnType === 'enter') searchPosition = searchPosition + 1
        else searchPosition = searchPosition - 1

        const pos = searchPosition

        const viewer = document.getElementById(`${props.id}-viewer`)
        const pages = viewer.querySelectorAll('.page')
        pages.forEach((page) => {
            const textLayer = page.querySelector('.textLayer')
            //remove duplicate dom
            removeDuplicates(textLayer.querySelectorAll('div'))
            textLayer.querySelectorAll('div').forEach((div) => {
                clearFound(div)
                if (div.innerHTML.toLowerCase().includes(value)) {
                    var regex = new RegExp(value, 'g');
                    globalCount = globalCount + div.innerHTML.toLowerCase().match(regex).length
                    div.innerHTML = findPosition(div.innerHTML, value, 0, globalCount, pos)
                }
            });
        });
        //btnType === 'decrement' ? pos - 1 : (btnType === 'increment' || btnType === 'enter') && globalCount > 0 ? pos : pos - 1

        if (value === lastValue) { }
        else {
            lastValue = value
            searchPosition = 0
        }

        var elmnt = viewer.querySelector('foundactive')
        if (elmnt) {
            elmnt.scrollIntoView(true);
            window.scrollBy(0, -180);
        }

        setFoundCount(globalCount)
        forceUpdate()
    }

    function findPosition(text, search, index, globalCount, searchPosition) {
        let array = []
        let textLength = 0
        text.toLowerCase().split(search).map(item => {
            textLength = textLength + item.length + search.length
            array.push({ startPosition: textLength - search.length, endPosition: textLength })
        })
        array = array.slice(0, -1);
        if (array[index]) {
            const startPos = array[index].startPosition
            const endPos = array[index].endPosition
            if ((globalCount - array.length + index) === (searchPosition - 1)) text = text.replaceBetween(startPos, endPos, `<foundactive>${search}</foundactive>`)
            else text = text.replaceBetween(startPos, endPos, `<found>${search}</found>`)
            return findPosition(text, search, index + 1, globalCount, searchPosition)
        } else {
            return text
        }

    }

    function handleChangeSearchValue(e) {
        if (e.target.value.length === 0) {
            setFoundCount(0)
            searchPosition = 0
            const viewer = document.getElementById(`${props.id}-viewer`)
            const pages = viewer.querySelectorAll('.page')
            pages.forEach((page) => {
                const textLayer = page.querySelector('.textLayer')
                //remove duplicate dom
                textLayer.querySelectorAll('div').forEach((div) => {
                    clearFound(div)
                });
            });
        }
        setSearchText(e.target.value)
    }

    function handlePressEnter(e) {
        if (e.target.value.length <= 1) return
        else if ((searchPosition === foundCount) && foundCount !== 0 && searchText === e.target.value) return
        onSearch(e.target.value, 'enter')
        if (searchPosition === 0) onSearch(e.target.value, 'increment')
    }

    useEffect(() => {
        window.addEventListener("keydown", function (e) {
            if (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)) {
                e.preventDefault();
                setShowSearch(true)
                if (props.isPanelActive) document.getElementById(`${props.isPanelActive}-search`).focus()
                else document.getElementById(`${props.id}-search`).focus()
            }

            if (e.keyCode === 27 && !showSearch) {
                setFoundCount(0)
                setSearchText('')
                setShowSearch(false)
                searchPosition = 0
                const viewer = document.getElementById(`${props.id}-viewer`)
                const pages = viewer.querySelectorAll('.page')
                pages.forEach((page) => {
                    const textLayer = page.querySelector('.textLayer')
                    //remove duplicate dom
                    textLayer.querySelectorAll('div').forEach((div) => {
                        clearFound(div)
                    });
                });
            }
        })
    }, [])

    return (
        showSearch &&
        <div className='pdf-search-container'>
            {
                foundCount > 0 &&
                <label className='search-count' > {(Math.max(1, searchPosition))}/{Math.max(0, foundCount)}</label>
            }
            <Input id={`${props.id}-search`} size='medium' value={searchText} onChange={(e) => handleChangeSearchValue(e)} onPressEnter={(e) => handlePressEnter(e)} placeholder="Search" style={{ width: 140 }} />
            <div className='btn-group-flex'>
                <Button className='prev' disabled={searchPosition <= 1} size='medium' onClick={() => onSearch(searchText, 'decrement')}>{`<`}</Button>
                <Button className='next' disabled={((searchPosition === foundCount) && foundCount !== 0) || foundCount === 0} size='medium' onClick={() => onSearch(searchText, 'increment')}>{`>`}</Button>
            </div>
        </div>
    )
}

export default SearchComponent