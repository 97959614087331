import React from 'react'
import { Layout, Row, Col } from 'antd'
import Panel from './panel';
import sample1 from '../../../../../assets/pdf/sample.pdf'
import sample2 from '../../../../../assets/pdf/sample3.pdf'

import './index.scss'

const { Header } = Layout

const Compare = (props) => {
    return (
        <Layout className='compare-container'>
            <Header className='header'>
                <h1>Compare Versions</h1>
            </Header>
            <Row>
                <Col md={12} lg={12} xs={12} sm={12} >
                    <Panel
                        id='pdf-container-compare-1'
                        pdfFile={sample1}
                    />
                </Col>
                <Col md={12} lg={12} xs={12} sm={12} >
                    <Panel
                        id='pdf-container-compare-2'
                        pdfFile={sample2}
                    />
                </Col>
            </Row>
        </Layout>
    )
}

export default Compare