import React, { useState } from 'react'
import './index.scss'
import { Icon } from "office-ui-fabric-react";
import { Select, Button } from 'antd'
import Scale from './scale'
import SlidingPane from "react-sliding-pane";
import Menu from "./menu"
import "react-sliding-pane/dist/react-sliding-pane.css";
import AnnotationView from '../annotationView/annotationView'

const ToolbarMobile = (props) => {
    const [menuShow, setMenuShow] = useState(false)
    const [annotShow, setAnnotShow] = useState(false)
    console.log("PROPS mobile", props)
    return (
        <div>
            <div className='menu-panel-mobile'>
                <SlidingPane
                    // closeIcon={<div>Some div containing custom close icon.</div>}
                    isOpen={menuShow}
                    title="Menu Panel"
                    from="left"
                    width="300px"
                    onRequestClose={() => setMenuShow(false)}
                >
                    <Menu
                        pdfFile={props.pdfFile}
                        textRecognition={props.textRecognition}
                    />
                </SlidingPane>
                <SlidingPane
                    // closeIcon={<div>Some div containing custom close icon.</div>}
                    isOpen={annotShow}
                    title="Annotations"
                    from="right"
                    width="300px"
                    onRequestClose={() => setAnnotShow(false)}
                >
                    <AnnotationView
                        id='mainPdf'
                        pdfFile={props.pdfFile}
                        rightSidebarCollapse={false}
                        viewControl={props.viewControl}
                    />
                </SlidingPane>
            </div>

            <div className='mobile-toolbar'>
                <div className='flex-row'>
                    <Button className='custom-icon-btn' onClick={(e) => setMenuShow(!menuShow)}>
                        <Icon iconName="SidePanel" styles={IconStyles} />
                    </Button>
                    <div className='icon-divider' />
                    <Scale key={props.scale} value={props.scale} onChange={(value) => props.setScale(value)} />
                </div>

                <div className='flex-row'>
                    <Select style={{ width: 100 }} defaultValue={props.viewControl} onChange={(value) => props.setViewControl(value)}>
                        <Select.Option value='view'>View</Select.Option>
                        <Select.Option value='annotate'>Annotate</Select.Option>
                    </Select>
                    <Button className='custom-icon-btn' onClick={(e) => setAnnotShow(!menuShow)}>
                        <Icon iconName="CollapseMenu" styles={IconStyles} />
                    </Button>
                </div>
            </div>
            <div className='mobile-annot-toolbar' style={{ display: props.viewControl === 'annotate' ? 'flex' : 'none' }}>
                <div className='flex-row'>
                    <Button className='custom-icon-btn' onClick={(e) => props.handleToolbar('draw', true)}>
                        <Icon iconName="PenWorkspace" alt='drawing' styles={IconStyles} />
                    </Button>
                    <div className='icon-divider' />
                    <Button className='custom-icon-btn' onClick={(e) => props.handleToolbar('strikeout', true)}>
                        <Icon iconName="Strikethrough" alt='Strikethrough' styles={IconStyles} />
                    </Button>
                    <div className='icon-divider' />
                    <Button className='custom-icon-btn' onClick={(e) => props.handleToolbar('point')}>
                        <Icon iconName="Comment" alt='comment' styles={IconStyles} />
                    </Button>
                    <div className='icon-divider' />
                    <Button className='custom-icon-btn' onClick={(e) => props.handleToolbar('highlight', true)}>
                        <Icon iconName="Highlight" alt='Highlight' styles={IconStyles} />
                    </Button>
                    <div className='icon-divider' />
                    <Button className='custom-icon-btn' onClick={(e) => props.handleToolbar('text')}>
                        <Icon iconName="InsertTextBox" alt='textarea' styles={IconStyles} />
                    </Button>
                </div>
                <div className='flex-row'>
                    <Button className='custom-icon-btn' onClick={(e) => props.handleRotateCCWClick()}>
                        <Icon iconName="Rotate90CounterClockwise" alt='rotateCCW' styles={IconStyles} />
                    </Button>
                    <Button className='custom-icon-btn' onClick={(e) => props.handleRotateCWClick()}>
                        <Icon iconName="Rotate90Clockwise" alt='rotateCW' styles={IconStyles} />
                    </Button>
                    <Button className='custom-icon-btn' onClick={(e) => props.handleClearClick()}>
                        <Icon iconName="EraseTool" alt='erase' styles={IconStyles} />
                    </Button>
                </div>
            </div>
        </div>

    )
}

export default ToolbarMobile

const IconStyles = {
    root: {
        color: '#868e96',
        fontSize: '20px',
        fontWeight: 300,
        margin: '0 4px'
    }
}