import React, { Component } from 'react'
import { Form, Icon, Input, Button, Checkbox, Layout, Spin } from 'antd'
import { connect } from 'react-redux'
import { submitLogin } from '../actions'
// import logo from '../../../../assets/logo/logo.png'
import './index.scss'

class Login extends Component {
  handleSubmit(e) {
    this.props.onSubmitLogin(e)
  }

  render() {
    const { loggingIn, loginRejected } = this.props
    return (
      <Layout>
        <div className='login-container'>
          <div className='login-form'>
            <div className='login-header'>
              <div className='logo'>
                {/* <img src={logo} /> */}
                <h1>Simple PDF</h1>
              </div>
              <span>Portal</span>
            </div>
            {loginRejected &&
              <div className='login-error-message'>
                <span>Login Failed: Invalid Credentials</span>
              </div>}
            <Form
              name='basic'
              initialValues={{ remember: true }}
              onFinish={(e) => this.handleSubmit(e)}
            // onFinishFailed={onFinishFailed}
            >
              <Form.Item
                name='email'
                rules={[{ required: true, message: 'Please input your email!' }]}
              >
                <Input placeholder='Email' />
              </Form.Item>

              <Form.Item
                name='password'
                placeholder='Password'
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <Input.Password placeholder='Password' />
              </Form.Item>

              <Form.Item>
                <Button type='primary' style={{ width: '100%' }} loading={loggingIn} htmlType='submit'>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Layout>
    )
  }
}

function mapStateToProps(state) {
  return {
    // loginRejected: state.adminLogin.loginRejected,
    // loggingIn: state.adminLogin.loggingIn
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitLogin: (data) => dispatch(submitLogin(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
