import React from 'react'
import { Modal, Progress } from 'antd'
import './index.scss'
import Timeframe from './timeframe'
import { jsPDF } from "jspdf";
import * as htmlToImage from 'html-to-image';

class TimelineDownload extends React.Component {
    constructor() {
        super()
        this.state = {
            renderImg: [],
            modalShow: false,
            frameCount: 0,
            frameTotal: 0,
            percentage: 0,
            url: undefined

        }
        this.getVideoImage = this.getVideoImage.bind(this)
        this.showImageAt = this.showImageAt.bind(this)
        this.download = this.download.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.assetUrl !== this.props.assetUrl) {
            console.log("YEAH ITS ME")
            this.setState({ url: this.props.assetUrl })
            this.showImageAt(0, this.props.assetUrl)
        }
    }

    download() {
        this.setState({ modalShow: true })
        this.generatePDF()
    }

    async generatePDF() {
        var doc = new jsPDF('l', 'pt', [940, 660]);
        const frameList = document.getElementById('timeline-download').childNodes
        this.setState({ frameTotal: frameList.length })
        for (let index = 0; index < frameList.length; index++) {
            const percentage = ((this.state.frameCount / this.state.frameTotal) * 100)
            if (percentage > this.state.percentage) {
                this.setState({ percentage: percentage })
            }
            this.setState({ frameCount: index + 1 })
            const element = frameList[index];
            const newImage = await htmlToImage.toPng(element)
            const newSecs = new Date(+element.firstChild.dataset.seconds * 1000).toISOString().substr(11, 8)
            doc.addPage();
            doc.addImage(newImage, 'PNG', 0, 0, 940, 660);
            doc.text(newSecs, 0, 15)
            console.log("TRIGGER")
        }
        doc.save('video');
        this.setState({ modalShow: false, percentage: 0, frameCount: 0, frameTotal: 0, })
    }

    getVideoImage(path, secs, callback) {
        var me = this, video = document.createElement('video');
        video.onloadedmetadata = function () {
            if ('function' === typeof secs) {
                secs = secs(this.duration);
            }
            this.currentTime = Math.min(Math.max(0, (secs < 0 ? this.duration : 0) + secs), this.duration);
        };
        video.onseeked = function (e) {
            var img = new Image();
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            canvas.height = video.videoHeight;
            canvas.width = video.videoWidth;
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            img.crossOrigin = 'anonymous'
            img.src = canvas && canvas.toDataURL();
            img.dataset.seconds = secs
            img.onclick = () => me.props.handleUpdateSeekChange(secs);
            callback.call(me, img, this.currentTime, e);
        };
        video.onerror = function (e) {
            callback.call(me, undefined, undefined, e);
        };
        video.setAttribute('crossorigin', 'anonymous');
        video.src = path;
    }

    showImageAt(secs, url) {
        var duration;
        this.getVideoImage(
            url,
            function (totalTime) {
                duration = totalTime;
                return secs;
            },
            function (img, secs, event) {
                try {
                    if (event.type == 'seeked') {
                        this.state.renderImg.push(<Timeframe src={img.src} seconds={img.dataset.seconds} data={this.props.data} />)
                        this.setState({ renderImg: this.state.renderImg })
                        if (duration >= ++secs) {
                            this.showImageAt(secs, url);
                        };
                    }
                } catch (error) {
                    console.log("ERROR timelineDownload.js", error)
                }

            }
        );
    }

    componentDidMount() {
        const self = this
        const targetNode = document.getElementById('timeline-download');

        const config = { attributes: true, childList: true, subtree: true };
        const callback = function (mutationsList, observer) {
            for (const mutation of mutationsList) {
                const frameList = document.getElementById('timeline-download').childNodes
                self.setState({ frameTotal: frameList.length })
            }
        };
        const observer = new MutationObserver(callback);
        observer.observe(targetNode, config);
    }


    render() {
        return (
            <div key={this.props.data && this.props.data.toString()}>
                <ol id="timeline-download">
                    {this.state.renderImg}
                </ol>
                <Modal title="Download" visible={this.state.modalShow} footer={null}>
                    {/* Status {this.state.frameCount} / {this.state.frameTotal} */}
                    <p>Please wait while we process your download file.</p>
                    <Progress percent={this.state.percentage.toFixed(0)} status="active" />
                </Modal>
            </div>
        )
    }
}

export default TimelineDownload
