import React from 'react'
import PropTypes from 'prop-types'
import { fabric } from 'fabric'

class Circle extends React.Component {
    static propTypes = {
        canvas: PropTypes.object,
        top: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
        radius: PropTypes.number.isRequired,
        fill: PropTypes.string.isRequired,
    }

    static defaultProps = {
        top: 0,
        left: 0,
        radius: 5,
        opacity: 0,
        scaleX: 4.44,
        scaleY: 4.44,
        fill: 'transparent',
        stroke: 'red'
    }

    componentDidMount() {
        const circle = new fabric.Circle({ ...this.props, cornerSize: 6 })
        const { canvas } = this.props
        circle.animate('opacity', 1, {
            duration: 500,
            onChange: canvas.renderAll.bind(canvas),
            easing: fabric.util.ease['easeInQuad']
        });
        canvas.add(circle);
    }

    render() {
        return null
    }
}

export default Circle
