import React, { Component } from 'react'
import { Route, Switch, Redirect, withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import * as _ from 'lodash'
import './App.scss'
import 'antd/dist/antd.css'
// import logo from '../../assets/logo/logo.png'
import Footer from './shared/Footer'
import { NotFound } from './notFound'
import Login from './login/component'
import Pdf from './pdf/components/index'
import PdfCompare from './pdf/components/compare'
import Video from './video/components/index'
import Audio from './audio/components/index'
import AssetCompare from './assetCompare/components/index'
import { Layout, Modal } from 'antd'
import Sider from './shared/Sider'
import {
  FilePdfOutlined,
  ShrinkOutlined,
  ExclamationCircleOutlined,
  VideoCameraOutlined,
  SettingOutlined,
  SoundOutlined
} from '@ant-design/icons'
import { initializeIcons } from "office-ui-fabric-react";
import MediaQuery from 'react-responsive'

const { Content, Header } = Layout
moment.updateLocale(moment.locale(), { invalidDate: 'N/A' })

const paths = [
  {
    default: true,
    exact: true,
    slug: 'PDF',
    header: 'PDF',
    route: '/',
    component: Pdf,
    icon: < FilePdfOutlined />
  },
  // {
  //   default: true,
  //   exact: true,
  //   slug: 'PDF-Compare',
  //   header: 'PDF-Compare',
  //   route: '/pdf-compare',
  //   component: PdfCompare,
  //   icon: < FilePdfOutlined />
  // },
  // {
  //   default: true,
  //   exact: true,
  //   slug: 'Video',
  //   header: 'Video',
  //   route: '/video',
  //   component: Video,
  //   icon: < VideoCameraOutlined />
  // },
  // {
  //   default: true,
  //   exact: true,
  //   slug: 'Audio',
  //   header: 'Audio',
  //   route: '/audio',
  //   component: Audio,
  //   icon: < SoundOutlined />
  // },
  // {
  //   default: true,
  //   exact: true,
  //   slug: 'Compare',
  //   header: 'Compare',
  //   route: '/asset-compare',
  //   component: AssetCompare,
  //   icon: < ShrinkOutlined />
  // },
  // {
  //   default: true,
  //   exact: true,
  //   slug: 'Settings',
  //   header: 'Settings',
  //   route: '/settings',
  //   component: NotFound,
  //   icon: < SettingOutlined />
  // }
]


class App extends Component {
  constructor() {
    super()
    this.state = {
      isLoggedIn: true,
      siderCollapse: true,
      activeTab: 0
    }
  }

  componentWillMount() {
    window.document.title = '[CLIENT] SimplePDF'
    const currentPathIndex = paths.findIndex(data => data.route === this.props.location.pathname)
    this.setState({ activeTab: currentPathIndex })

    initializeIcons()

    // if (isLocalStorageEmpty()) this.setState({ isLoggedIn: false })
    // else this.setState({ isLoggedIn: true })
  }

  // componentWillReceiveProps(nextProps) {
  //   if (this.props.loggedIn !== nextProps.loggedIn && nextProps.loggedIn) {
  //     this.setState({ isLoggedIn: true })
  //     window.location.reload()
  //   }
  // }

  toggle = () => {
    this.setState({
      siderCollapse: !this.state.siderCollapse,
    });
  };

  logout() {
    const self = this
    Modal.confirm({
      title: 'Do you want to logout?',
      icon: <ExclamationCircleOutlined />,
      content: 'You will redirected to login page',
      onOk() {
        localStorage.removeItem('CLIENT_SESSION_TOKEN')
        self.props.history.push('/')
        window.location.reload()
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  render() {
    switch (this.state.isLoggedIn) {
      case false:
        return (
          <Layout className='app-layout'>
            <Login />
          </Layout>
        )
      case true:
        return (
          <Layout>
            {/* <Header className="site-layout-background" style={{ padding: 0, backgroundColor: '#5f41d2' }}>
                {React.createElement(this.state.siderCollapse ? MenuUnfoldOutlined : MenuFoldOutlined, {
                  className: 'trigger',
                  onClick: this.toggle,
                  style: { color: 'white', fontSize: 20, padding: '10px' }
                })}
            </Header> */}
            <MediaQuery maxWidth={991}>
              <Header className='mobile-header'>
                <h1>simple.</h1>
              </Header>
            </MediaQuery>
            <Layout className="site-layout">
              <MediaQuery minWidth={991}>
                <Sider toggle={this.toggle} siderCollapse={this.state.siderCollapse} activeTab={this.state.activeTab} paths={paths} style={{ backgroundColor: '#1B0B4E' }} />
              </MediaQuery>
              <Content
                className="site-layout-background"
                style={{
                  minHeight: 280,
                }}
              >
                <Switch>
                  {/* // this will scroll to top when switch changes. */}
                  {window.scrollTo(0, 0)}
                  {_.map(paths, (path) => (
                    <Route
                      exact={path.exact}
                      path={path.route}
                      component={path.component}
                      key={path.route}
                    />
                  ))}
                  <Route component={NotFound} />
                  <Redirect to='/404' />
                </Switch>
                {/* <div style={{ paddingBottom: '100vh' }} /> */}
              </Content>
            </Layout>
          </Layout >
        )
      default:
        break
    }
  }
}

function mapStateToProps(state) {
  return {
    // loggedIn: state.clientLogin.loggedIn
  }
}

export default withRouter(connect(mapStateToProps, {})(App))

