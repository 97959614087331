import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Row, Col } from 'antd'
import { connect } from 'react-redux'
import { updateAnnotationsList, updateGlobalSurfer } from '../actions'
import { PlayCircleOutlined, PauseCircleOutlined } from '@ant-design/icons'
import './index.scss'

let globalSurfer;
const Audio = (props) => {
    let waveSurf;
    const [audioUrl, setAudioUrl] = useState('')
    const [play, setPlay] = useState(false)

    useEffect(() => {
        const annotations = JSON.parse(localStorage.getItem(`${props.audioUrl}/annotations`)) || [];
        props.onUpdateAnnotationsList([...annotations])
    }, [])

    useLayoutEffect(() => {
        if (props.audioUrl.length === 0) return
        setAudioUrl(props.audioUrl)

        document.querySelector('#waveform').innerHTML = ''
        const annotations = JSON.parse(localStorage.getItem(`${props.audioUrl}/annotations`)) || [];
        let WaveSurfer = window.WaveSurfer
        var wavesurfer = WaveSurfer.create({
            container: document.querySelector('#waveform'),
            waveColor: '#28134B',
            progressColor: '#3B8686',
            backend: 'MediaElement',
            plugins: [
                WaveSurfer.regions.create({
                    regionsMinLength: 1,
                    color: props.annotColor,
                    regions: annotations,
                    dragSelection: {
                        slop: 5
                    }
                })
            ]
        });

        wavesurfer.load(props.audioUrl);
        wavesurfer.on('audioprocess', updateTimer);
        wavesurfer.on('seek', updateTimer)

        waveSurf = wavesurfer
        globalSurfer = wavesurfer
        props.onUpdateGlobalSurfer(globalSurfer)
        localStorage.setItem(`/audioUrl`, props.audioUrl)
    }, [props.audioUrl])

    useEffect(() => {
        props.annotColor && localStorage.setItem(`${audioUrl}/annotColor`, hexToRgbA(props.annotColor))
    }, [props.annotColor])

    function hexToRgbA(hex) {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',.50)';
        }
        throw new Error('Bad Hex');
    }

    useEffect(() => {

        waveSurf && waveSurf.on('finish', function () {
            setPlay(false)
        });

        waveSurf && waveSurf.un('region-created')
        waveSurf && waveSurf.on('region-created', (region, event) => {
            const url = localStorage.getItem(`/audioUrl`)
            region.color = localStorage.getItem(`${url}/annotColor`)
        });

        waveSurf && waveSurf.un('region-update-end')
        waveSurf && waveSurf.on('region-update-end', (region, event) => {
            const url = localStorage.getItem(`/audioUrl`)
            let annotations = JSON.parse(localStorage.getItem(`${url}/annotations`)) || []

            const { id, start, end, loop, color, minLength } = region
            const findIndex = annotations.findIndex(annot => annot.id === region.id)
            if (findIndex === -1) {
                // region.color = localStorage.getItem(`${url}/annotColor`)
                annotations.push({
                    id: id,
                    name: id,
                    start: start,
                    end: end,
                    loop: loop,
                    color: localStorage.getItem(`${url}/annotColor`),
                    minLength: minLength,
                    createdAt: new Date(),
                    authorName: localStorage.getItem(`/author/name`),
                    authorAvatarURL: localStorage.getItem(`/author/url`),
                    reply: []
                })
            } else {
                // annotations[findIndex].name = name
                annotations[findIndex].start = start
                annotations[findIndex].end = end
                annotations[findIndex].loop = loop
                // annotations[findIndex].color = color
                annotations[findIndex].minLength = minLength
            }

            props.onUpdateAnnotationsList([...annotations])
            localStorage.setItem(`${url}/annotations`, JSON.stringify(annotations));
        })
    }, [props.annotationsList, props.audioUrl, props.annotColor])

    function updateTimer() {
        var formattedTime = secondsToTimestamp(waveSurf.getCurrentTime());
        document.getElementById('time-current').innerText = formattedTime;
    }
    
    function secondsToTimestamp(seconds) {
        seconds = Math.floor(seconds);
        var h = Math.floor(seconds / 3600);
        var m = Math.floor((seconds - (h * 3600)) / 60);
        var s = seconds - (h * 3600) - (m * 60);
        
        h = h < 10 ? '0' + h : h;
        m = m < 10 ? '0' + m : m;
        s = s < 10 ? '0' + s : s;
        return h + ':' + m + ':' + s;
    }

    function handlePlay() {
        setPlay(!play)
        if (play) globalSurfer.playPause()
        else globalSurfer.play()
    }

    console.log("globalSurfer", globalSurfer && globalSurfer)
    return (
        <div className='audio-content'>
            <Row>
                <Col sm={24} md={24} lg={24} xs={24}>
                    <div id="waveform"></div>
                </Col>
            </Row>
            <Row>
                <Col sm={24} md={24} lg={24} xs={24} className='action-content'>
                    <div></div>
                    <div className='play' onClick={() => handlePlay()}>
                        {play ? <PauseCircleOutlined style={{ fontSize: 30 }} /> : <PlayCircleOutlined style={{ fontSize: 30 }} />}
                    </div>
                    <div id="time-current">00:00:00</div>
                </Col>
            </Row>
        </div>
    )
}


function mapStateToProps(state) {
    return {
        annotationsList: state.client.audio.annotationsList,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onUpdateAnnotationsList: (annotations) => dispatch(updateAnnotationsList(annotations)),
        onUpdateGlobalSurfer: (obj) => dispatch(updateGlobalSurfer(obj))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Audio)