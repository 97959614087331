import store from '../../../../store'

export const UPDATE_LOCALSTORAGE = 'UPDATE_LOCALSTORAGE'
export const UPDATE_ANNOTATIONS = 'UPDATE_ANNOTATIONS'

export const updateLocalStorage = (pdfFile) => {
  const annotations = localStorage.getItem(`${pdfFile}/annotations`)
  const anotParsed = JSON.parse(annotations)

  return dispatch => {
    dispatch({
      type: UPDATE_LOCALSTORAGE,
      payload: anotParsed
    })
  }
}

export const updateAnnotationsList = (annotations) => {
  return store.dispatch({
    type: UPDATE_ANNOTATIONS,
    payload: annotations
  })
}

