
import React, { useState, useEffect } from 'react';
import { Button, Input } from 'antd'
import './index.scss'
import { Icon } from "office-ui-fabric-react";

const Scale = (props) => {
    console.log("SCALE", props)
    const [value, setValue] = useState(props.value)
    const maxValue = 150
    const minValue = 0

    function increase() {
        if (value >= maxValue) return
        const currValue = +value + 5
        setValue(currValue)
        props.onChange(currValue)
    }

    function decrease() {
        if (value <= minValue) return
        const currValue = +value - 5
        setValue(currValue)
        props.onChange(currValue)
    }

    function handleChangeValue(value) {
        const reg = /^-?\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
            if (+value > maxValue) {
                props.onChange(maxValue)
                setValue(maxValue)
            } else if (+value < minValue) {
                props.onChange(minValue)
                setValue(minValue)
            } else {
                props.onChange(value || 100)
                setValue(value)
            }
        }
    }

    return (
        <div className='flex-row'>
            <Button className='custom-icon-btn' onClick={() => decrease()} >
                <Icon iconName="SkypeMinus" styles={IconStyles} />
            </Button>
            <Button className='custom-icon-btn' onClick={() => increase()}>
                <Icon iconName="CirclePlus" styles={IconStyles} />
            </Button>
        </div>
        // <Input className="scale" style={{ width: 180, margin: 10 }}
        //     maxLength={3}
        //     addonAfter={

        //     }
        //     addonBefore={

        //     }
        //     value={value}
        //     onChange={(e) => setValue(e.target.value)}

        //     onPressEnter={(e) => handleChangeValue(e.target.value)}
        // />

    );
}

export default Scale;

const IconStyles = {
    root: {
        color: '#868e96',
        fontSize: '20px',
        fontWeight: 300,
        margin: '0 4px'
    }
}