import PDFJSAnnotate from '../PDFJSAnnotate';
import appendChild from '../render/appendChild';
import {
  BORDER_COLOR,
  findSVGAtPoint,
  getMetadata,
  scaleDown
} from './utils';

let _enabled = false;
let input;
let _textSize;
let _textColor;
let _rotate;
let _scale

/**
 * Handle document.mouseup event
 *
 * @param {Event} e The DOM event to handle
 */
function handleDocumentMouseup(e) {
  if (input || !findSVGAtPoint(e.clientX, e.clientY)) {
    return;
  }

  input = document.createElement('input');
  input.setAttribute('id', 'pdf-annotate-text-input');
  input.setAttribute('placeholder', 'Enter text (Press ESC)');
  input.style.border = `1px solid ${BORDER_COLOR}`;
  input.style.borderRadius = '3px';
  input.style.position = 'absolute';
  input.style.top = `${e.clientY}px`;
  input.style.left = `${e.clientX}px`;
  input.style.fontSize = `${_textSize}pt`;

  input.addEventListener('blur', handleInputBlur);
  input.addEventListener('keyup', handleInputKeyup);

  document.body.appendChild(input);
  input.focus();
}

/**
 * Handle input.blur event
 */
function handleInputBlur() {
  saveText();
}

/**
 * Handle input.keyup event
 *
 * @param {Event} e The DOM event to handle
 */
function handleInputKeyup(e) {
  if (e.keyCode === 27) {
    closeInput();
  } else if (e.keyCode === 13) {
    saveText();
  }
}

/**
 * Save a text annotation from input
 */
function saveText() {
  if (input.value.trim().length > 0) {
    let clientX = parseInt(input.style.left, 10);
    let clientY = parseInt(input.style.top, 10);
    let svg = findSVGAtPoint(clientX, clientY);
    if (!svg) {
      return;
    }

    let { documentId, pageNumber } = getMetadata(svg);
    let rect = svg.getBoundingClientRect();

    let x;
    let y;
    let width;
    let height;

    switch (_rotate) {
      case 90:
        x = Math.abs(clientX - rect.x)
        y = Math.abs(clientY - rect.y)
        width = input.offsetHeight
        height = input.offsetWidth
        break;

      case 180:
        x = clientX - rect.left
        y = clientY - rect.top
        width = input.offsetWidth
        height = input.offsetHeight
        break;

      case 270:
        x = Math.abs(clientX - rect.x)
        y = Math.abs(clientY - rect.y);
        width = input.offsetHeight
        height = input.offsetWidth
        break;

      case 0:
        x = clientX - rect.left
        y = clientY - rect.top
        width = input.offsetWidth
        height = input.offsetHeight
        break;
    }

    let annotation = Object.assign({
      type: 'textbox',
      size: _textSize,
      color: _textColor,
      content: input.value.trim(),
      rotation: 360 - _rotate

    }, scaleDown(svg, {
      x: x,
      y: y,
      width: width,
      height: height
    })
    );

    PDFJSAnnotate.getStoreAdapter().addAnnotation(documentId, pageNumber, annotation)
      .then((annotation) => {
        appendChild(svg, annotation);
      });
  }

  closeInput();
}

/**
 * Close the input
 */
function closeInput() {
  if (input) {
    input.removeEventListener('blur', handleInputBlur);
    input.removeEventListener('keyup', handleInputKeyup);
    document.body.removeChild(input);
    input = null;
  }
}

/**
 * Set the text attributes
 *
 * @param {Number} textSize The size of the text
 * @param {String} textColor The color of the text
 */
export function setText(textSize = 12, textColor = '000000') {
  _textSize = parseInt(textSize, 10);
  _textColor = textColor;
}


/**
 * Enable text behavior
 */
export function enableText(rotate, scale) {
  _rotate = rotate
  _scale = scale
  if (_enabled) { return; }

  _enabled = true;
  document.addEventListener('mouseup', handleDocumentMouseup);
}


/**
 * Disable text behavior
 */
export function disableText() {
  if (!_enabled) { return; }

  _enabled = false;
  document.removeEventListener('mouseup', handleDocumentMouseup);
}

