import React, { useLayoutEffect, useEffect, useState, useRef, Fragment } from 'react';
import { Row, Col, Button, Radio, Dropdown } from 'antd'

const ColorPicker = (props) => {
    const COLORS = [{ hex: '#000000', name: 'Black' }, { hex: '#EF4437', name: 'Red' }, { hex: '#E71F63', name: 'Pink' }, { hex: '#8F3E97', name: 'Purple' }, { hex: '#65499D', name: 'Deep Purple' }, { hex: '#4554A4', name: 'Indigo' }, { hex: '#2083C5', name: 'Blue' }, { hex: '#35A4DC', name: 'Light Blue' }, { hex: '#09BCD3', name: 'Cyan' }, { hex: '#009688', name: 'Teal' }, { hex: '#43A047', name: 'Green' }, { hex: '#8BC34A', name: 'Light Green' }, { hex: '#FDC010', name: 'Yellow' }, { hex: '#F8971C', name: 'Orange' }, { hex: '#F0592B', name: 'Deep Orange' }, { hex: '#F06291', name: 'Light Pink' }, { hex: 'transparent', name: 'transparent' }];
    const [activeColor, setActiveColor] = useState('')
    const [selectedColor, setSelectedColor] = useState(props.value || '#2083C5')

    function onChangeColor(hex) {
        setSelectedColor(hex)
        props.onChange && props.onChange(hex)
    }

    useEffect(() => {
        props.onChange && props.onChange(selectedColor)
    }, [])

    return (
        <Dropdown overlay={
            <div className='color-picker-container'>
                {
                    COLORS.map((item, index) => {
                        return (
                            <div className='color-picker-items' key={index} style={{ background: item.hex, border: (activeColor === item.hex) && '1px solid black' }} onMouseEnter={() => { setActiveColor(item.hex) }} onClick={() => onChangeColor(item.hex)} />
                        )
                    })
                }
            </div>
        }>
            <div style={{ background: props.value || selectedColor, height: 15, width: 15, margin: 2, borderRadius: 7.5, border: '1px solid gray' }} />
        </Dropdown>
    )
}
export default ColorPicker