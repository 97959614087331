
import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Layout, Modal, Form } from 'antd'
import { connect } from 'react-redux'
import PDFJSAnnotate from '../../../../PDFJSAnnotate'
import './index.scss'
import samplePdf from '../../../../assets/pdf/sample2.pdf'
import Toolkit from './toolkit/'
import PanelView from './panelView/index'
import AnnotationView from './annotationView/annotationView'
import PdfView from './pdfView';
import { AnnotateToolbarv3 } from "./toolbar.js";
import MobileToolbar from "./mobile/toolbar";
import { create } from 'xmlbuilder2';
import { updateAnnotationsList } from '../actions'
import GuestIcon from '../../../../assets/images/guestIcon.png'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import MediaQuery from 'react-responsive'
import { initialText, initialPen } from './helperInit'
import { jsPDF } from "jspdf";
const { UI } = PDFJSAnnotate;
const { Sider, Content } = Layout;

const Pdf = (props) => {
    const [form] = Form.useForm();
    // const pdfFile = 'https://mozilla.github.io/pdf.js/web/compressed.tracemonkey-pldi-09.pdf'
    const pdfFile = samplePdf;
    const [commentModalEnabled, setCommentModalEnabled] = useState(false)
    const [selectedText, setSelectedText] = useState('')
    const [leftSidebarCollapse, setLeftSidebarCollapse] = useState(false)
    const [rightSidebarCollapse, setRightSidebarCollapse] = useState(false)
    const [annotationsData, setAnnotationsData] = useState([])

    // Toolkit initial data
    const [scale, setScale] = useState(120)
    const [viewControl, setViewControl] = useState('annotate')
    const [pdfInView, setpdfInView] = useState('');
    const [textRecognition, setTextRecognition] = useState('')
    const [documentType, setDocumentType] = useState('pdf');
    const [selectedAnnotationToAdd, setSelectedAnnotationToAdd] = useState('')
    const [annotType, setAnnotType] = useState('')
    const [totalPages, setTotalPages] = useState(0)
    const [rotation, setRotation] = useState(undefined)
    const [isLock, setIsLock] = useState(false)

    useEffect(() => {
        var parametersMap = {
            file: ''
        };
        const queryString = window.parent.location.search;
        const queries = queryString.split("?");
        queries.forEach(query => {
            const parameters = query.split("&");
            for (var parameter of parameters) {
                const [key, value] = parameter.split("=");
                parametersMap[key] = value;
            }
        });
        if (parametersMap.file) {
            const getParams = parametersMap.file.split('%3F')[0]
            const getLastSplit = getParams.split('.')
            const getFileType = getLastSplit[getLastSplit.length - 1]

            const imageFileType = ['jpg', 'jpeg', 'jpe', 'jif', 'jfif', 'jfi', 'png', 'gif', 'webp', 'tiff', 'tif', 'bmp', 'svg'];
            if (imageFileType.find(type => getFileType === type)) {
                checkDocument(decodeURIComponent(parametersMap.file), getFileType)
            } else {
                pdfURLToBlob(decodeURIComponent(parametersMap.file), 'pdf')
            }
        } else {
            setpdfInView(samplePdf)
            setRotation(0)
        }
    }, [])

    function checkDocument(assetUrl, assetFileExtension) {
        var img = new Image()
        img.onload = function () {
            const doc = new jsPDF({
                orientation: this.height > this.width ? "p" : "l",
                format: [this.width, this.height],
                unit: 'px',
                hotfixes: ["px_scaling"]
            });
            if (assetFileExtension === 'jpg') assetFileExtension = 'JPEG'
            doc.addImage(img, assetFileExtension, 0, 0,
                this.width, this.height);

            var outBlobURL = doc.output("bloburl");
            var outBlob = doc.output("blob")

            new Promise((resolve, reject) => {
                // if (outBlob.size < 52428800) setTextRecognition(readTextFromURL(assetUrl));

                setpdfInView(outBlobURL);
                setRotation(0)
                return resolve(true)
            })
        }

        img.src = assetUrl;
        img.crossOrigin = "";
    }

    function pdfURLToBlob(assetUrl, assetFileExtension, assetAnnotation) {
        if (assetUrl.length > 0 && assetFileExtension == 'pdf') {
            var xhr = new XMLHttpRequest();
            xhr.open('GET', assetUrl, true);
            xhr.responseType = 'blob';

            xhr.onload = function (e) {
                if (this.status == 200) {
                    // Note: .response instead of .responseText
                    var blob = new Blob([this.response], { type: 'application/pdf' }),
                        url = URL.createObjectURL(blob);

                    new Promise((resolve, reject) => {
                        // if (blob.size < 52428800) setTextRecognition(readTextFromURL(assetUrl));
                        localStorage.setItem(`${url}/annotations`, assetAnnotation ? Array.isArray(assetAnnotation) ? JSON.stringify(assetAnnotation) : assetAnnotation : '[]');
                        props.onUpdateAnnotationsList(Array.isArray(assetAnnotation) ? assetAnnotation : JSON.parse(assetAnnotation || '[]'))
                        const nodes = document.getElementById(viewerId) ? document.getElementById(viewerId).childNodes : []
                        for (let index = 0; index < nodes.length; index++) {
                            const element = nodes[index];
                            element.setAttribute('data-loaded', 'false')
                            element.style = 'display: none;'
                        }
                        console.log("setpdfInView======>", url)

                        setpdfInView(url);
                        setDocumentType(assetFileExtension);

                        resolve(true)
                    }).then(() => {
                        const ls = localStorage.getItem(`${url}/annotations`)
                        const storage = Array.isArray(ls) ? ls : JSON.parse(ls)
                        const found = storage && storage.find(data => data.type === 'rotate') || -1
                        if (found !== -1) {
                            setRotation(found && found.rotate || 0)
                        }
                        else {
                            setRotation(0)
                        }

                        getAnnotationsDataFromStorage(url);
                    })
                }
            };

            xhr.send();
        }
    }

    function checkIfSelectedText() {
        if (window.getSelection().toString() && (viewControl === 'annotate')) {
            setCommentModalEnabled(true)
            setSelectedText(window.getSelection().toString())
        }
    }

    function handleScale() {
        // initialText(pdfInView)
        // initialPen(pdfInView)
    }

    useEffect(() => {
        function updateSize() {

            // remove leader line when creating an actions with these buttons
            const removeElements = (elms) => elms.forEach(el => el.remove());
            removeElements(document.querySelectorAll(".leader-line"));

            // setSize([window.innerWidth, window.innerHeight]);
            if (window.innerWidth >= 1450) {
                setScale(120)
            }
            else if (window.innerWidth >= 767) {
                setScale(100)
            }
            else if (window.innerWidth >= 657) {
                setScale(80)
            }
            else if (window.innerWidth >= 554) {
                setScale(70)
            }
            else if (window.innerWidth >= 490) {
                setScale(60)
            }
            else if (window.innerWidth >= 420) {
                setScale(50)
            }
            else if (window.innerWidth <= 375) {
                setScale(50)
            }

            handleScale()
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(() => {
        const authorName = props.userDetails ? props.userDetails.fullname : 'Guest'
        const authorId = props.userDetails ? props.userDetails.systemuserid : 0
        const authorAvatarURL = props.userDetails ? props.userDetails.entityimage_url : GuestIcon
        localStorage.setItem(`/author/name`, authorName)
        localStorage.setItem(`/author/id`, authorId)
        localStorage.setItem(`/author/url`, authorAvatarURL)
    }, [props.userDetails])

    function getParameters() {
        var parametersMap = {};
        const queryString = window.parent.location.search;
        const queries = queryString.split("?");
        queries.forEach(query => {
            const parameters = query.split("&");
            for (var parameter of parameters) {
                const [key, value] = parameter.split("=");
                parametersMap[key] = value;
            }
        });
        console.log(parametersMap["data"]);
        setpdfInView(decodeURIComponent(parametersMap["data"]));
        setDocumentType(decodeURIComponent(parametersMap["data"]).split('.').pop());
    }

    function getAnnotationsDataFromStorage() {
        const annotData = localStorage.getItem(`${pdfInView}/annotations`)
        props.onUpdateAnnotationsList(JSON.parse(annotData))
    }

    function convertJsonToXfdf(annotations) {

        const annots = create().ele('annots');
        var color = '#FF000';
        const root = create({ version: '1.0', encoding: "UTF-8" })
            .ele('xfdf', { xmlns: 'http://ns.adobe.com/xfdf/', 'xml:space': 'preserve' })
            .ele('fields')
            .up()
            .ele('annots');

        //loop thru annotations

        for (var i = 0; i < annotations.length; i++) {

            // check if annotation has multiple rect

            if (annotations[i].type === 'textbox') {
                root.ele('text', {
                    'subject': 'Comment', 'page': annotations[i].page - 1, 'rect': annotations[i].y + ','
                        + annotations[i].x + ',' + annotations[i].width + ',' + annotations[i].height,
                    'flags': 'print', 'name': annotations[i].uuid, 'title': annotations[i].title ? annotations[i].title : '', 'color': "#" + annotations[i].color,
                    'creationdate': annotations[i].createdAt ? annotations[i].createdAt : ''
                })
                    .ele('contents-richtext')
                    .ele('body', {
                        xmlns: "http://www.w3.org/1999/xhtml", 'xmlns:xfa': 'http://www.xfa.org/schema/xfa-data/1.0/',
                        'xfa:spec': '2.0.2', 'xfa:APIVersion': 'Acrobat:10.1.3'
                    })
                    .ele('p', { 'dir': 'ltr' })
                    .ele('span', { 'dir': 'ltr' }).txt(annotations[i].content ? annotations[i].content : '')
                    .up()
                    .up()
                    .up()
                    .up()
                    .ele('contents').txt(annotations[i].content ? annotations[i].content : '')
                    .up();
            } else {

                var rectVal = "";
                var rectangles = annotations[i].rectangles;

                if (rectangles) {
                    for (var x = 0; x < rectangles.length; x++) {
                        if (x > 0) {
                            rectVal = rectVal + ',';
                        }
                        rectVal = rectVal + rectangles[x].y + ',' + rectangles[x].x + ',' + rectangles[x].width + ',' + rectangles[x].height;
                    }
                }

                root.ele(annotations[i].type, {
                    'subject': annotations[i].type, 'page': annotations[i].page - 1, 'rect': rectVal,
                    'flags': 'print', 'name': annotations[i].uuid, 'title': annotations[i].title ? annotations[i].title : '', 'color': "#" + annotations[i].color,
                    'creationdate': annotations[i].createdAt ? annotations[i].createdAt : ''
                })
                    .ele('contents-richtext')
                    .ele('body', {
                        xmlns: "http://www.w3.org/1999/xhtml", 'xmlns:xfa': 'http://www.xfa.org/schema/xfa-data/1.0/',
                        'xfa:spec': '2.0.2', 'xfa:APIVersion': 'Acrobat:10.1.3'
                    })
                    .ele('p', { 'dir': 'ltr' })
                    .ele('span', { 'dir': 'ltr' }).txt(annotations[0].content ? annotations[0].content : '')
                    .up()
                    .up()
                    .up()
                    .up()
                    .ele('contents').txt(annotations[0].content ? annotations[0].content : '')
                    .up();
            }

            var replies = annotations[i].reply;
            if (replies) {
                if (replies.length > 0) {
                    for (var j = 0; j < replies.length; j++) {
                        root.ele('text', {
                            'subject': 'Comment', 'page': annotations[i].page - 1, 'rect': annotations[i].rectangles[0].y + ','
                                + annotations[i].rectangles[0].x + ',' + annotations[i].rectangles[i].width + ',' + annotations[i].rectangles[0].height,
                            'flags': 'print', 'name': replies[j].uuid, 'title': replies[j].title ? replies[j].title : '', 'color': "#" + annotations[i].color,
                            'creationdate': replies[j].createdAt ? replies[j].createdAt : '', 'inreplyto': annotations[i].uuid
                        })
                            .ele('contents-richtext')
                            .ele('body', {
                                xmlns: "http://www.w3.org/1999/xhtml", 'xmlns:xfa': 'http://www.xfa.org/schema/xfa-data/1.0/',
                                'xfa:spec': '2.0.2', 'xfa:APIVersion': 'Acrobat:10.1.3'
                            })
                            .ele('p', { 'dir': 'ltr' })
                            .ele('span', { 'dir': 'ltr' }).txt(replies[j].message ? replies[j].message : '')
                            .up()
                            .up()
                            .up()
                            .up()
                            .ele('contents').txt(replies[j].message ? replies[j].message : '')
                            .up();
                    }
                }
            }
        }

        root.up();
        root.up();
        root.up();

        // convert the XML tree to string
        const xfdfString = root.end({ prettyPrint: true });
        console.log(xfdfString);

        return xfdfString;
    }

    function downloadDocument() {

        // convert annot object to xml string
        var xfdfString = convertJsonToXfdf(annotationsData);

        // save to xml file
        downloadXFDFFile(xfdfString);

        // package and zip with pdf file

        // download
    }

    function downloadXFDFFile(xfdfString) {
        const element = document.createElement("a");
        const file = new Blob([xfdfString], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "myAnnotations.xfdf";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    function handleToolbar(tooltype, mobile = false) {

        // remove leader line when creating an actions with these buttons
        const removeElements = (elms) => elms.forEach(el => el.remove());
        removeElements(document.querySelectorAll(".leader-line"));

        // tooltype = localStorage.getItem(`${RENDER_OPTIONS.documentId}/tooltype`) || 'cursor';
        if (tooltype) {
            setActiveToolbarItem(tooltype, document.querySelector(`.toolbar button[data-tooltype=${tooltype}]`));
        }

        function setActiveToolbarItem(type, button) {
            let active = document.querySelector('.toolbar button.active');

            if (active) {
                active.classList.remove('active');
            }

            if (button) {
                button.classList.add('active');
            }
            clearSelection()
            tooltype = type;

            switch (type) {
                case 'cursor':
                    UI.enableEdit();
                    break;
                case 'draw':
                    UI.enablePen(rotation, scale, mobile);
                    break;
                case 'text':
                    UI.enableText(rotation, scale);
                    break;
                case 'point':
                    UI.enablePoint(rotation, scale);
                    break;
                case 'highlight':
                    let color = document.querySelector('.highlight-color .color') && document.querySelector('.highlight-color .color').getAttribute('data-color')
                    UI.enableRect('highlight', color, rotation, scale, mobile);
                    break;
                case 'area':
                case 'strikeout':
                    UI.enableRect(type, '', rotation, scale, mobile);
                    break;
            }
        }
    }

    function clearSelection() {
        // UI.disableEdit();
        UI.disablePen();
        UI.disableText();
        UI.disablePoint();
        UI.disableRect();
    }

    useEffect(() => {
        console.log("TESTTTTT")
    }, [scale])

    function handleClearClick(e) {
        if (isLock) return
        setIsLock(true)
        clearSelection()

        const authorId = localStorage.getItem(`/author/id`)
        Modal.confirm({
            title: 'Delete',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you Want to delete all annotations?',
            mask: true,
            onOk() {

                // remove leader line when creating an actions with these buttons
                const removeElements = (elms) => elms.forEach(el => el.remove());
                removeElements(document.querySelectorAll(".leader-line"));

                setIsLock(false)
                // authorId string 0 meaning this is a guest user
                // will delete all annotation for that user
                if (authorId === '0') {
                    for (let i = 0; i < totalPages; i++) {
                        document.querySelector(`div#pageContainer${i + 1} svg.annotationLayer`) &&
                            (document.querySelector(`div#pageContainer${i + 1} svg.annotationLayer`).innerHTML = '');
                    }
                    localStorage.removeItem(`${pdfInView}/annotations`);
                    props.onUpdateAnnotationsList([])
                } else {
                    // author is not 0 meaning that this is a logged user
                    // will delete annotations related to that user
                    const annotStorage = JSON.parse(localStorage.getItem(`${pdfInView}/annotations`))

                    annotStorage.map(item => {
                        if (item.authorId !== authorId) return
                        const viewer = window.document.getElementById('viewer').querySelector(`[data-page-number='${item.page}']`);
                        const svgElement = viewer.querySelector(`[data-pdf-annotate-id='${item.uuid}']`)
                        svgElement.remove()
                    })


                    const filterAnnotStorage = annotStorage.filter(data => data.authorId !== authorId)
                    localStorage.setItem(pdfInView + '/annotations', JSON.stringify(filterAnnotStorage));
                    props.onUpdateAnnotationsList(filterAnnotStorage)
                }
            },
            onCancel() {
                setIsLock(false)
            },
            zIndex: 9999
        });
    }

    function updateStorageRotate(newRotate) {
        const storage = JSON.parse(localStorage.getItem(`${pdfInView}/annotations`))
        if (storage) {
            storage[0].rotate = newRotate
            localStorage.setItem(`${pdfInView}/annotations`, JSON.stringify(storage))
        }
    }

    function handleRotateCWClick() {
        const newRotation = rotation + 90
        setRotation(newRotation % 360);
        updateStorageRotate(newRotation % 360)

        // remove leader line when creating an actions with these buttons
        const removeElements = (elms) => elms.forEach(el => el.remove());
        removeElements(document.querySelectorAll(".leader-line"));

    }

    function handleRotateCCWClick() {
        const newRotation = rotation + (360 - 90)
        setRotation(newRotation % 360);
        updateStorageRotate(newRotation % 360)

        // remove leader line when creating an actions with these buttons
        const removeElements = (elms) => elms.forEach(el => el.remove());
        removeElements(document.querySelectorAll(".leader-line"));
    }

    useEffect(() => {
        getAnnotationsDataFromStorage();
    }, [])

    useEffect(() => {
        function updateSize() {
            if (window.innerWidth <= 1450) {
                setLeftSidebarCollapse(true)
                setRightSidebarCollapse(true)
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const viewerId = 'mainPdf'

    return (
        <Layout className='dashboard-container' >
            <MediaQuery minWidth={991}>
                <Toolkit
                    id={viewerId}
                    scale={scale}
                    setScale={setScale}
                    viewControl={viewControl}
                    setViewControl={setViewControl}
                    setpdfInView={setpdfInView}
                    setDocumentViewer={setDocumentType}
                    setTextRecognition={setTextRecognition}
                    leftSidebarCollapse={leftSidebarCollapse}
                    setLeftSidebarCollapse={setLeftSidebarCollapse}
                    rightSidebarCollapse={rightSidebarCollapse}
                    setRightSidebarCollapse={setRightSidebarCollapse}
                />
            </MediaQuery>

            <MediaQuery maxWidth={991}>
                <MobileToolbar
                    scale={scale}
                    setScale={setScale}
                    viewControl={viewControl}
                    setViewControl={setViewControl}
                    handleToolbar={handleToolbar}
                    downloadDocument={downloadDocument}
                    onChangeAnnotations={(e) => { setSelectedAnnotationToAdd(e) }}
                    setAnnotType={(e) => setAnnotType(e)}
                    handleClearClick={handleClearClick}
                    handleRotateCWClick={handleRotateCWClick}
                    handleRotateCCWClick={handleRotateCCWClick}
                    pdfFile={pdfInView}
                    textRecognition={textRecognition}
                    isLock={isLock}
                />
            </MediaQuery>

            {(documentType == 'pdf') ?
                <>
                    {
                        //Desktop
                    }
                    <MediaQuery minWidth={991}>
                        <AnnotateToolbarv3
                            visible={viewControl === 'annotate'}
                            handleToolbar={handleToolbar}
                            downloadDocument={downloadDocument}
                            onChangeAnnotations={(e) => { setSelectedAnnotationToAdd(e) }}
                            setAnnotType={(e) => setAnnotType(e)}
                            handleClearClick={handleClearClick}
                            handleRotateCWClick={handleRotateCWClick}
                            handleRotateCCWClick={handleRotateCCWClick}
                            isLock={isLock}
                        />
                    </MediaQuery>
                    <Layout>


                        <MediaQuery minWidth={991}>
                            <Sider className='pdf-sider' trigger={null} collapsible width={300} style={{ height: viewControl === "view" ? 'calc(100vh - 50px)' : 'calc(100vh - 120px)' }} collapsed={leftSidebarCollapse} onClick={() => clearSelection()}>
                                <div className='panel-container'>
                                    <PanelView
                                        pdfFile={pdfInView}
                                        textRecognition={textRecognition}
                                        leftSidebarCollapse={leftSidebarCollapse}
                                        viewControl={viewControl}
                                    />
                                </div>
                            </Sider>
                        </MediaQuery>
                        <Layout className="site-layout">
                            <Content>
                                <div className='pdf-container' id="pdf-container" style={{ height: viewControl === "view" ? 'calc(100vh - 50px)' : 'calc(100vh - 120px)' }}>
                                    <div onMouseUp={e => checkIfSelectedText()} >
                                        <PdfView
                                            id={viewerId}
                                            scale={scale}
                                            rotation={rotation}
                                            pdfFile={pdfInView}
                                            viewControl={viewControl}
                                            selectedAnnotationToAdd={selectedAnnotationToAdd}
                                            setSelectedAnnotationToAdd={setSelectedAnnotationToAdd}
                                            getAnnotationsDataFromStorage={getAnnotationsDataFromStorage}
                                            setTotalPages={setTotalPages}
                                            annotType={annotType}
                                        />
                                    </div>
                                </div>
                            </Content>
                        </Layout>
                        <MediaQuery minWidth={991}>
                            <Sider className='pdf-sider' trigger={null} width={300} style={{ height: viewControl === "view" ? 'calc(100vh - 50px)' : 'calc(100vh - 120px)' }} collapsible collapsed={rightSidebarCollapse} onClick={() => clearSelection()}>
                                <AnnotationView
                                    id='mainPdf'
                                    pdfFile={pdfInView}
                                    rightSidebarCollapse={rightSidebarCollapse}
                                    viewControl={viewControl}
                                    clearSelection={clearSelection}
                                />
                                <button onClick={() => childRef.current.getAlert()}>Click</button>
                            </Sider>
                        </MediaQuery>
                    </Layout>
                </>

                :
                <iframe
                    // src={"https://view.officeapps.live.com/op/embed.aspx?src=https%3A%2F%2Fdamblob1.blob.core.windows.net%2Fassetcontainer%2F0d078ecd46474c99a7a1f6abac3bd13e.docx%3Fsv%3D2019-12-12%26spr%3Dhttps%2Chttp%26st%3D2021-02-08T12%253A10%253A16Z%26se%3D2021-02-08T13%253A10%253A16Z%26sr%3Db%26sp%3Dr%26rsct%3Dimage%252Fjpeg%26sig%3DI6gKscPTIrBKts1elXTSgOqffMbEE33AzZaPp%252BKqysw%253D"}
                    src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(pdfInView)}`}
                    // src={`<iframe src="http://docs.google.com/viewer?url=http%3A%2F%2Fwww.pfsense-br.org%2Fblog%2Fwp-content%2Fuploads%2F2011%2F10%2FTutorial-Squid-PFsense.pdf&embedded=true" width="600" height="780" style="border: none;"></iframe>`}
                    style={{ border: "none", width: "100%", height: "100vh" }}>
                </iframe>
                // <iframe src={`https://docs.google.com/gview?url=${pdfInView}&embedded=true`}
                //     style={{ border: "none", width: "100%", height: "100vh" }}
                // >
                //     <p>Your browser does not support iframes.</p>
                // </iframe>
            }
        </Layout >
    );
}


function mapStateToProps(state) {
    return {
        annotationsList: state.client.pdf.annotationsList,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onUpdateAnnotationsList: (annotations) => dispatch(updateAnnotationsList(annotations))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pdf)