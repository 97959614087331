
import React from 'react';
import { Input, Radio, Upload, Row, Col } from 'antd'
import '../index.scss'
import { connect } from 'react-redux'
import Scale from './scale'
// import PageControl from './pageControl'
import { isConfigured, readTextFromURL } from '../../../azure-cognitive';
import { IconButton, Icon } from "office-ui-fabric-react";
import { updateAnnotationsList } from '../../actions'
import SearchComponent from './search'
const IconStyles = {
    root: {
        color: '#5F41d2',
        fontSize: '21px',
        fontWeight: 300,
        display: 'block',
        border: '1px solid lightgray',
        borderRadius: 8,
        margin: 4
    }
}

const RadioStyle = {
    width: '20%', backgroundColor: '#e7ebee', border: '0px', textAlign: 'center'
}

// const onSelect = (value) => {
//     props.setViewControl(value);
// }

const Toolkit = (props) => {
    return (
        <Row className='toolkit'>
            <Col md={4} lg={4} sm={24} xs={24}>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: 50, margin: '0 20px' }}>
                    <IconButton
                        onClick={(e) => props.setLeftSidebarCollapse(!props.leftSidebarCollapse)}
                        iconProps={{ iconName: props.leftSidebarCollapse ? 'InsertColumnsRight' : 'InsertColumnsLeft' }}
                        styles={IconStyles}
                    />
                    {/* <PageControl /> */}
                    <Scale value={props.scale} onChange={props.setScale} />
                </div>

                {/* 
                <Button className='no-border' size='small' >
                    <FontAwesomeIcon icon={faArrowsAlt} />
                </Button>
                <PageControl />
                <Scale value={props.scale} onChange={props.setScale} />

                <Button className='no-border' size='small' >
                    <FontAwesomeIcon icon={faHandPaper} />
                </Button>

                <Button className='no-border' size='small' >
                    <FontAwesomeIcon icon={faCrop} />
                </Button> */}
            </Col>
            <Col md={16} lg={16} sm={24} xs={24}>
                <div className='view-actions'>
                    <Radio.Group
                        value={props.viewControl}
                        onChange={(e) => props.setViewControl(e.target.value)}
                        style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Radio.Button className='radio-btn' value="view" style={RadioStyle}> View</Radio.Button>
                        <Radio.Button className='radio-btn' value="annotate" style={RadioStyle}> Annotate</Radio.Button>
                        {/* <Radio.Button className='radio-btn' value="shapes" style={RadioStyle}> Shapes</Radio.Button> */}
                        {/* <Radio.Button className='radio-btn' value="insert" style={RadioStyle}> Insert</Radio.Button> */}
                        <Radio.Button className='radio-btn' value="edit" style={RadioStyle}> Edit</Radio.Button>
                    </Radio.Group>
                </div>
            </Col>
            <Col md={4} lg={4} sm={24} xs={24}>

                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: 50, margin: '0 20px' }}>
                    <SearchComponent id={props.id} />
                    {/* <Upload
                        showUploadList={false}
                        beforeUpload={(file, fileList) => {
                            // clear storage
                            localStorage.clear()
                            props.onUpdateAnnotationsList([])

                            console.log('Upload');
                            isConfigured();

                            props.setpdfInView(URL.createObjectURL(file));
                            props.setDocumentViewer(file.name.split('.').pop());

                            if (file.name.split('.').pop() == 'pdf' && file.size < 4194304) props.setTextRecognition(readTextFromURL(file));
                        }}
                    >
                        {// 
                         //   <Button className='no-border' size='small' >
                         //       <FontAwesomeIcon icon={faFileUpload} />
                         //   </Button> 
                        //
                        }
                        <IconButton
                            iconProps={{ iconName: 'Upload' }}
                            styles={IconStyles}
                        />
                    </Upload> */}

                    {/* <IconButton
                        onClick={(e) => props.handleToolbar()}
                        iconProps={{ iconName: 'Search' }}
                        styles={IconStyles}
                    /> */}

                    <IconButton
                        onClick={(e) => props.setRightSidebarCollapse(!props.rightSidebarCollapse)}
                        iconProps={{ iconName: props.rightSidebarCollapse ? 'InsertColumnsLeft' : 'InsertColumnsRight' }}
                        styles={IconStyles}
                    />
                </div>



            </Col>
        </Row>
    );
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        onUpdateAnnotationsList: (annotations) => dispatch(updateAnnotationsList(annotations))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Toolkit)