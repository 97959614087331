import React, { useEffect, useState } from 'react'
import AnnotationView from '../annotationView/annotationView'
import { Layout } from 'antd'
import { connect } from 'react-redux'
import { IconButton } from "office-ui-fabric-react";
import Scale from '../toolkit/scale'
import SearchComponent from '../toolkit/search'
import PdfView from './viewer';
import { updateAnnotationsList } from '../../actions'

import './index.scss'
const { Sider, Content } = Layout

const Compare = (props) => {
    const [totalPages, setTotalPages] = useState(0)
    const [viewControl, setViewControl] = useState('annotate')
    const [scale, setScale] = useState(70)
    const [rightSidebarCollapse, setLeftSidebarCollapse] = useState(false)

    return (
        <div className={props.id} id={props.id} >
            <div className='pdf-compare-toolbar' >
                <div className='flex-row'>
                    <Scale value={scale} onChange={setScale} />
                </div>
                <div className='flex-row'>
                    <SearchComponent showSearch={true} id={props.id} />
                    <IconButton
                        onClick={(e) => setLeftSidebarCollapse(!rightSidebarCollapse)}
                        iconProps={{ iconName: 'SidePanel' }}
                        styles={IconStyles}
                    />
                </div>
            </div>
            <Layout>
                <Content className='pdf-container' id="pdf-container">
                    <PdfView
                        id={props.id}
                        setTotalPages={setTotalPages}
                        scale={scale}
                        rotation={0}
                        pdfFile={props.pdfFile}
                    />
                </Content>
                <Sider className='pdf-compare-comments' trigger={null} width={300} collapsible collapsed={rightSidebarCollapse}>
                    <AnnotationView
                        id={props.id}
                        pdfFile={props.pdfFile}
                        rightSidebarCollapse={rightSidebarCollapse}
                        viewControl={viewControl}
                        compareEnabled={true}
                    />
                </Sider>
            </Layout>
        </div>

    )
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        onUpdateAnnotationsList: (annotations) => dispatch(updateAnnotationsList(annotations))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Compare)

const IconStyles = {
    root: {
        color: '#5F41d2',
        fontSize: '21px',
        fontWeight: 300,
        display: 'block',
        border: '1px solid lightgray',
        borderRadius: 8,
        margin: 4
    }
}