import React, { Component } from 'react'
import { Route, Switch, Redirect, withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import * as _ from 'lodash'
// import logo from '../../assets/logo/logo.png'
import { Layout, Menu, Modal, Input, Row, Col, Badge } from 'antd'
import 'antd/dist/antd.css'
import { NotFound } from './notFound'
import Login from './login/component'
import Dashboard from './dashboard/components'
import { getUser } from '../../utilities/token'

import './index.scss'
import {
  DashboardOutlined,
  LogoutOutlined,
  ExclamationCircleOutlined,
  MessageOutlined,
  NotificationOutlined,
} from '@ant-design/icons'
const { Footer, Sider, Content, Header } = Layout;

moment.updateLocale(moment.locale(), { invalidDate: 'N/A' })

const paths = [
  {
    default: true,
    exact: true,
    slug: 'Dashboard',
    route: '/admin',
    component: Dashboard,
    icon: < DashboardOutlined />
  }
]

const MainLayout = (props) => {
  return (
    <Layout {...props} className='app-layout'>
      {props.children}
    </Layout>
  )
}

const isLocalStorageEmpty = () => {
  let strResult
  if (localStorage.getItem('ADMIN_SESSION_TOKEN') !== null) {
    strResult = true
  } else {
    strResult = false
  }
  return strResult
}

class App extends Component {
  constructor() {
    super()
    this.state = {
      isLoggedIn: false,
      activeTab: 0
    }
  }

  logout() {
    const self = this
    Modal.confirm({
      title: 'Do you want to logout?',
      icon: <ExclamationCircleOutlined />,
      content: 'You will redirected to login page',
      onOk() {
        localStorage.removeItem('ADMIN_SESSION_TOKEN')
        self.props.history.push('/admin')
        window.location.reload()
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  componentWillMount() {
    window.document.title = '[ADMIN] Owner Management Portal'
    const currentPathIndex = paths.findIndex(data => data.route === this.props.location.pathname)
    this.setState({ activeTab: currentPathIndex + 1 })

    if (isLocalStorageEmpty()) this.setState({ isLoggedIn: false })
    else this.setState({ isLoggedIn: true })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.loggedIn !== nextProps.loggedIn && nextProps.loggedIn) {
      this.setState({ isLoggedIn: true })
      window.location.reload()
    }
  }

  render() {
    switch (this.state.isLoggedIn) {
      case true:
        return (
          <MainLayout>
            <Login />
          </MainLayout>
        )

      case false:
        return (
          <Layout className='admin-container'>
            <Sider
              style={{
                // overflow: 'auto',
                // height: '100vh',
                // position: 'fixed',
                left: 0,
                background: 'rgb(41,77,240)'
              }}
              breakpoint="lg"
              collapsedWidth="0"
              onBreakpoint={broken => {
                console.log(broken);
              }}
              onCollapse={(collapsed, type) => {
                console.log(collapsed, type);
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '20px 15px' }}>
                <h1 style={{ color: 'white', textAlign: 'center' }}>Century Properties <br /> Inc.</h1>
              </div>
              <Menu theme="dark" style={{ background: 'transparent' }} onClick={(e) => {
                this.setState({ activeTab: e.key })
              }} mode="inline" defaultSelectedKeys={[this.state.activeTab.toString()]}>
                {
                  paths.map((data, i) => {
                    return (
                      <Menu.Item key={(i + 1)} icon={data.icon}>
                        <Link to={data.route}>
                          {data.slug}
                        </Link>
                      </Menu.Item>
                    )
                  })
                }
                <Menu.Item icon={<LogoutOutlined />} onClick={() => this.logout()}>
                  Logout
              </Menu.Item>
              </Menu>
            </Sider>
            <Layout>
              <Header className='admin-main-header-container'>
                <Row style={{ width: '100%' }}>
                  <Col md={12} lg={12} sm={24} xs={24}>
                    <Input className='search' placeholder='[Search] Ex: Tenants, Tickets, Reservations' />
                  </Col>
                  <Col md={12} lg={12} sm={24} xs={24} align='right' >
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <div style={{ margin: 10 }}>
                        <Badge count={100} >
                          <div>
                            <NotificationOutlined style={{ fontSize: 25, color: 'gray' }} />
                          </div>
                        </Badge>
                      </div>
                      <div style={{ margin: 10 }}>
                        <Badge count={100}>
                          <div>
                            <MessageOutlined style={{ fontSize: 25, color: 'gray' }} />
                          </div>
                        </Badge>
                      </div>
                      <div style={{ margin: 10 }}>
                        <div style={{ marginTop: -8 }}>
                          <img src={getUser().image} style={{ height: 30, width: 30, borderRadius: 15, objectFit: 'cover' }} />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Header>
              <Content className='main-layout-content' style={{ padding: '5vh 2vw' }} >
                <Switch>
                  {/* // this will scroll to top when switch changes. */}
                  {window.scrollTo(0, 0)}
                  {_.map(paths, (path) => (
                    <Route
                      exact={path.exact}
                      path={path.route}
                      component={path.component}
                      key={path.route}
                    />
                  ))}
                  <Route component={NotFound} />
                  <Redirect to='/404' />
                </Switch>
                <div style={{ paddingBottom: '50vh' }} />
              </Content>

              <Footer style={{ background: '#0095c9' }}>
                <div style={{ textAlign: 'center' }}>
                  <span style={{ color: 'white' }}>
                    © 2021 All Rights Reserved.
                  </span>
                </div>
              </Footer>
            </Layout>
          </Layout>

        )
      default:
        break
    }
  }
}

function mapStateToProps(state) {
  return {
    loggedIn: state.adminLogin.loggedIn
  }
}

export default withRouter(
  connect(mapStateToProps, {})(App)
)
