import React, { useLayoutEffect, useEffect, useState } from 'react'
import PDFJSAnnotate from '../../../../PDFJSAnnotate'
import DesignCanvas from './customCanvas/designCanvas'
import Circle from './customCanvas/circle'
import { connect } from 'react-redux'
import { initializeIcons } from "office-ui-fabric-react";
import { AnnotateToolbarv2, ShapesToolbar, InsertToolbar } from "./toolbar.js";
import { updateAnnotationsList } from '../actions'
import { Modal, Spin } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './toolbar.css'
import './pdfViewer.css'
import { LoadingOutlined } from '@ant-design/icons';
import initColorPicker from '../../../../utilities/initColorPicker'
const { UI } = PDFJSAnnotate;

let renderedPages = [];
let okToRender = false;

const PdfView = (props) => {
    const [canvasArray, setCanvasArray] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        initializeIcons();
    }, []);
    let scale = props.scale / 100; // 1.0
    let rotate = props.rotation
    const storage = JSON.parse(localStorage.getItem(`${props.pdfFile}/annotations`))


    PDFJSAnnotate.setStoreAdapter(new PDFJSAnnotate.LocalStoreAdapter());
    let NUM_PAGES = 0;
    // let renderedPages = {};

    PDFJS.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/1.4.162/pdf.worker.min.js'
    const url = props.pdfFile
    const documentId = url;
    let PAGE_HEIGHT;
    let RENDER_OPTIONS = {
        documentId,
        pdfDocument: null,
        scale: scale,
        rotate: storage ? (storage[0] && storage[0].rotate || 0) : rotate
    };

    const idViewer = `${props.id}-viewer`
    function render() {
        let viewer = document.getElementById(idViewer);
        try {
            PDFJS.getDocument(RENDER_OPTIONS.documentId).then((pdf) => {
                RENDER_OPTIONS.pdfDocument = pdf;
                viewer.innerHTML = '';
                NUM_PAGES = pdf.pdfInfo.numPages;
                props.setTotalPages(NUM_PAGES)
                for (let i = 0; i < NUM_PAGES; i++) {
                    let page = UI.createPage(i + 1);
                    viewer.appendChild(page);
                }
                UI.renderPage(1, RENDER_OPTIONS, idViewer).then(([pdfPage, annotations]) => {
                    let viewport = pdfPage.getViewport(RENDER_OPTIONS.scale, RENDER_OPTIONS.rotate);
                    PAGE_HEIGHT = viewport.height;
                    canvasRenderer()

                });
            });
        } catch (error) { }
    }

    useLayoutEffect(() => {
        UI.disableEdit();
        UI.disablePen();
        UI.disableText();
        UI.disablePoint();
        UI.disableRect();
    }, [
        // Reload pdf only if one of this array changed.
        // props.scale,
        props.rotation,
        props.pdfFile
    ])

    useEffect(() => {
        // Pen stuff
        (function () {
            var penSize = void 0;
            var penColor = void 0;;

            function initPen() {
                let size = document.querySelector('.pen-size');
                for (let i = 0; i < 12; i++) {
                    size && size.appendChild(new Option(i + 1, i + 1));
                }

                setPen(
                    localStorage.getItem(`${RENDER_OPTIONS.documentId}/pen/size`) || 1,
                    localStorage.getItem(`${RENDER_OPTIONS.documentId}/pen/color`) || '#000000'
                );

                // adding if statement here meaning that dont add init color when useEffectLayout is rerendering
                if (document.querySelector('.pen-color') && document.querySelector('.pen-color').childNodes.length < 1) {
                    initColorPicker(document.querySelector('.pen-color'), penColor, function (value) {
                        setPen(penSize, value);
                    });
                }

            }

            function setPen(size, color) {
                let modified = false;

                if (penSize !== size) {
                    modified = true;
                    penSize = size;
                    localStorage.setItem(`${RENDER_OPTIONS.documentId}/pen/size`, penSize);
                    document && document.querySelector('.pen-size') &&
                        document.querySelector('.pen-size').value &&
                        (document.querySelector('.pen-size').value = penSize);
                }

                if (penColor !== color) {
                    modified = true;
                    penColor = color;
                    localStorage.setItem(`${RENDER_OPTIONS.documentId}/pen/color`, penColor);

                    let selected = document.querySelector('.pen-color.color-selected');
                    if (selected) {
                        selected.classList.remove('color-selected');
                        selected.removeAttribute('aria-selected');
                    }

                    selected = document && document.querySelector(`.pen-color[data-color="${color}"]`);
                    if (selected) {
                        selected.classList.add('color-selected');
                        selected.setAttribute('aria-selected', true);
                    }
                }

                if (modified) {
                    console.log("MODIFIED!", penSize, penColor)

                    UI.setPen(penSize, penColor);
                }
            }

            function handlePenSizeChange(e) {
                setPen(e.target.value, penColor);
            }

            document.querySelector('.pen-size') &&
                document.querySelector('.pen-size').addEventListener('change', handlePenSizeChange);

            initPen();
        })();

        // Text stuff
        (function () {
            var textSize = void 0;
            var textColor = void 0;

            function initText() {
                var size = document.querySelector('.text-size');
                [8, 9, 10, 11, 12, 14, 18, 24, 30, 36, 48, 60, 72, 96].forEach(function (s) {
                    size && size.appendChild(new Option(s, s));
                });

                setText(localStorage.getItem(RENDER_OPTIONS.documentId + '/text/size') || 10, localStorage.getItem(RENDER_OPTIONS.documentId + '/text/color') || '#000000');

                // adding if statement here meaning that dont add init color when useEffectLayout is rerendering
                if (document.querySelector('.text-color') && document.querySelector('.text-color').childNodes.length < 1) {
                    initColorPicker(document.querySelector('.text-color'), textColor, function (value) {
                        setText(textSize, value);
                    });
                }

            }

            function setText(size, color) {
                var modified = false;

                if (textSize !== size) {
                    modified = true;
                    textSize = size;
                    localStorage.setItem(RENDER_OPTIONS.documentId + '/text/size', textSize);
                    document &&
                        document.querySelector('.toolbar .text-size') &&
                        (document.querySelector('.toolbar .text-size').value = textSize);
                }

                if (textColor !== color) {
                    modified = true;
                    textColor = color;
                    localStorage.setItem(RENDER_OPTIONS.documentId + '/text/color', textColor);

                    var selected = document.querySelector('.toolbar .text-color.color-selected');

                    if (selected) {
                        selected.classList.remove('color-selected');
                        selected.removeAttribute('aria-selected');
                    }

                    selected = document.querySelector('.toolbar .text-color[data-color="' + color + '"]');
                    if (selected) {
                        selected.classList.add('color-selected');
                        selected.setAttribute('aria-selected', true);
                    }
                }

                if (modified) {
                    UI.setText(textSize, textColor)
                }
            }

            function handleTextSizeChange(e) {
                setText(e.target.value, textColor);
            }

            document &&
                document.querySelector('.toolbar .text-size') &&
                document.querySelector('.toolbar .text-size').addEventListener('change', handleTextSizeChange);

            initText();
        })();
    }, [])

    useLayoutEffect(() => {
        let viewer = document.getElementById(idViewer);
        if (!viewer) return
        if (props.rotation === undefined) return
        try {
            setLoading(true)
            if (props.pdfFile.length > 0) {
                document.getElementById('pdf-container').scrollTo(0, 0)
                renderedPages = [];
                okToRender = false;
                const nodes = document.getElementById(idViewer).childNodes
                for (let index = 0; index < nodes.length; index++) {
                    const element = nodes[index];
                    element.setAttribute('data-loaded', 'false')
                    element.style = 'display: none;'
                }

                RENDER_OPTIONS.rotate = props.rotation
                RENDER_OPTIONS.documentId = props.pdfFile

                PDFJS.getDocument(props.pdfFile).then((pdf) => {
                    RENDER_OPTIONS.pdfDocument = pdf;
                    NUM_PAGES = pdf.pdfInfo.numPages;

                    for (let index = 1; index <= NUM_PAGES; index++) {
                        setTimeout(function () {
                            UI.renderPage(index, RENDER_OPTIONS, idViewer);
                            setLoading(false);
                        }, 1000);
                    }
                });

                render()
            }
            else {
                setLoading(false);
            }

        } catch (error) {
            setLoading(false);
        }
    }, [props.scale, props.rotation])


    function canvasRenderer() {
        let viewer = document.getElementById(idViewer);
        if (!viewer) return
        const newCanvasArray = []
        const viewerChild = document.getElementById(idViewer).childNodes
        for (let index = 0; index < viewerChild.length; index++) {
            const element = viewerChild[index];
            newCanvasArray.push(
                <DesignCanvas width={+viewerChild[0].style.width.replace('px', '') || 0} height={+viewerChild[0].style.height.replace('px', '') || 0} disabled>
                    <Circle height={100} top={20} left={20} radius={20} top={200} fill='transparent' stroke="red" type="circle" />
                </DesignCanvas>
            )
        }
        setCanvasArray(newCanvasArray)
    }

    useEffect(() => {
        if (props.viewControl === 'view') {
            UI.disableEdit();
            UI.disablePen();
            UI.disableText();
            UI.disablePoint();
            UI.disableRect();
        }
    }, [props.viewControl])

    return (
        <div>
            {
                loading &&
                <div className='pdf-overlay'>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 44, fontWeight: 'bold' }} spin />} />
                </div>
            }
            <ShapesToolbar visible={props.viewControl === 'shapes'} />
            <InsertToolbar visible={props.viewControl === 'insert'} />
            <div>
                <div id={idViewer} className="pdfViewer" style={{ userSelect: props.viewControl === 'view' && 'none' }}></div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        onUpdateAnnotationsList: (annotations) => dispatch(updateAnnotationsList(annotations))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PdfView)

