import React, { Fragment } from 'react'
import Rect from './customCanvas/rect'
import Circle from './customCanvas/circle'
import Line from './customCanvas/line'
import Image from './customCanvas/image'
import TextNormal from './customCanvas/textNormal'
import TextUnderline from './customCanvas/textUnderline'
import TextBold from './customCanvas/textBold'
import CommentBox from './customCanvas/comment'
import Signature from './customCanvas/signature'
import DesignCanvas from './customCanvas/designCanvas'
import './index.scss'

class Timeframe extends React.Component {
    constructor() {
        super()
        this.state = {
            refDesignCanvas: undefined
        }
        this.canvasRendererInPlaytime = this.canvasRendererInPlaytime.bind(this)
    }
    canvasRendererInPlaytime = () => {

        const { refDesignCanvas } = this.state
        const canvasArrayItem = []

        const filteredByTime = this.props.data.filter((item, index) =>
            this.props.seconds >= item.startTime &&
            this.props.seconds <= item.endTime
        )

        const filteredIds = filteredByTime.map(data => { return data.uuid })
        refDesignCanvas && refDesignCanvas.removeAllCanvasExceptOnTheseIds(filteredIds)

        if (filteredByTime.length !== 0) {
            filteredByTime.map((item, index) => {

                if (refDesignCanvas && refDesignCanvas.checkIfCanvasIdActiveInPlaytime(item.uuid)) return
                switch (item.type) {
                    case 'line':
                        return canvasArrayItem.push(<Line key={index} {...item} editable={false} selectable={false} />)
                    case 'textNormal':
                        return canvasArrayItem.push(<TextNormal key={index} {...item} editable={false} selectable={false} />)
                    case 'textUnderline':
                        return canvasArrayItem.push(<TextUnderline key={index} {...item} editable={false} selectable={false} />)
                    case 'textBold':
                        return canvasArrayItem.push(<TextBold key={index} {...item} editable={false} selectable={false} />)
                    case 'rect':
                        return canvasArrayItem.push(<Rect key={index} {...item} editable={false} selectable={false} />)
                    case 'circle':
                        return canvasArrayItem.push(<Circle key={index}{...item} editable={false} selectable={false} />)
                    case 'image':
                        return canvasArrayItem.push(<Image key={index} url={item.src} {...item} editable={false} selectable={false} />)
                    case 'comment':
                        return canvasArrayItem.push(<CommentBox key={index} {...item} selectable={false} />)
                    case 'signature':
                        return canvasArrayItem.push(<Signature key={index} url={item.src} {...item} editable={false} selectable={false} />)
                    default:
                        break;
                }
            })
        }

        return canvasArrayItem || null
    }

    render() {
        return (
            <div className='timeframe' style={{ width: this.props.width, height: this.props.height }}>
                <img src={this.props.src} data-seconds={this.props.seconds} style={{ width: this.props.width }} />
                <div className='canvas-content' >
                    <DesignCanvas ref={this.refDesign} height={this.props.height} width={this.props.width} >
                        {this.canvasRendererInPlaytime()}
                    </DesignCanvas>
                </div>
            </div>

        )
    }
}

Timeframe.defaultProps = {
    width: 940,
    height: 660,
}

export default Timeframe

