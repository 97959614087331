import React, { Fragment } from 'react'
import PropTypes from 'prop-types'


class DesignCanvas extends React.Component {
    static propTypes = {
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
    }

    static defaultProps = {
        width: 673.2,
        height: 871.2,
        visible: true
    }

    state = {
        canvas: null,
    }

    componentDidMount() {
        const fabric = require("fabric").fabric;
        const canvas = new fabric.Canvas(this.c)
        this.setState({ canvas })
    }

    render() {
        const children = React.Children.map(this.props.children, child => {
            return React.cloneElement(child, {
                canvas: this.state.canvas
            })
        })
        const { width, height } = this.props
        console.log("width, height", width, height)
        return (
            this.props.visible ?
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 11
                    // borderLeft: '9px solid transparent',
                    // pointerEvents: this.props.disabled && 'none',
                }}>
                    <canvas style={{
                        //  background: 'red' 
                    }} ref={c => (this.c = c)} width={width} height={height} />
                    {this.state.canvas && children}
                </div> : null
        )
    }
}

export default DesignCanvas
