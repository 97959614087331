import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
// import { fabric } from "fabric";


class DesignCanvas extends React.Component {
    constructor() {
        super()
        this.deleteSelectedCanvas = this.deleteSelectedCanvas.bind(this)
        this.removeAllCanvasExceptOnTheseIds = this.removeAllCanvasExceptOnTheseIds.bind(this)
    }
    static propTypes = {
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
    }

    state = {
        canvas: null,
    }

    componentDidMount() {
        const fabric = require("fabric").fabric;
        const canvas = new fabric.Canvas(this.c)
        this.setState({ canvas })
    }

    handleMouseMove() {
        try {
            this.props.setAdditionalCanvas && this.props.setAdditionalCanvas(this.state.canvas.toJSON())
        } catch (error) {
            console.log("ERROR", error)
        }
    }

    deleteSelectedCanvas() {
        const { canvas } = this.state
        var activeObject = canvas.getActiveObject();
        canvas.discardActiveObject();
        canvas.remove(activeObject);
    }

    // delete a specific canvas based on uuid.
    deleteCanvasBaseOnId(uuid) {
        console.log("DELETE CANVAS", uuid)
        const { canvas } = this.state
        let obj = canvas.getObjects().find(obj => obj.uuid === uuid);
        canvas.remove(obj);
    }

    // will remove all canvas in the display except on these ids.
    removeAllCanvasExceptOnTheseIds(arrayIds) {
        const { canvas } = this.state
        if (canvas === null) return
        // safety, removing duplicate array
        const uniqArray = [...new Set(canvas.getObjects())]
        uniqArray.map((obj, index) => {

            // if the canvas object does not have ID it means it is newly added.
            // dont remove canvas object that does not have uuid, terminate function using return
            if (!obj["uuid"]) return

            let isFound = arrayIds.find(uuid => uuid === obj.uuid)
            if (!isFound) {
                obj.animate('opacity', obj.opacity === 0 ? 1 : 0, {
                    duration: 500,
                    onChange: canvas.renderAll.bind(canvas),
                    onComplete: function () {
                        if (obj.opacity === 0) {
                            canvas.remove(obj);
                        }
                    },
                    easing: fabric.util.ease['easeInQuad']
                });
            }
        })
    }

    // a checker function that check if the canvas is active at playtime using their uuid.
    checkIfCanvasIdActiveInPlaytime(uuid) {
        const { canvas } = this.state
        if (canvas === null) return
        const isActive = canvas.getObjects().find(obj => obj.uuid === uuid)
        if (isActive) return true
    }

    getSelectedCanvasObject() {
        const { canvas } = this.state
        if (canvas === null) return
        return canvas.getActiveObject()
    }

    removeAllCanvasHasNoId() {
        const { canvas } = this.state
        const x = canvas.getObjects().map((obj, index) => {
            if (!obj["uuid"]) canvas.remove(obj);
        }).filter(data => data)
    }

    render() {
        // filter: removing empty object
        const filteredProps = this.props.children && this.props.children.filter(n => n)
        const children = React.Children.map(filteredProps, child => {
            if (filteredProps) {
                return React.cloneElement(child, {
                    canvas: this.state.canvas,
                })
            } else return null
        })

        const { width, height } = this.props
        return (
            <div id={this.props.uuid} onMouseUp={() => this.handleMouseMove()}   >
                <canvas ref={c => (this.c = c)} width={width} height={height} />
                {this.state.canvas && children}
            </div>
        )
    }
}

DesignCanvas.defaultProps = {
    width: 940,
    height: 660,
}

export default DesignCanvas
