import React, { useState, useEffect } from 'react'
import { IconButton, Icon } from "office-ui-fabric-react";
import initColorPicker from '../../../../utilities/initColorPicker'
import PDFJSAnnotate from '../../../../PDFJSAnnotate'
const { UI } = PDFJSAnnotate;
// import { Icon } from '@fluentui/react/lib/Icon';
import { Button } from 'antd'
const IconStyles = {
    root: {
        color: '#868e96',
        fontSize: '21px',
        fontWeight: 300,
        display: 'block',
    }
}

const IconGroup = {
    root: {
        fontSize: '21px',
        fontWeight: 300,
        display: 'block',
        cursor: 'pointer'
    }
}
export const AnnotateToolbarv2 = (props) => {
    return (
        <div style={{ zIndex: 9999, display: props.visible ? 'block' : 'none' }}>
            <div className="toolbar">

                {/*Color of Text*/}
                <IconButton
                    onClick={(e) => props.handleToolbar()}
                    iconProps={{ iconName: 'ColorSolid' }}
                    styles={IconStyles}
                />
                {/*Underline Text*/}
                <IconButton
                    onClick={(e) => props.handleToolbar()}
                    iconProps={{ iconName: 'Underline' }}
                    styles={IconStyles}
                />
                {/*Cursor*/}
                <IconButton
                    onClick={(e) => props.handleToolbar()}
                    iconProps={{ iconName: 'ArrowTallUpRight' }}
                    styles={IconStyles}
                />
                <div className="spacer"></div>
                {/*Rectangle*/}
                <IconButton
                    onClick={(e) => props.handleToolbar('area')}
                    iconProps={{ iconName: 'RectangleShape' }}
                    styles={IconStyles}
                    className="rectangle"
                    title="Rectangle"
                    data-tooltype="area"
                    type="button"
                />
                {/*Highlight*/}
                <IconButton
                    onClick={(e) => props.handleToolbar('highlight')}
                    iconProps={{ iconName: 'Highlight' }}
                    className="highlight"
                    title="Highlight"
                    data-tooltype="highlight"
                    styles={IconStyles}
                />

                {/*Strikethrough*/}
                <IconButton
                    onClick={(e) => props.handleToolbar('strikeout')}
                    iconProps={{ iconName: 'Strikethrough' }}
                    styles={IconStyles}
                    className="strikeout"
                    title="Strikeout"
                    data-tooltype="strikeout"
                />
                <div className="spacer"></div>

                {/*Insert Text*/}
                <IconButton
                    onClick={(e) => props.handleToolbar('text')}
                    iconProps={{ iconName: 'InsertTextBox' }}
                    className="text"
                    type="button"
                    title="Text Tool"
                    data-tooltype="text"
                    styles={IconStyles}
                />
                <select className="text-size"></select>
                <div className="text-color"></div>

                <div className="spacer"></div>
                {/*Pentool*/}
                <IconButton
                    onClick={(e) => props.handleToolbar('draw')}
                    styles={IconStyles}
                    className="pen"
                    title="Pen Tool"
                    data-tooltype="draw"
                    iconProps={{ iconName: 'PenWorkspace' }}

                />
                <select className="pen-size"></select>
                <div className="pen-color"></div>

                <div className="spacer"></div>
                {/*Insert Comment*/}
                <IconButton
                    onClick={(e) => props.handleToolbar('point')}
                    iconProps={{ iconName: 'Comment' }}
                    styles={IconStyles}
                    type="button"
                    title="Comment"
                    data-tooltype="point"
                />

                <div className="spacer"></div>

                <select className="scale">
                    <option value=".5">50%</option>
                    <option value="1">100%</option>
                    <option value="1.33">133%</option>
                    <option value="1.5">150%</option>
                    <option value="2">200%</option>
                </select>
                {/*rotate 90 deg clockw*/}
                <a href="" className="rotate-ccw" title="Rotate Counter Clockwise">
                    <IconButton
                        iconProps={{ iconName: 'Rotate90CounterClockwise' }}
                        styles={IconStyles}
                    />
                </a>
                {/*rotate 90 deg ctrclockw*/}
                <a href="" className="rotate-cw" title="Rotate Clockwise">
                    <IconButton
                        iconProps={{ iconName: 'Rotate90Clockwise' }}
                        styles={IconStyles}
                    />
                </a>

                <div className="spacer"></div>
                {/* Commented Redo/Undo For now */}
                {/*undo*/}
                {/* <IconButton
                    iconProps={{ iconName: 'Undo' }}
                    styles={IconStyles}
                /> */}
                {/*redo*/}
                {/* <IconButton
                    iconProps={{ iconName: 'Redo' }}
                    styles={IconStyles}
                /> */}

                {/*Erase annot?*/}
                <a href="" className="clear" title="Clear">
                    <IconButton
                        iconProps={{ iconName: 'EraseTool' }}
                        styles={IconStyles}
                    />
                </a>

            </div>
        </div>
    )
}

export const AnnotateToolbarv3 = (props) => {
    const [selection, setSelection] = useState('annotate')

    useEffect(() => {
        var textColor = '#FFFF00';
        if (document.querySelector('.highlight-color').childNodes.length < 1) {
            initColorPicker(document.querySelector('.highlight-color'), textColor, function (value) {
                UI.enableRect('highlight', value)
            });
        }

    }, [])

    return (
        <div style={{ zIndex: 9999, display: props.visible ? 'block' : 'none', pointerEvents: props.isLock ? 'none' : '' }}>
            <div className="toolbar">

                <div style={{ flexDirection: 'row', display: selection === 'annotate' ? 'flex' : 'none' }}>

                    <div className='btn-container' onClick={(e) => props.handleToolbar('cursor')}>
                        <IconButton
                            iconProps={{ iconName: 'Move' }}
                            styles={IconStyles}
                            type="button"
                            title="Cursor"
                            data-tooltype="cursor"
                        />
                        <span>Move</span>
                    </div>

                    <div className='btn-container' onClick={(e) => props.handleToolbar('point')}>
                        <IconButton
                            iconProps={{ iconName: 'Comment' }}
                            styles={IconStyles}
                            type="button"
                            title="Comment"
                            data-tooltype="point"
                        />
                        <span>Note</span>
                    </div>

                    <div className='btn-container' onClick={(e) => props.handleToolbar('area')}>
                        <IconButton
                            iconProps={{ iconName: 'RectangleShape' }}
                            styles={IconStyles}
                            className="rectangle"
                            title="Rectangle"
                            data-tooltype="area"
                            type="button"
                        />
                        <span>Area</span>
                    </div>

                    <div className='btn-container' onClick={(e) => props.handleToolbar('highlight')} >
                        <div className='btn-group'>
                            <div className='pos-left'>
                                <IconButton
                                    iconProps={{ iconName: 'Highlight' }}
                                    className="highlight"
                                    title="Highlight"
                                    data-tooltype="highlight"
                                    styles={IconStyles}
                                />
                                <span>Highlight</span>
                            </div>
                            <div className='pos-right'>
                                <div className="highlight-color"></div>
                            </div>
                        </div>

                    </div>

                    <div className='btn-container' onClick={(e) => props.handleToolbar('strikeout')} >
                        <IconButton
                            iconProps={{ iconName: 'Strikethrough' }}
                            styles={IconStyles}
                            className="strikeout"
                            title="Strikeout"
                            data-tooltype="strikeout"
                        />
                        <span>Strikeout</span>
                    </div>

                    <div className='btn-container' >
                        <div className='btn-group'>
                            <div className='pos-left' onClick={(e) => props.handleToolbar('text')}>
                                <IconButton
                                    iconProps={{ iconName: 'InsertTextBox' }}
                                    className="text"
                                    type="button"
                                    title="Text Tool"
                                    data-tooltype="text"
                                    styles={IconStyles}
                                />
                                <span>Add Text</span>
                            </div>
                            <div className='pos-right'>
                                <select className="text-size"></select>
                                <div className="text-color"></div>
                            </div>
                        </div>
                    </div>

                    <div className='btn-container' >
                        <div className='btn-group'>
                            <div className='pos-left' onClick={(e) => props.handleToolbar('draw')}>
                                <IconButton
                                    styles={IconStyles}
                                    className="pen"
                                    title="Pen Tool"
                                    data-tooltype="draw"
                                    iconProps={{ iconName: 'PenWorkspace' }}
                                />
                                <span>Drawing</span>
                            </div>
                            <div className='pos-right'>
                                <select className="pen-size"></select>
                                <div className="pen-color"></div>
                            </div>
                        </div>
                    </div>

                    {/* <select className="scale">
                    <option value=".5">50%</option>
                    <option value="1">100%</option>
                    <option value="1.33">133%</option>
                    <option value="1.5">150%</option>
                    <option value="2">200%</option>
                </select> */}

                    <div className='btn-container' >
                        {/*rotate 90 deg clockw*/}
                        <a className="rotate-ccw" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} title="Rotate Counter Clockwise">
                            <IconButton
                                onClick={() => props.handleRotateCCWClick()}
                                iconProps={{ iconName: 'Rotate90CounterClockwise' }}
                                styles={IconStyles}
                            />
                            <span>Rotate C-CW</span>
                        </a>
                    </div>

                    <div className='btn-container' >
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {/*rotate 90 deg ctrclockw*/}
                            <a className="rotate-cw" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} title="Rotate Clockwise">
                                <IconButton
                                    onClick={() => props.handleRotateCWClick()}
                                    iconProps={{ iconName: 'Rotate90Clockwise' }}
                                    styles={IconStyles}
                                />
                                <span>Rotate CW</span>
                            </a>
                        </div>
                    </div>

                    <div className='btn-container' >
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {/*Erase annot?*/}
                            <a className="clear" title="Clear">
                                <IconButton
                                    onClick={() => props.handleClearClick()}
                                    iconProps={{ iconName: 'EraseTool' }}
                                    styles={IconStyles}
                                />
                                <span>Erase All</span>
                            </a>
                        </div>
                    </div>

                    {/* <div className='btn-container' onClick={(e) => props.downloadDocument()} >
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <a className="download-doc" title="Download Document">
                                <IconButton
                                    iconProps={{ iconName: 'DownloadDocument' }}
                                    styles={IconStyles}
                                />
                                <span>Download</span>
                            </a>
                        </div>
                    </div> */}
                </div>

                <div style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', display: selection === 'shapes' ? 'flex' : 'none' }}>
                    <div className='btn-container'>
                        <IconButton
                            onClick={(e) => props.handleToolbar('point')}
                            iconProps={{ iconName: 'Line' }}
                            styles={IconStyles}
                        />
                        <span>Line</span>
                    </div>

                    <div className='btn-container'>
                        <IconButton
                            onClick={(e) => props.handleToolbar('area')}
                            iconProps={{ iconName: 'LocationCircle' }}
                            styles={IconStyles}
                            type="button"
                        />
                        <span>Circle</span>
                    </div>

                    <div className='btn-container' >
                        <IconButton
                            onClick={(e) => props.handleToolbar('highlight')}
                            iconProps={{ iconName: 'RectangleShape' }}
                            styles={IconStyles}
                        />
                        <span>Rectangle</span>
                    </div>

                </div>

                <div style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', display: selection === 'insert' ? 'flex' : 'none' }}>
                    <div className='btn-container'>
                        <IconButton
                            onClick={(e) => props.handleToolbar('point')}
                            iconProps={{ iconName: 'FileImage' }}
                            styles={IconStyles}
                        />
                        <span>Image</span>
                    </div>

                    <div className='btn-container'>
                        <IconButton
                            onClick={(e) => props.handleToolbar('area')}
                            iconProps={{ iconName: 'InsertSignatureLine' }}
                            styles={IconStyles}
                            type="button"
                        />
                        <span>Signature</span>
                    </div>


                </div>
                {/* Commented Redo/Undo For now */}
                {/*undo*/}
                {/* <IconButton
                    iconProps={{ iconName: 'Undo' }}
                    styles={IconStyles}
                /> */}
                {/*redo*/}
                {/* <IconButton
                    iconProps={{ iconName: 'Redo' }}
                    styles={IconStyles}
                /> */}

            </div>
        </div>
    )
}
export const ShapesToolbar = (props) => {
    return (
        <div style={{ zIndex: 999, display: props.visible ? 'block' : 'none' }}>
            <div className="toolbar1">
                {/*Draw*/}
                <IconButton
                    iconProps={{ iconName: 'PenWorkspace' }}
                    styles={IconStyles}
                    className="pen"
                    title="Pen Tool"
                    data-tooltype="draw"
                />

                {/*Rectangle*/}
                <IconButton
                    iconProps={{ iconName: 'RectangleShape' }}
                    styles={IconStyles}
                />

                {/*Circle*/}
                <IconButton
                    iconProps={{ iconName: 'CircleRing' }}
                    styles={IconStyles}
                />

                {/*Pentagon*/}
                <IconButton
                    iconProps={{ iconName: 'Pentagon' }}
                    styles={IconStyles}
                />

                {/*Cloud*/}
                <IconButton
                    iconProps={{ iconName: 'Cloud' }}
                    styles={IconStyles}
                />

                {/*Line*/}
                <IconButton
                    iconProps={{ iconName: 'Line' }}
                    styles={IconStyles}
                />

                {/*Arrow*/}
                <IconButton
                    iconProps={{ iconName: 'ArrowTallUpRight' }}
                    styles={IconStyles}
                />

                <div className="spacer"></div>
                {/*undo*/}
                <IconButton
                    iconProps={{ iconName: 'Undo' }}
                    styles={IconStyles}
                />
                {/*redo*/}
                <IconButton
                    iconProps={{ iconName: 'Redo' }}
                    styles={IconStyles}
                />

                {/*Erase annot?*/}
                <a href="" className="clear" title="Clear">
                    <IconButton
                        iconProps={{ iconName: 'EraseTool' }}
                        styles={IconStyles}
                    />
                </a>
            </div>
        </div>
    )
}

export const InsertToolbar = (props) => {
    return (
        <div style={{ zIndex: 999, display: props.visible ? 'block' : 'none' }}>
            <div className="toolbar">
                {/*Signature*/}
                <IconButton
                    iconProps={{ iconName: 'InsertSignatureLine' }}
                    styles={IconStyles}
                    className="pen"
                    title="Pen Tool"
                    data-tooltype="draw"
                />

                {/*Add image*/}
                <IconButton
                    iconProps={{ iconName: 'Photo2' }}
                    styles={IconStyles}
                />

                {/*attach*/}
                <IconButton
                    iconProps={{ iconName: 'Attach' }}
                    styles={IconStyles}
                />

                {/*RubberStamp*/}
                <IconButton
                    iconProps={{ iconName: 'AddFavorite' }}
                    styles={IconStyles}
                />

                {/*Callout*/}
                <IconButton
                    iconProps={{ iconName: 'Childof' }}
                    styles={IconStyles}
                />

                <div className="spacer"></div>
                {/*undo*/}
                <IconButton
                    iconProps={{ iconName: 'Undo' }}
                    styles={IconStyles}
                />
                {/*redo*/}
                <IconButton
                    iconProps={{ iconName: 'Redo' }}
                    styles={IconStyles}
                />

                {/*Erase annot?*/}
                <a href="" className="clear" title="Clear">
                    <IconButton
                        iconProps={{ iconName: 'EraseTool' }}
                        styles={IconStyles}
                    />
                </a>
            </div>
        </div>
    )
}
