import React from 'react'
import { Button, Layout, Row, Col, Modal } from 'antd'
import SignatureCanvas from 'react-signature-canvas'
import ColorPicker from '../../../../../utilities/colorPicker'
import '../index.scss'
class AddSignature extends React.Component {

    constructor() {
        super()
        this.state = {
            color: '#000000'
        }
    }
    clear = () => {
        this.sigPad.clear()
    }

    trim = () => {
        this.setState({
            trimmedDataURL: this.sigPad.getTrimmedCanvas()
                .toDataURL('image/png')
        })
        console.log("TEST",)
        const base64ImageSignatude = this.sigPad.getTrimmedCanvas()
            .toDataURL('image/png')
        this.props.handleAddSignatureModal(base64ImageSignatude)
        this.sigPad.clear()

    }

    onChangeColor = (value) => {
        this.setState({ color: value })
    }

    render() {
        return (
            <Modal title="Add Signature" onCancel={() => this.props.onCancel()} visible={this.props.visible} width={820} footer={null}>
                <Row>
                    <Col span={24}>
                        <Layout.Content style={{ display: 'flex', alignItems: 'center', marginTop: 15, marginBottom: 15 }}>
                            <h4 style={{ margin: 0 }}>Select a Color: </h4>
                            <ColorPicker onChange={this.onChangeColor} value={this.state.color} />
                        </Layout.Content>
                    </Col>
                </Row>

                <Layout>
                    <Layout.Content>
                        <SignatureCanvas ref={(ref) => { this.sigPad = ref }} penColor={this.state.color} canvasProps={{ width: 820, height: 500, className: 'sigCanvas' }} />
                    </Layout.Content>
                </Layout>
                <Row style={{ width: '100%' }}>
                    <Col span={24} align='right'>
                        <Button style={{ margin: 4 }} onClick={() => this.clear()}>Clear</Button>
                        <Button style={{ margin: 4 }} onClick={() => this.trim()}>Add Signature</Button>
                    </Col>
                </Row>
            </Modal>
        )
    }
}

export default AddSignature
