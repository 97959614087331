import React from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash.omit'
import CommentBox from '../../../../../assets/images/commentbox.png'
import { fabric } from "fabric";


class Comments extends React.Component {

    static defaultProps = {
        opacity: 0,
    }

    componentDidMount() {
        // const fabric = window.fabric
        const { canvas } = this.props
        const options = omit({ ...this.props, cornerSize: 6 }, ['scale'])
        fabric.Image.fromURL(CommentBox, img => {
            img.scale(0.3)
            img.animate('opacity', 1, {
                duration: 500,
                onChange: canvas.renderAll.bind(canvas),
                easing: fabric.util.ease['easeInQuad']
            });
            canvas.add(img);

        }, {
            ...options,
            lockScalingX: true,
            lockScalingY: true,
            lockScalingFlip: true,
            hasControls: false,
            height: 180,
            width: 200,
            opacity: 0
        })
    }

    render() {
        return null
    }
}

export default Comments
