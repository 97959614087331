import React from 'react'
import PropTypes from 'prop-types'
import { fabric } from "fabric";


class Drawing extends React.Component {
    static propTypes = {
        penColor: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
    }

    static defaultProps = {
        width: 5,
        penColor: '#5DADE2',
        opacity: 0
    }

    // Cant save for now. will fix later.
    componentDidMount() {
        const canvas = new fabric.Canvas(this.props.canvas.upperCanvasEl)
        canvas.isDrawingMode = true;
        canvas.freeDrawingBrush.color = this.props.penColor;
        canvas.freeDrawingBrush.width = this.props.width;

        canvas.on('mouse:down', function (options) {
            const json = localStorage.getItem("data1");
            canvas.loadFromJSON(json, canvas.renderAll.bind(canvas));
            console.log('loaded')
        });

        canvas.on('mouse:up', () => {
            localStorage.setItem("data1", JSON.stringify(canvas));
            console.log('saved')
        })
    }

    render() {
        return null
    }
}

export default Drawing
