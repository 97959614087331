import React, { useLayoutEffect, useState } from 'react'
import PDFJSAnnotate from '../../../../../PDFJSAnnotate'
import { connect } from 'react-redux'
import { updateAnnotationsList } from '../../actions'
import { Spin } from 'antd'
import '../toolbar.css'
import '../pdfViewer.css'
import { LoadingOutlined } from '@ant-design/icons';
const { UI } = PDFJSAnnotate;

let renderedPages = [];
let okToRender = false;

const PdfView = (props) => {
    const [loading, setLoading] = useState(false)

    let scale = props.scale / 100; // 1.0
    let rotate = props.rotation
    const storage = JSON.parse(localStorage.getItem(`${props.pdfFile}/annotations`))

    PDFJSAnnotate.setStoreAdapter(new PDFJSAnnotate.LocalStoreAdapter());
    let NUM_PAGES = 0;

    PDFJS.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/1.4.162/pdf.worker.min.js'
    const url = props.pdfFile
    const documentId = url;
    let PAGE_HEIGHT;
    let RENDER_OPTIONS = {
        documentId,
        pdfDocument: null,
        scale: scale,
        rotate: storage ? (storage[0] && storage[0].rotate || 0) : rotate
    };

    const idViewer = `${props.id}-viewer`
    function render() {
        let viewer = document.getElementById(idViewer);
        try {
            PDFJS.getDocument(RENDER_OPTIONS.documentId).then((pdf) => {
                RENDER_OPTIONS.pdfDocument = pdf;
                viewer.innerHTML = '';
                NUM_PAGES = pdf.pdfInfo.numPages;
                props.setTotalPages(NUM_PAGES)
                for (let i = 0; i < NUM_PAGES; i++) {
                    let page = UI.createPage(i + 1);
                    viewer.appendChild(page);
                }
                UI.renderPage(1, RENDER_OPTIONS, idViewer).then(([pdfPage, annotations]) => {
                    let viewport = pdfPage.getViewport(RENDER_OPTIONS.scale, RENDER_OPTIONS.rotate);
                    PAGE_HEIGHT = viewport.height;
                });
            });
        } catch (error) { }
    }

    useLayoutEffect(() => {
        let viewer = document.getElementById(idViewer);
        if (!viewer) return
        try {
            setLoading(true)
            document.getElementById(props.id).scrollTo(0, 0)
            renderedPages = [];
            okToRender = false;
            const nodes = document.getElementById(idViewer).childNodes
            for (let index = 0; index < nodes.length; index++) {
                const element = nodes[index];
                element.setAttribute('data-loaded', 'false')
                element.style = 'visibility: hidden;'
            }

            PDFJS.getDocument(RENDER_OPTIONS.documentId).then((pdf) => {
                RENDER_OPTIONS.pdfDocument = pdf;
                NUM_PAGES = pdf.pdfInfo.numPages;
                for (let index = 0; index <= NUM_PAGES; index++) {
                    setTimeout(function () {
                        UI.renderPage(index, RENDER_OPTIONS, idViewer);
                        setLoading(false);
                    }, 1000);
                }
            });

            render()
        } catch (error) { }
    }, [props.scale, props.rotation])

    return (
        <div>
            {
                loading &&
                <div className='pdf-overlay'>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 44, fontWeight: 'bold' }} spin />} />
                </div>
            }
            <div>
                <div id={idViewer} className="pdfViewer" style={{ userSelect: props.viewControl === 'view' && 'none' }}></div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        onUpdateAnnotationsList: (annotations) => dispatch(updateAnnotationsList(annotations))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PdfView)

