import sampleVids from '../../../../assets/videos/samplevids.mp4'
import React, { useLayoutEffect, useEffect, useState, useRef, Fragment } from 'react';
import { Row, Col, Layout, Radio, Button, Input, notification, Form, Modal, Card } from 'antd'
import { connect } from 'react-redux'
import AnnotationView from './annotationView/index'
import './index.scss'
import ReactPlayer from 'react-player'
import UserActions from './userActions'
import Rect from './customCanvas/rect'
import Circle from './customCanvas/circle'
import Line from './customCanvas/line'
import Image from './customCanvas/image'
import TextNormal from './customCanvas/textNormal'
import TextUnderline from './customCanvas/textUnderline'
import TextBold from './customCanvas/textBold'
import CommentBox from './customCanvas/comment'
import Drawing from './customCanvas/drawing'
import Signature from './customCanvas/signature'
import DesignCanvas from './customCanvas/designCanvas'
import 'fabric-webpack'
import Duration from './duration'
import Timeline from './timeline'
import TimelineDownload from './timelineDownload'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause, faVolumeUp, faArrowsAlt, faExpand } from '@fortawesome/free-solid-svg-icons'
import AddSignatureModal from './modals/addSignature'
import AddImageModal from './modals/addImage'
import { v4 as uuidv4 } from 'uuid';
class Video extends React.Component {
    constructor(props) {
        super()
        this.state = {
            assetUrl: '',
            refDesignCanvas: undefined,
            refDesignCanvasEdit: undefined,
            url: null,
            pip: false,
            playing: false,
            controls: false,
            light: false,
            volume: 0.8,
            muted: false,
            played: 0,
            playedSeconds: 0,
            loaded: 0,
            duration: 0,
            playbackRate: 1.0,
            loop: false,
            actionType: 'view',
            selectedShapeToAdd: '',
            canvasObjectArray: [],
            signatureModalShow: false,
            imageModalShow: false,
            addCanvasEnabled: false,
            videoUrl: '',
            shapesSettings: {
                stroke: '',
                strokeWidth: '',
                fill: '',
                fontWeight: '',
                penColor: '',
                width: 0,
                url: ''
            },
            data: []
        }
        this.canvasRendererInPlaytime = this.canvasRendererInPlaytime.bind(this)
        this.handleAddCanvas = this.handleAddCanvas.bind(this)
        this.handleAddSignatureModal = this.handleAddSignatureModal.bind(this)
        this.handleAddImageModal = this.handleAddImageModal.bind(this)
        this.canvasRendererInPlaytime = this.canvasRendererInPlaytime.bind(this)
        this.handleDeleteCanvas = this.handleDeleteCanvas.bind(this)
        this.handleDownload = this.handleDownload.bind(this)
        // this.refDesignCanvas = React.createRef();
    }


    componentDidMount() {
        localStorage.setItem(`/author/url`, 'https://pickaface.net/gallery/avatar/20130306_051313_4564_Dev.png')
        localStorage.setItem(`/author/id`, 1)
        localStorage.setItem(`/author/name`, 'Guest Test')

        const { videoUrl } = this.state
        const videoAnnotations = localStorage.getItem(`${videoUrl}/video/annotations`)
        this.setState({ data: videoAnnotations ? JSON.parse(videoAnnotations) : [] })


        var parametersMap = {
            file: ''
        };
        const queryString = window.parent.location.search;
        const queries = queryString.split("?");
        queries.forEach(query => {
            const parameters = query.split("&");
            for (var parameter of parameters) {
                const [key, value] = parameter.split("=");
                parametersMap[key] = value;
            }
        });
        if (parametersMap.file) {
            this.setState({ assetUrl: decodeURIComponent(parametersMap.file) })
        } else {
            this.setState({ assetUrl: sampleVids })
        }

    }


    ref = player => {
        this.player = player
    }

    refDesign = (e) => {
        return this.setState({ refDesignCanvas: e })
    }

    refDownload = (e) => {
        return this.setState({ refDownload: e })
    }

    refDesignEdit = (e) => {
        return this.setState({ refDesignCanvasEdit: e })
    }

    handleProgress = state => {
        // We only want to update time slider if we are not currently seeking
        if (!this.state.seeking) {
            this.setState(state)
        }
    }

    handleSeekMouseUp = e => {
        this.setState({ seeking: false })
        this.player.seekTo(parseFloat(e.target.value))
    }

    handleSeekChange = e => {
        this.setState({ played: parseFloat(e.target.value) })
    }

    handleUpdateSeekChange = value => {
        this.setState({ played: parseFloat(value / this.state.duration) })
        this.player.seekTo(parseFloat(value / this.state.duration))
    }

    // Try to make a magic with this function
    canvasRendererInPlaytime = () => {
        // Note: canvasRendererInPlaytime function always run in every seconds.

        const { refDesignCanvas } = this.state
        // an array to store batch of canvas.
        const canvasArrayItem = []

        // get all the data which have match in current playtime.
        const filteredByTime = this.state.data.filter((item, index) =>
            this.state.playedSeconds >= item.startTime &&
            this.state.playedSeconds <= item.endTime
        )

        // remove all the canvas except to those data that are
        // matched with the current playtime.
        const filteredIds = filteredByTime.map(data => { return data.uuid })
        refDesignCanvas && refDesignCanvas.removeAllCanvasExceptOnTheseIds(filteredIds)

        // do not run if filter array is 0
        if (filteredByTime.length !== 0) {
            // map an array then push to canvas array item and then ready to show.
            filteredByTime.map((item, index) => {

                // a checker function that check if the current item is active at playtime.
                // this function is made to (AVOID DUPLICATION) entry in the playtime, "because"
                // it is running every seconds. if item is active terminate this loop using return.
                if (refDesignCanvas && refDesignCanvas.checkIfCanvasIdActiveInPlaytime(item.uuid)) return

                // switch case to prepare the component that are needed to display.
                switch (item.type) {
                    case 'line':
                        return canvasArrayItem.push(<Line key={index} {...item} />)
                    case 'textNormal':
                        return canvasArrayItem.push(<TextNormal key={index} {...item} />)
                    case 'textUnderline':
                        return canvasArrayItem.push(<TextUnderline key={index} {...item} />)
                    case 'textBold':
                        return canvasArrayItem.push(<TextBold key={index} {...item} />)
                    case 'rect':
                        return canvasArrayItem.push(<Rect key={index} {...item} />)
                    case 'circle':
                        return canvasArrayItem.push(<Circle key={index}{...item} />)
                    case 'image':
                        return canvasArrayItem.push(<Image key={index} url={item.src} {...item} />)
                    case 'comment':
                        return canvasArrayItem.push(<CommentBox key={index} {...item} />)
                    case 'signature':
                        return canvasArrayItem.push(<Signature key={index} url={item.src} {...item} />)
                    default:
                        break;
                }
            })
        }

        return canvasArrayItem || null
    }

    handleAddCanvas(values) {
        const { canvasObjectArray, videoUrl } = this.state
        const authorAvatarUrl = localStorage.getItem('/author/url')
        const authorId = localStorage.getItem('/author/id')
        const authorName = localStorage.getItem('/author/name')
        canvasObjectArray.map((item, index) => {
            // do not update canvas that has uuid
            // canvas that has uuid means it is existing and does not need to update,
            // once we allow to update an existing canvas it will overrirde its settings like
            // start time and end time and it will be the same with the new add canvas.
            if (item["uuid"]) return

            canvasObjectArray[index].uuid = uuidv4()
            canvasObjectArray[index].authorName = authorName
            canvasObjectArray[index].authorId = authorId
            canvasObjectArray[index].authorAvatarUrl = authorAvatarUrl
            canvasObjectArray[index].startTime = values.startTime
            canvasObjectArray[index].endTime = values.endTime
            if (item.type === 'comment') {
                canvasObjectArray[index].comment = values.comment
            }
            canvasObjectArray[index].createdAt = new Date()
            // canvasObjectArray[index].type = values.type
            this.state.data.push(canvasObjectArray[index])
        })
        this.setState({ data: this.state.data, addCanvasEnabled: false })
        localStorage.setItem(`${videoUrl}/video/annotations`, JSON.stringify(this.state.data))
    }

    handleDuration = (duration) => {
        this.setState({ duration })
    }

    handleVolumeChange = e => {
        this.setState({ volume: parseFloat(e.target.value) })
    }

    handleAddSignatureModal(base64SignatureImage) {
        if (base64SignatureImage) {
            this.setState({ signatureModalShow: !this.state.signatureModalShow, shapesSettings: { url: base64SignatureImage }, addCanvasEnabled: true, selectedShapeToAdd: 'signature' })
        } else {
            this.setState({ signatureModalShow: !this.state.signatureModalShow })
        }
    }

    handleAddImageModal(base64SignatureImage) {
        if (base64SignatureImage) {
            this.setState({ imageModalShow: !this.state.imageModalShow, shapesSettings: { url: base64SignatureImage }, addCanvasEnabled: true, selectedShapeToAdd: 'image' })
        } else {
            this.setState({ imageModalShow: !this.state.imageModalShow })
        }
    }

    handleDeleteCanvas() {
        const { refDesignCanvas, refDesignCanvasEdit } = this.state
        const canvas = refDesignCanvasEdit && refDesignCanvasEdit.state && refDesignCanvasEdit.state.canvas

        if (refDesignCanvas) {
            // const { uuid } = refDesignCanvas.getSelectedCanvasObject()
            if (refDesignCanvas.getSelectedCanvasObject() && refDesignCanvas.getSelectedCanvasObject().uuid) {
                const selectedObjectId = refDesignCanvas.getSelectedCanvasObject().uuid
                console.log("selectedObjectId", selectedObjectId)
                this.state.refDesignCanvas.deleteSelectedCanvas()
                const filteredData = this.state.data.filter(item => item.uuid !== selectedObjectId)
                localStorage.setItem(`${this.state.videoUrl}/video/annotations`, JSON.stringify(filteredData))
                this.setState({ data: filteredData })
            } else {
                canvas.remove(refDesignCanvasEdit.state.canvas.getActiveObject())
            }
        }

        this.setState({ addCanvasEnabled: false })
    }

    handleDownload() {
        this.state.refDownload.download(this.state.data)
    }

    render() {
        const { url, playing, controls, light, volume, muted, loop, played, playedSeconds, loaded, duration, playbackRate, pip } = this.state
        return (
            <Layout className='video-container' >
                <Row>
                    <Col xs={24} sm={24} md={20} lg={20} >
                        <UserActions
                            handleDownload={this.handleDownload}
                            handleAddSignatureModal={this.handleAddSignatureModal}
                            handleAddImageModal={this.handleAddImageModal}
                            onDeleteCanvas={this.handleDeleteCanvas}
                            onChange={(e) => { this.setState({ actionType: e }) }}
                            getSelectedShapeToAdd={(e, shapesSettings) => this.setState({ selectedShapeToAdd: e, addCanvasEnabled: true, shapesSettings })}
                        />
                        <Row>
                            <Col xs={16} sm={16} md={16} lg={16}>
                                <div className='video-player'>
                                    <div style={{ position: 'relative', margin: 20, overflow: 'auto' }}>
                                        <div style={{ position: 'absolute', top: 0, zIndex: 1 }} >
                                            <DesignCanvas ref={this.refDesign} setAdditionalCanvas={(e) => this.setState({ canvasObjectArray: e.objects })} >
                                                {this.canvasRendererInPlaytime()}
                                            </DesignCanvas>
                                        </div>
                                        {
                                            this.state.addCanvasEnabled === true &&
                                            <div style={{ position: 'absolute', top: 0, zIndex: 1 }} >
                                                <DesignCanvas ref={this.refDesignEdit} setAdditionalCanvas={(e) => this.setState({ canvasObjectArray: e.objects })}>
                                                    {/* ANNOTATIONS */}
                                                    {this.state.addCanvasEnabled === true && this.state.selectedShapeToAdd === 'drawing' ?
                                                        <Drawing width={100} height={100} top={20} left={200} penColor={this.state.shapesSettings.penColor} width={this.state.shapesSettings.width} type="drawing" /> : null
                                                    }
                                                    {this.state.addCanvasEnabled === true && this.state.selectedShapeToAdd === 'textNormal' ?
                                                        <TextNormal width={100} height={100} top={20} left={200} fill={this.state.shapesSettings.fill} fontSize={this.state.shapesSettings.fontSize} type="textNormal" /> : null
                                                    }
                                                    {this.state.addCanvasEnabled === true && this.state.selectedShapeToAdd === 'textUnderline' ?
                                                        <TextUnderline width={100} height={100} top={20} left={200} fill={this.state.shapesSettings.fill} fontSize={this.state.shapesSettings.fontSize} type="textUnderline" /> : null
                                                    }
                                                    {this.state.addCanvasEnabled === true && this.state.selectedShapeToAdd === 'textBold' ?
                                                        <TextBold width={100} height={100} top={20} left={200} fill={this.state.shapesSettings.fill} fontSize={this.state.shapesSettings.fontSize} type="textBold" /> : null
                                                    }

                                                    {/* SHAPES */}
                                                    {this.state.addCanvasEnabled === true && this.state.selectedShapeToAdd === 'line' ?
                                                        <Line width={100} height={100} top={300} left={400}
                                                            // stroke={this.state.shapesSettings.stroke}
                                                            // fill={this.state.shapesSettings.fill}
                                                            // strokeWidth={this.state.shapesSettings.strokeWidth = 15}
                                                            type="line"
                                                        /> : null
                                                    }
                                                    {this.state.addCanvasEnabled === true && this.state.selectedShapeToAdd === 'circle' ?
                                                        <Circle height={500} top={220} left={380} radius={20}
                                                            // fill={this.state.shapesSettings.fill}
                                                            type="circle" /> : null
                                                    }
                                                    {this.state.addCanvasEnabled === true && this.state.selectedShapeToAdd === 'comment' ?
                                                        <CommentBox type="comment" /> : null
                                                    }
                                                    {this.state.addCanvasEnabled === true && this.state.selectedShapeToAdd === 'rect' ?
                                                        <Rect width={150} height={150} top={220} left={380}
                                                            // stroke={this.state.shapesSettings.stroke}
                                                            //  fill={this.state.shapesSettings.fill}
                                                            type="rect" /> : null
                                                    }

                                                    {/* INSERTS */}
                                                    {this.state.addCanvasEnabled === true && this.state.selectedShapeToAdd === 'signature' ?
                                                        <Signature top={20} left={200} url={this.state.shapesSettings.url} scale={1} type="signature" /> : null
                                                    }

                                                    {this.state.addCanvasEnabled === true && this.state.selectedShapeToAdd === 'image' ?
                                                        <Image top={20} left={200} url={this.state.shapesSettings.url} scale={1} type="image" /> : null
                                                    }
                                                </DesignCanvas>
                                            </div>

                                        }
                                        <ReactPlayer
                                            url={this.state.assetUrl}
                                            playing={playing}
                                            ref={this.ref}
                                            onSeek={e => { }}
                                            controls={false}
                                            volume={volume}
                                            onProgress={this.handleProgress}
                                            onDuration={this.handleDuration}
                                            width={940}
                                            height={660}
                                        // style={{ position: 'absolute', top: 0, zIndex: 1 }}
                                        />
                                        <div className='video-controls' >
                                            <div className='play-pause-btn'>
                                                <FontAwesomeIcon icon={playing ? faPause : faPlay} onClick={() => this.setState({ playing: !this.state.playing })} />
                                            </div>
                                            <div className='volume'>
                                                <FontAwesomeIcon className='icon' icon={faVolumeUp} />
                                                <input type='range' min={0} max={1} step='any' value={volume} onChange={this.handleVolumeChange} />
                                            </div>
                                            <div className='seeker-time'>
                                                <span><Duration seconds={duration * played} /> / <Duration seconds={duration} />  </span>
                                            </div>
                                            <div className='seeker-range'>
                                                <input
                                                    type='range' min={0} max={0.999999} step='any'
                                                    value={played}
                                                    onMouseDown={this.handleSeekMouseDown}
                                                    onChange={this.handleSeekChange}
                                                    onMouseUp={this.handleSeekMouseUp}
                                                />
                                            </div>
                                            <div className='screen-size'>
                                                <FontAwesomeIcon icon={faExpand} />
                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={24} >
                                        <Timeline
                                            assetUrl={this.state.assetUrl}
                                            handleUpdateSeekChange={this.handleUpdateSeekChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={8} sm={8} md={8} lg={8} >
                                <Layout.Content style={{ overflow: 'auto', height: '90vh' }}>
                                    <Row>
                                        <Col lg={24}></Col>
                                    </Row>
                                    <Row>
                                        <Col md={24} lg={24} sm={24} xs={24}>
                                            {
                                                this.state.addCanvasEnabled &&
                                                <TimeRangeBoxEditor
                                                    handleAddCanvas={this.handleAddCanvas}
                                                    type={this.state.selectedShapeToAdd}
                                                    played={duration * played}
                                                />
                                            }
                                        </Col>
                                    </Row>
                                    {/* <VideoIndexer
                                        // assetVersionId={assetVersionId}
                                        // assetFileExtension={assetFileExtension}
                                        assetUrl={this.state.assetUrl}
                                        actionType={actionType}
                                        addCanvasEnabled={this.state.addCanvasEnabled}
                                    /> */}
                                </Layout.Content>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} sm={24} md={4} lg={4}>
                        <div style={{ height: '90vh' }}>
                            <AnnotationView
                                data={this.state.data}
                                setData={(e) => this.setState({ data: e })}
                                playedSeconds={playedSeconds}
                                handleUpdateSeekChange={this.handleUpdateSeekChange}
                                videoUrl={this.state.videoUrl}
                                refDesignCanvas={this.state.refDesignCanvas}
                            />
                        </div>

                    </Col>
                </Row>

                <AddSignatureModal
                    visible={this.state.signatureModalShow}
                    handleAddSignatureModal={this.handleAddSignatureModal}
                    onCancel={() => this.setState({ signatureModalShow: false })}
                />
                <AddImageModal
                    visible={this.state.imageModalShow}
                    handleAddImageModal={this.handleAddImageModal}
                    onCancel={() => this.setState({ imageModalShow: false })}
                />
                <TimelineDownload
                    ref={this.refDownload}
                    assetUrl={this.state.assetUrl}
                    data={this.state.data}
                />
            </Layout >
        );
    }
}

const TimeRangeBoxEditor = (props) => {
    const [successfullyAdded, setSuccessfullyAdded] = useState(false)
    const [startTime, setStartTime] = useState(0)
    const [endTime, setEndTime] = useState(0)
    const [comment, setComment] = useState('')
    const [errorList, setErrorList] = useState([])

    function handleSubmit() {
        setSuccessfullyAdded(false)
        const errorData = []
        const submitData = {
            type: props.type,
            startTime,
            endTime,
            comment
        }

        if (props.type === '') {
            errorData.push('Unable to proceed in saving, you should have select first an annotations, shapes and/or insert.')
        }

        if (startTime === 0) {
            errorData.push('You have to select start time.')
            errorData.push('Your Start time must be greater than zero.')
        }

        if (endTime === 0) {
            errorData.push('You have to select end time.')
        }

        if (startTime > endTime) {
            errorData.push('Start time is greater than "OR" equal to end time. Seriously?')
        }

        if (errorData.length === 0) {
            setSuccessfullyAdded(true)
            setStartTime(0)
            setEndTime(0)
            setComment('')
            setErrorList([])
            props.handleAddCanvas(submitData)
            notification.success({
                message: ``,
                description: 'Successfully Saved'
            });
        } else {
            setErrorList(errorData)
            notification.error({
                message: `Error`,
                description:
                    errorData.map((error, index) => {
                        return (
                            <p key={index}>** {error}</p>
                        )
                    })
            });
        }
        // props.handleAddCanvas(submitData)

    }
    return (
        <Card>
            <h3>Add Annotations</h3>
            <Row>
                <Col md={24} lg={24} xs={24} sm={24}>
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <div>
                            <h1>Time (in seconds)</h1>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <h1 style={{ marginTop: 6 }}>Start: </h1> &nbsp;
                                <div style={{ width: 50 }}>
                                    <Duration seconds={startTime !== 0 ? startTime : props.played} />
                                </div>
                                <Input value={startTime !== 0 ? startTime : props.played} style={{ display: 'none' }} />
                                {
                                    startTime === 0 ?
                                        <Button onClick={() => setStartTime(props.played)}>Set</Button> :
                                        <Button onClick={() => setStartTime(0)}>Cancel</Button>
                                }
                            </div>
                        </div>
                        <div>
                            <h1>&nbsp;</h1>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <h1 style={{ marginTop: 6 }}>End: </h1> &nbsp;
                                <div style={{ width: 50 }}>
                                    <Duration seconds={(startTime === 0) ? 0 : (endTime !== 0) ? endTime : props.played} />
                                </div>
                                <Input value={(startTime === 0) ? 0 : (endTime !== 0) ? endTime : props.played} style={{ display: 'none' }} />
                                {
                                    endTime === 0 ?
                                        <Button onClick={() => setEndTime(props.played)}>Set</Button> :
                                        <Button onClick={() => setEndTime(0)}>Cancel</Button>
                                }
                            </div>
                        </div>
                    </div>

                </Col>
                {
                    props.type === 'comment' &&
                    <Col md={24} lg={24} xs={24} sm={24}>
                        <h1>Comment</h1>
                        <Input.TextArea value={comment} onChange={(e) => setComment(e.target.value)} rows={2} />
                    </Col>
                }
            </Row>
            {/* {errorList.length !== 0 &&
                <Row>
                    <Col md={24} lg={24} xs={24} sm={24}>
                        <div style={{ border: '1px solid #bf6363', borderRadius: 5, padding: 10, margin: 10 }}>
                            <h1 style={{ color: '#bf6363' }}>Note:</h1>
                            {
                                errorList.map(error => {
                                    return (
                                        <p style={{ color: '#bf6363' }}>*** {error}</p>
                                    )
                                })
                            }
                        </div>
                    </Col>
                </Row>
            }

            {successfullyAdded &&
                <Row>
                    <Col md={24} lg={24} xs={24} sm={24}>
                        <div style={{ border: '1px solid #27613f', borderRadius: 5, padding: 10, margin: 10 }}>
                            <h1 style={{ color: '#27613f', margin: 0 }}>Successfully Added</h1>
                        </div>
                    </Col>
                </Row>
            } */}
            <Row>
                <Col md={24} lg={24} xs={24} sm={24}>
                    <Button style={{ marginTop: 20 }} onClick={() => handleSubmit()} >Save</Button>
                </Col>
            </Row>

        </Card>
    )
}

function mapStateToProps(state) {
    return {
        // annotationData: state.client.pdf.annotationData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        // onSample: (data) => dispatch(sample(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Video)

