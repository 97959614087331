import PDFJSAnnotate from '../PDFJSAnnotate';
import appendChild from '../render/appendChild';
import {
  BORDER_COLOR,
  disableUserSelect,
  enableUserSelect,
  findSVGAtPoint,
  getMetadata,
  getOffset,
  scaleDown,
  scaleUp
} from './utils';

let _enabled = false;
let _type;
let _color;
let _rotate;
let _scale;
let overlay;
let originY;
let originX;
let _mobile

/**
 * Get the current window selection as rects
 *
 * @return {Array} An Array of rects
 */
function getSelectionRects() {
  try {
    let selection = window.getSelection();
    let range = selection.getRangeAt(0);
    let rects = range.getClientRects();

    if (rects.length > 0 &&
      rects[0].width > 0 &&
      rects[0].height > 0) {
      return rects;
    }
  } catch (e) { }

  return null;
}

/**
 * Handle document.mousedown event
 *
 * @param {Event} e The DOM event to handle
 */
function handleDocumentMousedown(e) {
  let svg;
  if (_type !== 'area' || !(svg = findSVGAtPoint(e.clientX, e.clientY))) {
    return;
  }

  let rect = svg.getBoundingClientRect();
  originY = e.clientY;
  originX = e.clientX;

  overlay = document.createElement('div');
  overlay.style.position = 'absolute';
  overlay.style.top = `${originY - rect.top}px`;
  overlay.style.left = `${originX - rect.left}px`;
  overlay.style.border = `3px solid ${BORDER_COLOR}`;
  overlay.style.borderRadius = '3px';
  svg.parentNode.appendChild(overlay);

  document.addEventListener('mousemove', handleDocumentMousemove);
  disableUserSelect();
}

function handleTouchStart(e) {
  originY = e.changedTouches[0].clientY;
  originX = e.changedTouches[0].clientX;
}

function handleTouchMove(e) {
  e.preventDefault()
}

function handleTouchEnd(e) {
  let svg = findSVGAtPoint(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
  if (!svg) return
  let rect = svg.getBoundingClientRect();

  if (_type === 'area') {
    overlay = document.createElement('div');
    overlay.style.position = 'absolute';
    overlay.style.top = `${originY - rect.top}px`;
    overlay.style.left = `${originX - rect.left}px`;
    overlay.style.border = `3px solid ${BORDER_COLOR}`;
    overlay.style.borderRadius = '3px';
    if (originX + (e.changedTouches[0].clientX - originX) < rect.right) {
      overlay.style.width = `${e.changedTouches[0].clientX - originX}px`;
    }

    if (originY + (e.changedTouches[0].clientY - originY) < rect.bottom) {
      overlay.style.height = `${e.changedTouches[0].clientY - originY}px`;
    }
    svg.parentNode.appendChild(overlay);
  }

  let rects;
  if (_type !== 'area' && (rects = e.target.getClientRects())) {
    saveRect(_type, [...rects].map((r) => {
      return {
        top: r.top,
        left: originX,
        width: e.changedTouches[0].clientX - originX,
        height: r.height
      };
    }));
  } else if (_type === 'area' && overlay) {
    svg = overlay.parentNode.querySelector('svg.annotationLayer');

    saveRect(_type, [{
      top: parseInt(overlay.style.top, 10) + rect.top,
      left: parseInt(overlay.style.left, 10) + rect.left,
      width: parseInt(overlay.style.width, 10),
      height: parseInt(overlay.style.height, 10)
    }]);

    overlay.parentNode.removeChild(overlay);
    overlay = null;

    enableUserSelect();
  }
}

/**
 * Handle document.mousemove event
 *
 * @param {Event} e The DOM event to handle
 */
function handleDocumentMousemove(e) {
  let svg = overlay.parentNode.querySelector('svg.annotationLayer');
  let rect = svg.getBoundingClientRect();

  if (originX + (e.clientX - originX) < rect.right) {
    overlay.style.width = `${e.clientX - originX}px`;
  }

  if (originY + (e.clientY - originY) < rect.bottom) {
    overlay.style.height = `${e.clientY - originY}px`;
  }
}

/**
 * Handle document.mouseup event
 *
 * @param {Event} e The DOM event to handle
 */
function handleDocumentMouseup(e) {
  let rects;
  if (_type !== 'area' && (rects = getSelectionRects())) {
    let svg = findSVGAtPoint(rects[0].left, rects[0].top);
    saveRect(_type, [...rects].map((r) => {
      return {
        top: r.top,
        left: r.left,
        width: r.width,
        height: r.height
      };
    }));
  } else if (_type === 'area' && overlay) {
    let svg = overlay.parentNode.querySelector('svg.annotationLayer');
    let rect = svg.getBoundingClientRect();


    saveRect(_type, [{
      top: parseInt(overlay.style.top, 10) + rect.top,
      left: parseInt(overlay.style.left, 10) + rect.left,
      width: parseInt(overlay.style.width, 10),
      height: parseInt(overlay.style.height, 10)
    }]);

    overlay.parentNode.removeChild(overlay);
    overlay = null;

    document.removeEventListener('mousemove', handleDocumentMousemove);
    enableUserSelect();
  }
}

/**
 * Handle document.keyup event
 *
 * @param {Event} e The DOM event to handle
 */
function handleDocumentKeyup(e) {
  // Cancel rect if Esc is pressed
  if (e.keyCode === 27) {
    let selection = window.getSelection();
    selection.removeAllRanges();
    if (overlay && overlay.parentNode) {
      overlay.parentNode.removeChild(overlay);
      overlay = null;
      document.removeEventListener('mousemove', handleDocumentMousemove);
      document.removeEventListener('touchmove', handleDocumentMousemove);
    }
  }
}

/**
 * Save a rect annotation
 *
 * @param {String} type The type of rect (area, highlight, strikeout)
 * @param {Array} rects The rects to use for annotation
 * @param {String} color The color of the rects
 */
function saveRect(type, rects, color) {
  let x;
  let y;
  x = rects[0].left;
  y = rects[0].top;
  let svg = findSVGAtPoint(x, y);
  let node;
  let annotation;

  if (!svg) {
    return;
  }

  let boundingRect = svg.getBoundingClientRect();


  if (!color) {
    if (type === 'strikeout') {
      color = 'FF0000';
    }
  }

  if (type === 'highlight') {
    if (!_color) {
      color = 'FFFF00';
    } else {
      color = _color;
    }
  }

  // Initialize the annotation
  annotation = {
    type,
    color,
    rectangles: [...rects].map((r) => {
      let offset = 0;

      if (type === 'strikeout') {
        offset = r.height / 2;
      }

      let x = r.left - boundingRect.left;
      let y = (r.top + offset) - boundingRect.top;
      let width = r.width;
      let height = r.height;

      switch (_rotate) {
        case 90:
          offset = type === 'strikeout' ? (r.width / 2) : 0;
          x = Math.abs(r.top - boundingRect.top);
          y = ((boundingRect.width + offset) - r.width) - (r.left - boundingRect.x)
          width = r.height;
          height = r.width;
          break;

        case 180:
          offset = type === 'strikeout' ? (r.height / 2) : 0;
          x = Math.abs((r.left - boundingRect.left) - (boundingRect.width - r.width));
          y = ((boundingRect.height + offset) - r.height) - Math.abs((r.top - boundingRect.top));
          width = r.width;
          height = r.height;
          break;

        case 270:
          offset = type === 'strikeout' ? (r.width / 2) : 0;
          x = (boundingRect.height - r.height) - Math.abs(r.top - boundingRect.top)
          y = y = Math.abs((boundingRect.left - offset) - r.left)
          width = r.height;
          height = r.width;
          break;
      }

      return scaleDown(svg, {
        y: y,
        x: x,
        width: width,
        height: height
      });
    }).filter((r) => r.width > 0 && r.height > 0 && r.x > -1 && r.y > -1),

    content: getSelection()
  };

  function getSelection() {
    if (type === 'highlight' || type === 'strikeout') {
      return window.getSelection().toString()
    }
    return null
  }

  // Short circuit if no rectangles exist
  if (annotation.rectangles.length === 0) {
    return;
  }

  // Special treatment for area as it only supports a single rect
  if (type === 'area') {
    let rect = annotation.rectangles[0];
    delete annotation.rectangles;
    annotation.x = rect.x;
    annotation.y = rect.y;
    annotation.width = rect.width;
    annotation.height = rect.height;
  }

  let { documentId, pageNumber } = getMetadata(svg);

  // Add the annotation
  PDFJSAnnotate.getStoreAdapter().addAnnotation(documentId, pageNumber, annotation)
    .then((annotation) => {
      appendChild(svg, annotation);
    });
  if (window.getSelection) {
    if (window.getSelection().empty) {  // Chrome
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {  // Firefox
      window.getSelection().removeAllRanges();
    }
  } else if (document.selection) {  // IE?
    document.selection.empty();
  }
}

/**
 * Enable rect behavior
 */
export function enableRect(type, color, rotate, scale, mobile) {
  _type = type;
  _color = color;
  _rotate = rotate
  _scale = scale
  _mobile = mobile

  if (_enabled) { return; }

  _enabled = true;
  // if(!_mobile){
  document.addEventListener('mouseup', handleDocumentMouseup);
  document.addEventListener('mousedown', handleDocumentMousedown);
  document.addEventListener('keyup', handleDocumentKeyup);
  // }
  // else {
  document.addEventListener('touchstart', handleTouchStart);
  document.addEventListener('touchend', handleTouchEnd);
  // }
}

/**
 * Disable rect behavior
 */
export function disableRect() {
  if (!_enabled) { return; }

  _enabled = false;
  // if(!_mobile){
  document.removeEventListener('mouseup', handleDocumentMouseup);
  document.removeEventListener('mousedown', handleDocumentMousedown);
  document.removeEventListener('keyup', handleDocumentKeyup);
  // }
  // else {
  document.removeEventListener('touchstart', handleTouchStart);
  document.removeEventListener('touchend', handleTouchEnd);
  // }
}

export function isEnableRect() {
  return _enabled
}
