import React, { Fragment } from 'react'
import './index.scss'
import Timeframe from './timeframe'

class Timeline extends React.Component {
    constructor() {
        super()
        this.state = {
            renderImg: [],
            url: undefined
        }
        this.getVideoImage = this.getVideoImage.bind(this)
        this.showImageAt = this.showImageAt.bind(this)
        this.listenToGrab = this.listenToGrab.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.assetUrl !== this.props.assetUrl) {
            console.log("YEAH ITS ME")
            this.setState({ url: this.props.assetUrl })
            this.showImageAt(0, this.props.assetUrl)
            this.listenToGrab()
        }
    }

    listenToGrab() {
        const slider = document.querySelector('#olFrames');
        let isDown = false;
        let startX;
        let scrollLeft;

        slider.addEventListener('mousedown', (e) => {
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener('mouseleave', () => {
            isDown = false;
            slider.classList.remove('active');
        });
        slider.addEventListener('mouseup', () => {
            isDown = false;
            slider.classList.remove('active');
        });
        slider.addEventListener('mousemove', (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 2; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
            console.log(walk);
        });
    }

    getVideoImage(path, secs, callback) {
        var me = this, video = document.createElement('video');
        video.onloadedmetadata = function () {
            if ('function' === typeof secs) {
                secs = secs(this.duration);
            }
            this.currentTime = Math.min(Math.max(0, (secs < 0 ? this.duration : 0) + secs), this.duration);
        };
        video.onseeked = function (e) {
            var img = new Image();
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            canvas.height = video.videoHeight;
            canvas.width = video.videoWidth;
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            img.crossOrigin = 'anonymous'
            img.src = canvas && canvas.toDataURL();
            img.dataset.seconds = secs
            img.onclick = () => me.props.handleUpdateSeekChange(secs);
            callback.call(me, img, this.currentTime, e);
        };
        video.onerror = function (e) {
            callback.call(me, undefined, undefined, e);
        };
        video.setAttribute('crossorigin', 'anonymous');
        video.src = path;
    }

    showImageAt(secs, url) {
        var duration;
        this.getVideoImage(
            url,
            function (totalTime) {
                duration = totalTime;
                return secs;
            },
            function (img, secs, event) {
                try {
                    if (event.type == 'seeked') {
                        var li = document.createElement('li');
                        li.appendChild(img);
                        document.getElementById('olFrames').appendChild(li);
                        if (duration >= ++secs) {
                            this.showImageAt(secs, url);
                        };
                    }
                } catch (error) {
                    console.log("ERROR timeline.js", error)
                }

            }
        );
    }

    componentDidMount() {
        // this.showImageAt(0)
        // this.listenToGrab()
    }

    render() {
        return (
            <div id='timeline' className='timeline-edit'>
                <ol id="olFrames"></ol>
            </div>
        )
    }
}

export default Timeline
