import React, { useEffect, useState } from 'react'
import './index.scss'
import GuestIcon from '../../../../../assets/images/guestIcon.png'
import moment from 'moment'
import { connect } from 'react-redux'
import { Collapse, Dropdown, Menu, Input, Divider, Row, Col, Select } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { updateAnnotationsList } from '../../actions'
import CommentComponent from './comment'
import ReplyComponent from './reply'
import { SearchOutlined } from '@ant-design/icons';
const { Option } = Select;
const { Panel } = Collapse;

const Annotations = (props) => {
    const [annotationList, setAnnotationsList] = useState([])
    const [sort, setSort] = useState('')
    function handleDeleteAnnotation(annotId) {
        const index = props.annotationsList.findIndex(annot => annot.id === annotId)
        if (index !== - 1) {
            props.annotationsList.splice(index, 1)
            props.onUpdateAnnotationsList([...props.annotationsList])
            localStorage.setItem(`${props.audioUrl}/annotations`, JSON.stringify(props.annotationsList));
        }
        removeAnnotationElement(annotId)
    }

    function removeAnnotationElement(id) {
        if (props.globalSurfer) {
            let listObj = props.globalSurfer.regions.list
            delete listObj[id]
            props.globalSurfer.regions.list = listObj
            document.querySelector('#waveform').querySelector(`[data-id=${id}]`).remove()
        }
    }

    useEffect(() => {
        setAnnotationsList(props.annotationsList)
    }, [props.annotationsList])


    function handleSort(sortType) {
        setSort(sortType)
    }

    function handleSearch(searchTerm, searchKeys) {
        const convertStringDotNotation = (desc, obj) => {
            var arr = desc.split('.')
            while (arr.length && (obj = obj[arr.shift()]));
            return obj
        }

        const newArrayObj = props.annotationsList
        // if SearchTerm is Empty return original data.
        if (!searchTerm) {
            return setAnnotationsList([...newArrayObj])
        }
        const newArray = []
        newArrayObj.forEach((rowItem, i) => {
            searchKeys.forEach(keys => {
                const rowItemKeys = convertStringDotNotation(keys, { ...rowItem })
                const isFound = (rowItemKeys) && rowItemKeys.toString().toLowerCase().includes(searchTerm.toLowerCase())
                if (isFound) newArray.push(rowItem)
                if (rowItem.reply) {
                    rowItem.reply.map(data => {
                        const foundReply = (rowItemKeys) && data.message.toLowerCase().includes(searchTerm.toLowerCase())
                        if (foundReply) newArray.push(rowItem)
                    })
                }
            })
        })
        // Removed Duplicate Entry in Array.
        const newArrayElement = newArray.filter(function (elem, index, self) {
            return index === self.indexOf(elem)
        })
        setAnnotationsList([...newArrayElement])
    }

    return (
        <div className='annotations-container' style={{ height: 'calc(100vh - 93px)' }}>
            <div className='input-action'>
                <div className='search'>
                    <Input
                        prefix={<SearchOutlined className="site-form-item-icon" />}
                        placeholder="Search author"
                        size='small'
                        className='search'
                        onChange={(e) => handleSearch(e.target.value, ['authorName'])}
                    />
                </div>
                <Divider style={{ margin: 10 }} />
                <div className='action-filter'>
                    <Row style={{ width: '100%' }}>
                        <Col sm={14} xs={14} md={16} lg={16} align='left'>
                            <div>
                                <span style={{ fontSize: 11 }}>Sort By:</span>&nbsp;
                                <Select defaultValue="oldest" style={{ width: 95, fontSize: 11 }} onChange={(value) => handleSort(value)} >
                                    <Option value="oldest">Oldest</Option>
                                    <Option value="newest">Newest</Option>
                                </Select>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            {
                (annotationList && sort === 'newest' ? annotationList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) :
                    annotationList.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))).map((annotation, index) => {
                        return (
                            <div key={index}>
                                <p className='time-line'>{moment.utc(annotation.start * 1000).format('HH:mm:ss')} - {moment.utc(annotation.end * 1000).format('HH:mm:ss')}</p>
                                <div className='card' style={{ border: `2px solid ${annotation.color}` }}>
                                    <div className='flex-row'>
                                        <div className='image'>
                                            <img src={GuestIcon} />
                                        </div>
                                        <div className='description'>
                                            <div className='flex-row'>
                                                <h1>{annotation.authorName || 'Guest'}</h1>
                                                <div className='settings' >
                                                    <Dropdown overlay={<Menu>
                                                        <Menu.Item onClick={() => handleDeleteAnnotation(annotation.id)}> Delete </Menu.Item>
                                                    </Menu>} placement="bottomCenter">
                                                        <FontAwesomeIcon icon={faCog} />
                                                    </Dropdown>
                                                </div>
                                            </div>
                                            <p>{moment(new Date(annotation.createdAt)).format('LLL')}</p>
                                        </div>
                                    </div>
                                    {
                                        annotation.reply &&
                                        <Collapse bordered={false} defaultActiveKey={['1']} style={{ backgroundColor: 'transparent' }}>
                                            <Panel header={annotation.reply ? `${annotation.reply.length} Replies` : 'No Reply'} key={index}>
                                                {
                                                    annotation.reply && annotation.reply.map((item, index) => {
                                                        return (
                                                            <CommentComponent
                                                                audioUrl={props.audioUrl}
                                                                annotId={annotation.id}
                                                                item={item}
                                                                key={index}
                                                                annotation={annotation}
                                                                {...props}
                                                            />
                                                        )
                                                    })
                                                }
                                            </Panel>
                                        </Collapse>
                                    }
                                    <ReplyComponent
                                        audioUrl={props.audioUrl}
                                        annotId={annotation.id}
                                    />
                                </div>
                            </div>
                        )
                    })
            }
        </div>
    )
}


function mapStateToProps(state) {
    return {
        annotationsList: state.client.audio.annotationsList,
        globalSurfer: state.client.audio.globalSurfer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onUpdateAnnotationsList: (annotations) => dispatch(updateAnnotationsList(annotations))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Annotations)