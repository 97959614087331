import React, { useState } from 'react'
import { Row, Col, Radio } from 'antd'
import './index.scss'
import ColorPickerComment from '../../../../../utilities/colorPickerComment'

const Toolbar = (props) => {
    const [viewControl, setViewControl] = useState('annotate')
    return (
        <div className='audio-toolbar'>
            <Row>
                <div className='view-actions'>
                    <Radio.Group
                        value={viewControl}
                        onChange={(e) => setViewControl(e.target.value)}
                        style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Radio.Button className='radio-btn' value="view" style={radioStyle}> View</Radio.Button>
                        <Radio.Button className='radio-btn' value="annotate" style={radioStyle}> Annotate</Radio.Button>
                    </Radio.Group>
                </div>
                {
                    viewControl === 'annotate' &&
                    <div className='view-sub-actions'>
                        <div className='icons'>
                            <ColorPickerComment onChange={props.setAnnotColor} value={props.annotColor} />
                        </div>
                    </div>
                }

            </Row>

        </div>
    )
}

export default Toolbar

const radioStyle = {
    width: 140, backgroundColor: '#e7ebee', border: '0px', textAlign: 'center'
}

