import PDFJSAnnotate from '../PDFJSAnnotate';
import appendChild from '../render/appendChild';
import {
  disableUserSelect,
  enableUserSelect,
  findSVGAtPoint,
  getMetadata,
  scaleDown
} from './utils';

let _enabled = false;
let _penSize;
let _penColor;
let path;
let lines;
let _rotate;
let _scale
let _target = undefined;
let _mobile = false;

/**
 * Handle document.mousedown event
 */
function handleDocumentMousedown(e) {
  path = null;
  lines = [];

  document.addEventListener('mousemove', handleDocumentMousemove);
  document.addEventListener('mouseup', handleDocumentMouseup);
}

function handleDocumentTouchDown() {
  path = null;
  lines = [];


}

function handleDocumentTouchMove(e) {
  e.preventDefault()
  savePoint(e.touches && e.changedTouches[0].clientX, e.changedTouches && e.changedTouches[0].clientY);
}

function handleDocumentTouchUp(e) {
  // console.log("eeeeeeeeeeeeeeeeeeeeeeee", e, e.changedTouches && e.changedTouches[0] && e.changedTouches[0].clientX)

  let svg;
  if (lines.length > 1 && (svg = findSVGAtPoint(e.changedTouches && e.changedTouches[0] && e.changedTouches[0].clientX, e.changedTouches && e.changedTouches[0] && e.changedTouches[0].clientY))) {
    let { documentId, pageNumber } = getMetadata(svg);

    PDFJSAnnotate.getStoreAdapter().addAnnotation(documentId, pageNumber, {
      type: 'drawing',
      width: _penSize,
      color: _penColor,
      rotation: 360 - _rotate,
      lines
    }
    ).then((annotation) => {
      if (path) {
        svg.removeChild(path);
      }

      appendChild(svg, annotation);
    });
  }

  document.removeEventListener("touchstart", handleDocumentTouchDown);
  document.removeEventListener('touchmove', handleDocumentTouchMove);
  document.removeEventListener('touchend', handleDocumentTouchUp);
}

/**
 * Handle document.mouseup event
 *
 * @param {Event} e The DOM event to be handled
 */
function handleDocumentMouseup(e) {
  console.log("mouse move==>", e.clientX, e.clientY)

  let svg;
  if (lines.length > 1 && (svg = findSVGAtPoint(e.clientX, e.clientY))) {
    let { documentId, pageNumber } = getMetadata(svg);

    PDFJSAnnotate.getStoreAdapter().addAnnotation(documentId, pageNumber, {
      type: 'drawing',
      width: _penSize,
      color: _penColor,
      rotation: 360 - _rotate,
      lines
    }
    ).then((annotation) => {
      if (path) {
        svg.removeChild(path);
      }

      appendChild(svg, annotation);
    });
  }

  document.removeEventListener('mousemove', handleDocumentMousemove);
  document.removeEventListener('mouseup', handleDocumentMouseup);
}

/**
 * Handle document.mousemove event
 *
 * @param {Event} e The DOM event to be handled
 */
function handleDocumentMousemove(e) {

  let svg = findSVGAtPoint(e.clientX, e.clientY);
  if (!svg) {
    document.removeEventListener('mousemove', handleDocumentMousemove);
    document.removeEventListener('mouseup', handleDocumentMouseup);
    // return;

    if (lines.length > 1 && path) {
      // svg = findSVGAtPoint(e.layerX, e.layerY)
      svg = _target
      console.log(svg)

      let { documentId, pageNumber } = getMetadata(svg);

      PDFJSAnnotate.getStoreAdapter().addAnnotation(documentId, pageNumber, {
        type: 'drawing',
        width: _penSize,
        color: _penColor,
        rotation: 360 - _rotate,
        lines
      }
      ).then((annotation) => {
        if (path) {
          svg.removeChild(path);
        }

        appendChild(svg, annotation);
      });
    }
  }
  else {
    _target = svg;
    savePoint(e.clientX, e.clientY);
  }
}

/**
 * Handle document.keyup event
 *
 * @param {Event} e The DOM event to be handled
 */
function handleDocumentKeyup(e) {
  // Cancel rect if Esc is pressed
  if (e.keyCode === 27) {
    lines = null;
    path.parentNode.removeChild(path);
    document.removeEventListener('mousemove', handleDocumentMousemove);
    document.removeEventListener('mouseup', handleDocumentMouseup);
    // document.removeEventListener('mouseleave', handleDocumentMouseLeave);
  }
}

/**
 * Save a point to the line being drawn.
 *
 * @param {Number} x The x coordinate of the point
 * @param {Number} y The y coordinate of the point
 */
function savePoint(x, y) {

  let svg = findSVGAtPoint(x, y);
  if (!svg) {
    return;
  }

  let rect = svg.getBoundingClientRect();

  let ptx = x - rect.left;
  let pty = y - rect.top;

  switch (_rotate) {
    case 90:
      ptx = Math.abs(x - rect.x)
      pty = Math.abs(y - rect.y)
      break;

    case 180:
      ptx = y - rect.left;
      pty = x - rect.top;
      break;

    case 270:
      ptx = Math.abs(x - rect.x)
      pty = Math.abs(y - rect.y)
      break;
  }

  let point = scaleDown(svg, {
    x: ptx,
    y: pty
  });

  lines.push([point.x, point.y]);

  if (lines.length <= 1) {
    return;
  }

  if (path) {
    svg.removeChild(path);
  }

  path = appendChild(svg, {
    type: 'drawing',
    color: _penColor,
    width: _penSize,
    rotation: 360 - _rotate,
    lines
  });
}

/**
 * Set the attributes of the pen.
 *
 * @param {Number} penSize The size of the lines drawn by the pen
 * @param {String} penColor The color of the lines drawn by the pen
 */
export function setPen(penSize = 1, penColor = '000000') {
  _penSize = parseInt(penSize, 10);
  _penColor = penColor;
}

/**
 * Enable the pen behavior
 */
export function enablePen(rotate, scale, mobile) {
  _rotate = rotate
  _scale = scale
  _mobile = mobile
  if (_enabled) { return; }

  _enabled = true;
  if (!mobile) {
    document.addEventListener('mousedown', handleDocumentMousedown);
    document.addEventListener('keyup', handleDocumentKeyup);
  }
  else {
    document.addEventListener("touchstart", handleDocumentTouchDown);
    document.addEventListener('touchmove', handleDocumentTouchMove);
    document.addEventListener('touchend', handleDocumentTouchUp);
  }
  disableUserSelect();
}

/**
 * Disable the pen behavior
 */
export function disablePen() {
  if (!_enabled) { return; }

  _enabled = false;
  // if(!_mobile) {
  document.removeEventListener('mousedown', handleDocumentMousedown);
  document.removeEventListener('keyup', handleDocumentKeyup);
  // }
  // else {
  document.removeEventListener("touchstart", handleDocumentTouchDown);
  document.removeEventListener('touchmove', handleDocumentTouchMove);
  document.removeEventListener('touchend', handleDocumentTouchUp);
  // }
  enableUserSelect();
}

export function isEnablePen() {
  return _enabled
}