import React from 'react'
import PropTypes from 'prop-types'
import { fabric } from "fabric";

class Line extends React.Component {
    static propTypes = {
        canvas: PropTypes.object,
        top: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
        fill: PropTypes.string.isRequired,
    }

    static defaultProps = {
        top: 0,
        left: 0,
        strokeWidth: 7,
        opacity: 0,
        fill: 'transparent',
        stroke: 'red',
        width: 1000
    }

    componentDidMount() {
        const line = new fabric.Line([0, 0, 100, 0], { ...this.props, lockScalingY: true, cornerSize: 6 });
        // this.props.canvas.add(line)
        const { canvas } = this.props

        line.animate('opacity', 1, {
            duration: 500,
            onChange: canvas.renderAll.bind(canvas),
            easing: fabric.util.ease['easeInQuad']
        });
        canvas.add(line);
    }

    render() {
        return null
    }
}

export default Line
