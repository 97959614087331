import React, { useEffect, useState } from 'react'
import './index.scss'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { Button, Input, Dropdown, Menu, Avatar, } from 'antd'
import { updateAnnotationsList } from '../../actions'
import { connect } from 'react-redux'

const CommentComponent = (props) => {
    const [isEdit, setIsEdit] = useState(false)
    const [message, setMessage] = useState('')

    useEffect(() => {
        setMessage(props.item.message)
    }, [])

    function handleEdit() {

        setIsEdit(true)
    }

    function handleSave() {
        const index = props.annotationsList.findIndex(annot => annot.id === props.annotId)
        if (index !== - 1) {
            props.annotationsList[index].reply.find(reps => reps.uuid === props.item.uuid).message = message
            props.onUpdateAnnotationsList([...props.annotationsList])
            localStorage.setItem(`${props.audioUrl}/annotations`, JSON.stringify(props.annotationsList));
        }
        setIsEdit(false)
    }

    function handleDelete() {
        const index = props.annotationsList.findIndex(annot => annot.id === props.annotId)
        if (index !== - 1) {
            props.annotationsList[index].reply.splice(index, 1)
            props.onUpdateAnnotationsList([...props.annotationsList])
            localStorage.setItem(`${props.audioUrl}/annotations`, JSON.stringify(props.annotationsList));
        }
    }
    return (
        <div className='reply-list-content'>
            <div className='user-info'>
                <div className='flex'>
                    <div className='image' >
                        <Avatar
                            size={52}
                            src={props.annotation && props.annotation.authorAvatarURL}
                        />
                    </div>
                    <div className='user-name'>
                        <p >{props.annotation && props.annotation.authorName || 'Sample Guest'}</p>
                        <div className='date'>
                            <span>{moment(props.item.createdAt).format('LLL')}</span>
                            <div className='settings' >
                                <Dropdown overlay={<Menu>
                                    <Menu.Item onClick={() => handleEdit()}>Edit</Menu.Item>
                                    <Menu.Item onClick={() => handleDelete()}> Delete </Menu.Item>
                                </Menu>} placement="bottomCenter">
                                    <FontAwesomeIcon icon={faCog} />
                                </Dropdown>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {
                isEdit ?
                    <div>
                        <Input.TextArea rows={5} value={message} onChange={(e) => {
                            setMessage(e.target.value)
                        }} />
                        <Button type="primary" size='small' onClick={() => handleSave()}>Save</Button>
                    </div>
                    :
                    <p className='content'>{message}</p>
            }
        </div>
    )
}

function mapStateToProps(state) {
    return {
        annotationsList: state.client.audio.annotationsList,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onUpdateAnnotationsList: (annotations) => dispatch(updateAnnotationsList(annotations))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentComponent)
