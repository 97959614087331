
import React, { useLayoutEffect, useEffect, useState } from 'react';
import { Row, Col, Layout, Upload, Button, Image } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { analyzeImage } from '../../azure-cognitive'
import { Label } from 'office-ui-fabric-react';

const { Dragger } = Upload;

const AssetCompare = () => {
    const [uploadFirst, setUploadFirst] = useState(true);
    const [uploadSecond, setUploadSecond] = useState(true);
    const [fileFirst, setFileFirst] = useState();
    const [fileSecond, setFileSecond] = useState();

    const [fileFirstURL, setFileFirstURL] = useState('');
    const [fileSecondURL, setFileSecondURL] = useState('');

    const [firstBrands, setFirstBrands] = useState('None');
    const [firstObjects, setFirstObjects] = useState('None');
    const [firstCategories, setFirstCategories] = useState('None');
    const [firstImageType, setFirstImageType] = useState('None');
    const [firstFaces, setFirstFaces] = useState('None');
    const [firstTags, setFirstTags] = useState('None');

    const [secondBrands, setSecondBrands] = useState('None');
    const [secondObjects, setSecondObjects] = useState('None');
    const [secondCategories, setSecondCategories] = useState('None');
    const [secondImageType, setSecondImageType] = useState('None');
    const [secondFaces, setSecondFaces] = useState('None');
    const [secondTags, setSecondTags] = useState('None');

//marginLeft: `calc(50% - ${ fileSecond.width }px)`

    const cognitive = (response, first) => {
        if(response.brands) {
            const _brands = response.brands.map(brand => {
                return brand.name.concat(' (confidence:',Math.floor(brand.confidence * 100), '%)');
            })
            first ? setFirstBrands(_brands.join(', ')) : setSecondBrands(_brands.join(', '));
        }

        if(response.tags) {
            const _tags = response.tags.map(tag => {
                return tag.name.concat(' (confidence:',Math.floor(tag.confidence * 100), '%)');
            })
            first ? setFirstTags(_tags.join(', ')) : setSecondTags(_tags.join(', '));
        }

        if(response.categories) {
            const _categories = response.categories.map(category => {
                return category.name.concat(' (score:',Math.floor(category.score * 100), '%)');
            })
            first ? setFirstCategories(_categories.join(', ')) : setSecondCategories(_categories.join(', '));
        }

        if(response.imageType) {
            const _imageType = response.imageType.clipArtType ? 'Clip Art' : response.imageType.lineDrawingType ? 'Line/Drawing' : 'None';
            first ? setFirstImageType(_imageType) : setSecondImageType(_imageType);
        }

        if(response.objects) {
            const _objects = response.objects.map(object => {
                return object.object.concat(' (confidence:',Math.floor(object.confidence * 100), '%)');
            })
            first ? setFirstObjects(_objects.join(', ')) : setSecondObjects(_objects.join(', '));
        }

        if(response.faces) {
            const _faces = response.faces.map(face => {
                return '(age:'.concat(face.age, ', gender:',face.gender,')');
            })
            first ? setFirstFaces(_faces.join(', ')) : setSecondFaces(_faces.join(', '));
        }
    };

    return (
        <Layout className='dashboard-container'>
            <Row style={{ height:"100vh" }}>
                <Col xs={12} md={12} style={{ marginTop: uploadFirst ? "25%" : "1%" }}>
                    <Row align="middle" justify="center">
                        {
                            uploadFirst ?
                                <Upload 
                                    accept="image/*"
                                    multiple={false}
                                    showUploadList={false}
                                    beforeUpload={async (file) => {
                                        await analyzeImage(file)
                                        .then(response => {
                                            cognitive(response, true);
                                        });

                                        setFileFirst(file);
                                        setFileFirstURL(URL.createObjectURL(file));
                                        setUploadFirst(false);
                                    }}
                                >
                                    <Button icon={<UploadOutlined />}>Upload first image</Button>
                                </Upload>
                            :
                                <>
                                    <Image
                                        src={fileFirstURL}
                                        preview={false}
                                    ></Image>
                                    <Row>
                                        <div style={{width:"100%"}}><b>Brands:</b> { firstBrands }</div>
                                        <div style={{width:"100%"}}><b>Tags:</b> { firstTags }</div>
                                        <div style={{width:"100%"}}><b>Image Type:</b> { firstImageType }</div>
                                        <div style={{width:"100%"}}><b>Objects:</b> { firstObjects }</div>
                                        <div style={{width:"100%"}}><b>Categories:</b> { firstCategories }</div>
                                        <div style={{width:"100%"}}><b>Faces:</b> { firstFaces }</div>
                                    </Row>
                                </>
                        }
                    </Row>
                </Col>
                <Col xs={12} md={12} style={{ marginTop: uploadSecond ? "25%" : "1%" }}>
                    <Row align="middle" type="flex" justify="center" >
                        {
                            uploadSecond ? 
                                <Upload 
                                    accept="image/*"
                                    multiple={false}
                                    showUploadList={false}
                                    beforeUpload={async (file, fileList) => {
                                        await analyzeImage(file)
                                        .then(response => {
                                            cognitive(response, false);
                                        });

                                        setFileSecond(file);
                                        setFileSecondURL(URL.createObjectURL(file));
                                        setUploadSecond(false);
                                    }}
                                    >
                                    <Button icon={<UploadOutlined />}>Upload second image</Button>
                                </Upload>
                            :
                            <>
                                <Image
                                    src={fileSecondURL}
                                    preview={false}
                                ></Image>
                                <Row>
                                    <div style={{width:"100%"}}><b>Brands:</b> { secondBrands }</div>
                                    <div style={{width:"100%"}}><b>Tags:</b> { secondTags }</div>
                                    <div style={{width:"100%"}}><b>Image Type:</b> { secondImageType }</div>
                                    <div style={{width:"100%"}}><b>Objects:</b> { secondObjects }</div>
                                    <div style={{width:"100%"}}><b>Categories:</b> { secondCategories }</div>
                                    <div style={{width:"100%"}}><b>Faces:</b> { secondFaces }</div>
                                </Row>
                            </>
                        }
                        
                    </Row>
                </Col>
            </Row>
        </Layout>
    );
}

export default AssetCompare