import Axios from 'axios'
import { headers } from '../../../../utilities/token'


export const UPDATE_LOCALSTORAGE = 'UPDATE_LOCALSTORAGE'

export const GET_VIDEO_DOWNLOAD = 'GET_VIDEO_DOWNLOAD'
export const GET_VIDEO_DOWNLOAD_FULFILLED = 'GET_VIDEO_DOWNLOAD_FULFILLED'
export const GET_VIDEO_DOWNLOAD_REJECTED = 'GET_VIDEO_DOWNLOAD_REJECTED'


export const updateLocalStorage = (pdfFile) => {
  const annotations = localStorage.getItem(`${pdfFile}/annotations`)
  const anotParsed = JSON.parse(annotations)
  return dispatch => {
    dispatch({
      type: UPDATE_LOCALSTORAGE,
      payload: anotParsed
    })
  }
}


export const downloadVideoPdf = (id) => {
  return dispatch => {
    dispatch({
      type: GET_VIDEO_DOWNLOAD,
      payload: {}
    })
    return Axios.get(`api.site.com.ph/api/${id}`, { headers: headers })
      .then(function (response) {

        // DO WHATEVER IN 'response'

        return dispatch({
          type: GET_VIDEO_DOWNLOAD_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_VIDEO_DOWNLOAD_REJECTED,
          payload: error
        })
      })
  }
}