import React, { useState } from 'react'
import { Layout } from 'antd'
import Audio from './audioView'
import Toolbar from './toolbar'
import Annotations from './annotations'
import './index.scss'
import { connect } from 'react-redux'
import SampleAudio from '../../../../assets/audio/sample_audio.mp3'

const { Sider, Content } = Layout;


const AudioComponent = (props) => {
    const [rightSidebarCollapse, setRightSidebarCollapse] = useState(false)
    const [annotColor, setAnnotColor] = useState('')
    return (
        <Layout className='audio-container' >
            <Toolbar annotColor={annotColor} setAnnotColor={setAnnotColor} />
            <Layout>
                <Layout className="audio-layout">
                    <Audio
                        annotColor={annotColor}
                        audioUrl={SampleAudio}
                    />
                </Layout>
                <Sider className='audio-right-sider' trigger={null} width={300} collapsible collapsed={rightSidebarCollapse}>
                    <Annotations
                        audioUrl={SampleAudio}
                    />
                </Sider>
            </Layout>
        </Layout >
    )
}


function mapStateToProps(state) {
    return {
        annotationsList: state.client.audio.annotationsList,
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AudioComponent)