import React from 'react'
import PropTypes from 'prop-types'
import { fabric } from "fabric";


class Rect extends React.Component {
  static propTypes = {
    canvas: PropTypes.object,
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    fill: PropTypes.string.isRequired,
  }

  static defaultProps = {
    top: 0,
    left: 0,
    width: 50,
    height: 50,
    fill: 'red',
    opacity: 0,
    fill: 'transparent',
    stroke: 'red',
    strokeWidth: 4
  }

  componentDidMount() {
    const rect = new fabric.Rect({ ...this.props, cornerSize: 6 })
    // this.props.canvas.add(rect)
    const { canvas } = this.props
    rect.animate('opacity', 1, {
      duration: 500,
      onChange: canvas.renderAll.bind(canvas),
      easing: fabric.util.ease['easeInQuad']
    });
    canvas.add(rect);
  }

  render() {
    return null
  }
}

export default Rect
