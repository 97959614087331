import React from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash.omit'
import { fabric } from "fabric";


class Signature extends React.Component {
    static propTypes = {
        canvas: PropTypes.object,
        scale: PropTypes.number.isRequired,
        top: PropTypes.number.isRequired,
    }

    static defaultProps = {
        scale: 1.0,
    }

    componentDidMount() {
        const { canvas } = this.props
        const options = omit({ ...this.props, cornerSize: 6 }, ['scale'])
        fabric.Image.fromURL(this.props.url, img => {
            img.animate('opacity', 1, {
                duration: 500,
                onChange: canvas.renderAll.bind(canvas),
                easing: fabric.util.ease['easeInQuad']
            });
            canvas.add(img);

        }, options)
    }

    render() {
        return null
    }
}

export default Signature
