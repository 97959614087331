import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Client from '../components/client/'
import Admin from '../components/admin/adminRouter'
class App extends Component {
  constructor() {
    super()
    this.state = {
      componentType: undefined
    }
  }

  componentWillMount() {
    const { location: { pathname } } = this.props
    if (pathname.substring(0, 6) === '/admin') {
      this.setState({ componentType: 'admin' })
    } else this.setState({ componentType: 'client' })
  }


  render() {
    const { componentType } = this.state


    switch (componentType) {
      case 'client':
        return <Client />
      case 'admin':
        return <Admin />
    }
  }
}

export default withRouter(App)
